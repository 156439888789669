import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Loader, usePageTitle, useTrackPage } from "@library";
import {
  ErrorActions,
  ErrorTypes,
  ParentJourneyBeginActions
} from "scripts/actions";
import { PAGE_TYPE } from "scripts/services";
import { getParameterFromHash } from "scripts/utils";
import { useTranslation } from "scripts/hooks/useTranslation";

export type ParentJourneyBeginContainerProps = {
  errorActions: ThunkActionsDispatch<ErrorActions>;
  parentJourneyBeginActions: ThunkActionsDispatch<ParentJourneyBeginActions>;
};

const ParentJourneyBeginContainer = (
  props: ParentJourneyBeginContainerProps
) => {
  const { errorActions, parentJourneyBeginActions } = props;
  const { t } = useTranslation("Generic");

  useTrackPage(PAGE_TYPE.PARENT_JOURNEY_BEGIN);
  usePageTitle(t("loading_text"));

  useEffect(() => {
    (async () => {
      const token = getParameterFromHash("token");
      if (token) {
        await parentJourneyBeginActions.initialize(token);
      } else {
        errorActions.ErrorAction({
          error: ErrorTypes.TEMPORARY_LINK_TOKEN_MISSING
        });
      }
    })();
  }, []);

  return <Loader ariaLabel={t("loading_text")} />;
};

const mapDispatchToProps = (
  dispatch: Dispatch
): ParentJourneyBeginContainerProps => {
  return {
    errorActions: bindActionCreators(ErrorActions, dispatch),
    parentJourneyBeginActions: bindActionCreators(
      ParentJourneyBeginActions,
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(ParentJourneyBeginContainer);
