import React, { useEffect } from "react";
import { connect } from "react-redux";
import { AuthActions } from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";

export namespace CallbackOidc {
  export type Props = {
    authActions: ThunkActionsDispatch<AuthActions>;
  };
}

const CallbackOidc = (props: CallbackOidc.Props): JSX.Element => {
  const { authActions } = props;

  useEffect(() => {
    (async () => authActions.signinCallback())();
  }, []);

  return <></>;
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authActions: bindActionCreators(AuthActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(CallbackOidc);
