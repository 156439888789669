import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions } from "scripts/actions";

export namespace CancelConsent {
  export type StateProps = {};
  export type ActionProps = {
    authActions: ThunkActionsDispatch<AuthActions>;
  };
  export type Props = StateProps & ActionProps;
}

const CancelConsent = (props:CancelConsent.Props): JSX.Element => {
  useEffect(() => {
    props.authActions.ReturnToClientWithReasonCancel();
  }, [])

  return <></>
}

function mapStateToProps(state: RootState): CancelConsent.StateProps {
  return {};
}

const mapDispatchToProps = (dispatch: Dispatch): CancelConsent.ActionProps => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelConsent);