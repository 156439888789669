import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore
} from "oidc-client";
import StorageFactory from "scripts/utils/StorageFactory";

interface UserManagerContainerConfig {
  authority: string;
  clientId: string;
  scopes: string[];
  redirectUri: string;
  silentRedirectUri: string;
  postLogoutRedirectUri: string;
}

class UserManagerContainer {
  userManager: UserManager = null;

  init(containerConfig: UserManagerContainerConfig) {
    const config: UserManagerSettings = {
      authority: containerConfig.authority,
      client_id: containerConfig.clientId,
      response_type: "code",
      redirect_uri: containerConfig.redirectUri,
      silent_redirect_uri: containerConfig.silentRedirectUri,
      post_logout_redirect_uri: containerConfig.postLogoutRedirectUri,
      silentRequestTimeout: 60000,
      automaticSilentRenew: true,
      loadUserInfo: false,
      scope: containerConfig.scopes.join(" "),
      userStore: new WebStorageStateStore({
        store: StorageFactory.getStorage()
      }),
      stateStore: new WebStorageStateStore({
        store: StorageFactory.getStorage(window.localStorage)
      })
    };

    this.userManager = new UserManager(config);
  }

  instance() {
    if (!this.userManager) {
      console.error("UserManager not initialized");
      throw new Error("UserManager not initialized");
    }
    this.userManager.events.addAccessTokenExpiring(async (x) => {
      this.userManager.startSilentRenew();
    });
    return this.userManager;
  }
}

const container = new UserManagerContainer();
export default container;

export const getAccessTokenAsync = async () => {
  const manager = container.instance();
  const user = await manager.getUser();
  if (user) {
    if (!user.expired) {
      return user.access_token;
    }
    try {
      const newUser = await manager.signinSilent();
      if (newUser) {
        return newUser.access_token;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
  throw new Error("no user found");
};
