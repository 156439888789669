import { ModalActionTypes } from "scripts/actions";

const initialState: ModalState = {
  isOpen: false,
  richBody: false,
  title: undefined,
  closeText: undefined,
  confirmText: undefined,
  role: "alertdialog",
  hideCloseIcon: true,
  hideCloseButton: true,
  visual: undefined,
  onClose: undefined,
  onConfirm: undefined
};

export function ModalReducer(
  state = initialState,
  action: ModalAction
): ModalState {
  switch (action.type) {
    case ModalActionTypes.MODAL_TRIGGERED: {
      return {
        title: action.title,
        body: action.body ?? undefined,
        richBody: action.richBody ?? false,
        testIdentifier: action.testIdentifier ?? undefined,
        visual: action.visual ?? undefined,
        hideCloseIcon: action.hideCloseIcon ?? false,
        hideCloseButton: action.hideCloseButton ?? false,
        destructiveConfirm: action.destructiveConfirm ?? false,
        closeText: action.closeText ?? undefined,
        confirmText: action.confirmText ?? undefined,
        role: action.role ?? 'alertdialog',
        onClose: action.onClose ?? undefined,
        onConfirm: action.onConfirm ?? undefined,
        isOpen: true
      };
    }

    case ModalActionTypes.MODAL_DISMISSED: {
      return {
        ...state,
        isOpen: false
      };
    }
    default:
      return state;
  }
}
