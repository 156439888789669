import React, { useEffect, useState } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions, PublicProfileActions } from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { JourneyRoute } from "scripts/routes";
import { PublicProfilePresentation } from "scripts/components/presentation/PublicProfilePresentation";
import { getFallbackOutfits } from "scripts/services/AvatarService";
import { useTranslation } from "scripts/hooks/useTranslation";

const assets = require.context("assets", false);

type PublicProfile = {
  readonly outfit: AvatarOutfitEntry;
  readonly nickname: string;
};

export namespace PublicProfileContainer {
  export type StateProps = {
    context: ContextState;
    journey: JourneyState;
    publicProfile: PublicProfileState;
  };
  export type ActionProps = {
    authActions: ThunkActionsDispatch<AuthActions>;
    publicProfileActions: ThunkActionsDispatch<PublicProfileActions>;
  };
  export type Props = StateProps & ActionProps;
}

const PublicProfileContainer = (props: PublicProfileContainer.Props) => {
  const { t } = useTranslation(["Generic", "JourneyNickname"]);

  const {
    context,
    publicProfile,
    journey,
    authActions,
    publicProfileActions
  } = props;
  const { hideCloseButton } = context;

  usePageTitle(t("JourneyNickname:title"));
  useTrackPage(PAGE_TYPE.NICKNAME);

  const [selectedProfile, setSelectedProfile] = useState<PublicProfile>();
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    (async () =>
      await publicProfileActions.initializeAsync(
        getFallbackOutfits(
          assets("./default-avatar.png"),
          assets("./default-minifigure.png")
        )
      ))();
  }, []);

  useEffect(() => {
    if (
      publicProfile.outfits.length > 0 &&
      publicProfile.nicknames.length > 0
    ) {
      setSelectedProfile({
        outfit: publicProfile.outfits[0],
        nickname: publicProfile.nicknames[0]
      });
    }
  }, [publicProfile.outfits, publicProfile.nicknames]);

  const persistselectedProfile = async () => {
    setIsSaving(true);
    await publicProfileActions.persistOutfitAndDisplayname(
      selectedProfile.outfit.id,
      selectedProfile.nickname,
      JourneyRoute.GETYOURPARENTS
    );
    setIsSaving(false);
  };

  const nextClick = () => {
    persistselectedProfile();
  };

  const handleCloseButtonOnClick = () =>
    authActions.ReturnToClientWithReasonCancel(true);

  const closeButtonParameters = {
    onClick: handleCloseButtonOnClick,
    closeButtonLabel: t("close_button")
  };

  const currentOutfit = (item: AvatarOutfitEntry) =>
    publicProfile.initialOutfitId
      ? item.id === publicProfile.initialOutfitId
      : false;
  const otherOutfits = (item: AvatarOutfitEntry) =>
    publicProfile.initialOutfitId
      ? item.id !== publicProfile.initialOutfitId
      : true;

  return (
    <PublicProfilePresentation
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      isLoading={!journey.isConsentable || !selectedProfile}
      legalComponent={<Legals context={context} />}
      onContinue={() => nextClick()}
      onChange={(outfit, nickname) =>
        setSelectedProfile({ outfit, nickname })
      }
      avatars={[
        ...publicProfile.outfits.filter(currentOutfit),
        ...publicProfile.outfits.filter(otherOutfits)
      ]}
      nicknames={publicProfile.nicknames}
      disabled={isSaving}
      i18n={{
        header: publicProfile.isNicknameEditable
          ? t("JourneyNickname:header")
          : t("JourneyNickname:header_avatar_only"),
        buttonLabel: t("JourneyNickname:select_nickname_button_label"),
        a11yNext: t("JourneyNickname:a11y_next_label"),
        a11yPrevious: t("JourneyNickname:a11y_previous_label"),
        loadingText: t("loading_text")
      }}
    />
  );
};

function mapStateToProps(state: RootState): PublicProfileContainer.StateProps {
  return {
    context: state.context,
    journey: state.journey,
    publicProfile: state.publicProfile
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch
): PublicProfileContainer.ActionProps => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    publicProfileActions: bindActionCreators(PublicProfileActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfileContainer);
