import React from "react";
import sanitizeHtml from "sanitize-html";
import { Headline } from "../../Typography";
const style = require("./Header.less");

export interface HeaderProps {
    title: any,
    introText?: string,
    headerTestId?: string,
    introTestId?: string
}

const Header:React.FunctionComponent<HeaderProps> = props  => {

  const { title, introText, children, headerTestId, introTestId } = props;

  return (
    <div className={style.layoutHeader}>
      <Headline testId={headerTestId}>
        {title}
      </Headline>
      {(introText && !children) && (
        <p
          className={style.layoutHeader__introText}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(introText) }}
          data-testid={introTestId}
        />
      )}
      {(children && !introText) && (
        <p 
          className={style.layoutHeader__introText}
          data-testid={introTestId}
        >
          {children}
        </p>
      )}
    </div>
    
  );
};

export { Header };
