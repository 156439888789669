import { convertToBool } from "../utils";
import { VpcActionType } from "../actions/vpcActions";

const initialState: VpcState = {
  VPCToken: "",
  VPCParentValidated: false,
  IsLoaded: false
};

export const VPCReducer = (
  state = initialState,
  action: VpcAction
): VpcState => {
  switch (action.type) {
    case VpcActionType.VPC_LOAD_VPC_TOKEN:
      const { VPCToken } = action;
      return {
        ...state,
        VPCToken,
        IsLoaded: true
      };
    case VpcActionType.VPC_MARK_IS_LOADED:
      return {
        ...state,
        IsLoaded: true
      };
    case VpcActionType.VPC_PARENT_VALIDATED:
      return {
        ...state,
        VPCParentValidated: convertToBool(action.VPCParentValidated)
      };
    default:
      return state;
  }
};
