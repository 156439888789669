import { Button, Card, Headline, Paragraph, HorizonAnchor } from "@lego/lego-account-ui";
import {
  Section,
  Loader,
  Img
} from "@library";
import { BackButtonParameters, CloseButtonParameters, PageContainer } from "scripts/components";

export namespace Success {
  export type I18N = {
    header: string;
    text?: string;
    continueButtonLabel?: string;
    changeDelayNotice?: string;
    loadingText?: string;
  };

  export type Minifigure = {
    srcImg: string[];
    alt: string;
  };

  export type Props = {
    i18n: I18N;
    avatar?: {
      nickname?: string;
      image: string;
    };
    backButtonParameters?: BackButtonParameters;
    closeButtonParameters?: CloseButtonParameters;
    isLoading?: boolean;
    hideCloseButton?: boolean;
    legalComponent?: JSX.Element;
    minifigure?: Minifigure;
    onContinue?: () => void;
  };
}

export const Success = ({
  i18n,
  backButtonParameters,
  closeButtonParameters,
  isLoading = false,
  hideCloseButton = false,
  legalComponent = <div></div>,
  minifigure,
  onContinue = null,
}: Success.Props) => {
  const showButton = !!onContinue

  const renderContent = () => {
    return (
      <>
        <Headline variant="h1">{i18n.header}</Headline>

        <HorizonAnchor horizonType="uphill">
          <Section>
            <Card
              data-testid="pagePermissionDenied"
              visualShowPercent={"80"}
              visual={
                minifigure
                  ? <Img
                    srcImg={minifigure.srcImg}
                    alt={minifigure.alt}
                    aria-hidden="true"
                    tabIndex={-1}
                    height="100%"
                    width=""
                  />
                  : null
              }
            >
              {i18n?.text
                ? <Paragraph align="start">{i18n.text}</Paragraph>
                : <></>
              }
              <br />
              {i18n?.text
                ? <Paragraph align="start">{i18n.changeDelayNotice}</Paragraph>
                : <></>
              }

            </Card>
            {
              showButton
                ? <Button
                  loadingLabel={i18n.loadingText}
                  data-testid="successNextBtn"
                  onPress={onContinue}
                >
                  {i18n.continueButtonLabel}
                </Button>
                : null
            }
          </Section>
        </HorizonAnchor>
      </>
    );
  };

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        closeButtonParameters={{
          ...closeButtonParameters,
          display: showButton && hideCloseButton !== true
        }}
        legalComponent={legalComponent}
      >
        {isLoading ? <Loader ariaLabel={i18n.loadingText} /> : renderContent()}
      </PageContainer>
    </>
  );
};
