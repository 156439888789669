import { useEffect } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions } from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { Success } from "scripts/components/presentation/Success";
import { useTranslation } from "scripts/hooks/useTranslation";
import { useHistory } from "react-router-dom";
import { JourneyRoute } from "scripts/routes";
const assets = require.context("../../../assets/", false);

export namespace ChildSuccessContainer {
  export type StateProps = {
    context: ContextState;
  };
  export type ActionProps = {
    authActions: ThunkActionsDispatch<AuthActions>;
  };
  export type Props = StateProps & ActionProps;
}

const ChildSuccessContainer = (props: ChildSuccessContainer.Props) => {
  const { t } = useTranslation([
    "SuccessPage",
    "UpgradeSuccessPage",
    "JourneyNickname"
  ]);

  const { context, authActions } = props;
  const { hideCloseButton } = context;

  const history = useHistory();

  usePageTitle(t("SuccessPage:header"));
  useTrackPage(PAGE_TYPE.SUCCESS_ASYNC);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeButtonParameters = {
    onClick: authActions.ReturnToClient,
    closeButtonLabel: t("close_button")
  };

  const isReconsent = () => {
    return (
      history.location.pathname.indexOf(
        JourneyRoute.CHILD_RECONSENT_SUCCESS
      ) !== -1
    );
  };

  return (
    <Success
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      legalComponent={<Legals context={context} />}
      minifigure={{
        srcImg: [
          assets("./unicorn-guy@1x.png"),
          assets("./unicorn-guy@2x.png"),
          assets("./unicorn-guy@3x.png")
        ],
        alt: "unicorn guy"
      }}
      onContinue={authActions.ReturnToClient}
      i18n={{
        header: t("SuccessPage:header_continue_on_parent_device"),
        continueButtonLabel: t("SuccessPage:text_continue_on_parent_device"),
        text: isReconsent()
          ? t("SuccessPage:text_consent_update_sent")
          : t("SuccessPage:text_continue_on_parent_device2"),
        loadingText: t("loading_text")
      }}
    />
  );
};

function mapStateToProps(state: RootState): ChildSuccessContainer.StateProps {
  return {
    context: state.context
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch
): ChildSuccessContainer.ActionProps => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChildSuccessContainer);
