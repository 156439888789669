import { requestAsync, getAccessTokenAsync } from "scripts/services";

interface GetNicknamesResponse {
  nicknames: string[];
  language: string;
}

interface GetCurrentNicknameResponse {
  nickname: string;
  nicknameChangeGracePeriodDays: number;
  nicknameLastChanged: string;
  conflictTypeError?: string;
}

export const getNicknamesAsync = async (
  endpoint: string,
  culture: string,
  count: number = 15
): Promise<GetNicknamesResponse> => {
  try {
    const response = await requestAsync<GetNicknamesResponse>({
      endpoint,
      method: "GET",
      path: `displaynames/v3/names/${culture}?count=${count}`,
      mode: "cors",
      credentials: "omit"
    });

    if (response.ok) {
      return response.payload;
    }

    throw new Error(`Error getting nicknames: ${response.status}`);
  } catch (error) {
    console.error(error);
    return {
      language: "",
      nicknames: []
    };
  }
};

export const setCurrentNicknameAsync = async (
  endpoint: string,
  culture: string,
  nickname: string
) => {
  const accessToken = await getAccessTokenAsync();
  return await requestAsync<void>({
    endpoint,
    method: "PUT",
    path: "displaynames/v3/accounts/current",
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      culture,
      nickname
    },
    mode: "cors",
    credentials: "include"
  });
};

export const getCurrentNicknameAsync = async (endpoint: string) => {
  const accessToken = await getAccessTokenAsync();
  const response = await requestAsync<GetCurrentNicknameResponse>({
    endpoint,
    method: "GET",
    path: "displaynames/v3/accounts/current",
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    mode: "cors",
    credentials: "include"
  });

  try {
    if (response.ok) {
      return response.payload;
    }

    throw new Error(`Error getting current nickname: ${response.status}`);
  } catch (error) {
    console.error(error);
    return null;
  }
};
