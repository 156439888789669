import React from "react";
import { Redirect, RouteProps } from "react-router-dom";

import {
  Profile,
  UpgradeSuccessPage,
  SuccessContainer,
  VerifyOptionsPage,
  GetYourParentsContainer,
  ConsentPage,
  CancelConsent,
  FlowInfoPage,
  StartConsent,
  LinkConsent,
  VerifyProcessingPage
} from "scripts/experiences/vpc";
import {
  CallbackConsent,
  CallbackSignout,
  CallbackCustom,
  VerificationCallback,
  Unlink
} from "scripts/experiences/callbacks";
import NotFound404 from "scripts/experiences/errorpages/notFound404";

import { createRoute } from "scripts/routes";
import { createBrowserHistory, LocationState } from "history";
import { RouterState } from "connected-react-router";
import Refresh from "../components/Refresh";

export enum DefaultRoute {
  ROOT = "",
  GETYOURPARENTS = "/getyourparents",
  CALLBACK_CONSENT = "/callbackconsent",
  CALLBACK_CUSTOM = "/customcallback",
  SIGN_OUT = "/signout",
  CANCEL_CONSENT = "/cancelconsent",
  START_CONSENT = "/startconsent",
  LINK_CONSENT = "/linkconsent",
  CONSENT = "/consent",
  OPTIONS_VERIFY = "/verify/options",
  VERIFY_PROCESSING = "/verify/processing",
  DASHBOARD = "/dashboard",
  FLOW_INFO = "/flowinfo",
  UPGRADE_SUCCESS = "/upgradesuccess",
  SUCCESS = "/success",
  UNLINK = "/unlink",
  NOT_FOUND = "/404",
  VERIFICATION_CALLBACK = "/verificationCallback"
}

export const defaultRoutes = (
  router: RouterState<LocationState>,
  culture: string
): RouteProps[] => {
  const redirect = () => {
    const { location } = router;

    const culturePattern = /^\/[a-zA-Z]{2}-[a-zA-Z]{2}\/?/;

    if (location.pathname.match(culturePattern)) {
      // If we are in the root then redirect to edit profile as our dashboard
      const rootPattern = /^\/[a-zA-Z]{2}-[a-zA-Z]{2}\/?$/;

      if (location.pathname.match(rootPattern)) {
        return {
          ...location,
          pathname: `/${culture}${DefaultRoute.GETYOURPARENTS}`
        };
      }
      return { pathname: `/${culture}${DefaultRoute.NOT_FOUND}` };
    }
    return {
      ...location,
      pathname: `/${culture}${location.pathname}`
    };
  };

  const legacyUrlRedirect = () => ({
    ...router.location,
    pathname: router.location.pathname.replace("/vpc/flow", "")
  });

  const history = createBrowserHistory();

  return [
    {
      path: "/refresh",
      component: () => <Refresh history={history} location={router.location} />
    },
    {
      path: createRoute(DefaultRoute.DASHBOARD),
      component: Profile
    },
    {
      path: createRoute(DefaultRoute.GETYOURPARENTS),
      component: GetYourParentsContainer
    },
    {
      path: createRoute(DefaultRoute.FLOW_INFO),
      component: FlowInfoPage
    },
    {
      path: createRoute("/vpc/flow/getyourparents"),
      component: () => <Redirect to={legacyUrlRedirect()} />,
      exact: true
    },
    {
      path: createRoute(DefaultRoute.UPGRADE_SUCCESS),
      component: UpgradeSuccessPage
    },
    {
      path: createRoute(DefaultRoute.SUCCESS),
      component: SuccessContainer
    },
    {
      path: createRoute(DefaultRoute.CALLBACK_CONSENT),
      component: CallbackConsent
    },
    {
      path: createRoute(DefaultRoute.CALLBACK_CUSTOM),
      component: CallbackCustom
    },
    {
      path: createRoute(DefaultRoute.SIGN_OUT),
      component: CallbackSignout
    },
    {
      path: createRoute(DefaultRoute.VERIFICATION_CALLBACK),
      component: VerificationCallback
    },
    {
      path: createRoute(DefaultRoute.CANCEL_CONSENT),
      component: CancelConsent
    },
    {
      path: createRoute(DefaultRoute.START_CONSENT),
      component: StartConsent
    },
    {
      path: createRoute(DefaultRoute.LINK_CONSENT),
      component: LinkConsent
    },
    {
      path: createRoute(DefaultRoute.CONSENT),
      component: ConsentPage
    },
    {
      path: createRoute(DefaultRoute.OPTIONS_VERIFY),
      component: VerifyOptionsPage,
      exact: true
    },
    {
      path: createRoute(DefaultRoute.VERIFY_PROCESSING),
      component: VerifyProcessingPage,
      exact: true
    },
    {
      path: createRoute(DefaultRoute.UNLINK),
      component: Unlink,
      exact: true
    },
    {
      path: createRoute(DefaultRoute.NOT_FOUND),
      component: NotFound404
    },
    {
      component: () => <Redirect to={redirect()} />
    }
  ];
};
