import React, { useState } from "react";
const style = require("./Accordion.less");
const ShowAnimation = require("../Transitions/ShowAccordion.less");
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";


export interface AccordionProps {
    id: string,
    title: string
}

const Accordion: React.FC<AccordionProps> = (props): JSX.Element => {
    const [expanded, setExpanded] = useState<boolean>(false);
    return (
        <>
        <div className={classNames([style.Accordion, { [style.AccordionExpanded]: expanded }])} data-component="Accordion">
        <div className={style.AccordionTrigger}>
            <button 
                aria-expanded={expanded}
                aria-controls={`${props.id}-section`}
                className={style.AccordionTitle}
                id={`${props.id}-accordion`}
                onClick={() => setExpanded(!expanded)}
                >
                {props.title}
                <span className={style.AccordionIcon}></span>
            </button>
        </div>
        <CSSTransition in={expanded} timeout={200} classNames={ShowAnimation} unmountOnExit>
            <div id={`${props.id}-section`}
                data-testid="AccordionBody"
                role="region"
                aria-labelledby={`${props.id}-accordion`}
                className={style.AccordionBody}>
                {props.children}
            </div>
        </CSSTransition>  
        </div>
        </>
    );
};
export { Accordion };



