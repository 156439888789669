import React, { forwardRef } from "react";
import classNames from "classnames";
const style = require("./Section.less");

export type SectionMargin = 'none' | 'small';

export interface SectionProps {
    children?: React.ReactNode
    margin?: SectionMargin
    className?: string
}

const Section = forwardRef<HTMLDivElement | null, SectionProps>(({className, children, margin='none'}, ref)  => {
    return (
        <div ref={ref} className={classNames(style.Section, className, margin ? style[`margin-${margin}`] : '')}>
            {children}
        </div>
    );
});

export { Section };
