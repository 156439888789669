import { userManager, VPCApiClient } from "scripts/services";
import { convertToBool, getParameterByName } from "scripts/utils";
import { push } from "connected-react-router";
import { createPath, LocationDescriptorObject } from "history";
import { ErrorActions, ErrorTypes, VpcActions } from "scripts/actions";
import StorageFactory from "scripts/utils/StorageFactory";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { DefaultRoute, isJourneyFlow } from "scripts/routes";
import {
  CustomCallbackState,
  parseCallbackState
} from "scripts/utils/callbackHelper";

export enum AuthActionTypes {
  AUTH_FAILED = "[AUTH] AUTH_FAILED",
  AUTH_COMPLETED = "[AUTH] AUTH_COMPLETED"
}

export enum Reason {
  CANCEL = "cancel",
  ERROR = "error"
}

export type AuthFlowType = "flow:register" | "flow:upgrade" | "flow:login";
export type AuthPrompt = "login" | "select_account";

const storage = StorageFactory.getStorage();

export namespace AuthActions {
  function AuthCompleted(user: UserState): AuthActionSuccess {
    return {
      type: AuthActionTypes.AUTH_COMPLETED,
      user: user,
      isadult: convertToBool(user.profile && user.profile.isadult),
      email_verified: convertToBool(
        user.profile && user.profile.email_verified
      ),
      country: user.profile && user.profile.country
    };
  }
  function AuthFailed(error: string): AuthFailedAction {
    return {
      type: AuthActionTypes.AUTH_FAILED,
      error: error
    };
  }

  export const UserRefreshed =
    (newUser: UserState): ThunkAction =>
    (dispatch) => {
      dispatch(AuthCompleted(newUser));
    };

  export const DoOTTSilentLogin =
    (oneTimeToken: string): ThunkFunction<Promise<UserState>> =>
    (dispatch) => {
      return dispatch(DoSilentLogin(oneTimeToken));
    };

  export const DoSilentLogin =
    (acr_values?: string): ThunkFunction<Promise<UserState>> =>
    async (dispatch) => {
      const userManagerInstance = userManager.instance();
      try {
        await userManagerInstance.removeUser();
        const user = await userManagerInstance.signinSilent({
          acr_values: acr_values
        });
        dispatch(AuthCompleted(user));
        return user;
      } catch (err) {
        if (err && err.error === "login_required") {
          dispatch(AuthFailed(err.error));
          return null;
        } else {
          console.error("err in DoSilentLogin", err);
          dispatch(
            ErrorActions.ErrorAction({
              error:
                err && err.error
                  ? err.error
                  : "Error in DoSilentLogin: " + JSON.stringify(err)
            })
          );
          return null;
        }
      }
    };

  export const GetCurrentUser =
    (): ThunkFunction<Promise<UserState>> => async (dispatch) => {
      const user = await userManager.instance().getUser();
      if (user) {
        dispatch(AuthCompleted(user));
      }
      return user;
    };

  interface OidcState {
    redirect_uri: LocationDescriptorObject;
    context: ContextState;
    vpcToken: string;
  }
  export const DoRedirectLogin =
    (
      redirectUri: string,
      flow: AuthFlowType,
      prompt?: AuthPrompt
    ): ThunkActionAsync =>
    async (_, getState): Promise<void> => {
      const {
        context,
        vpc: { VPCToken }
      } = getState();
      const url = enrichReturnUrl(redirectUri, context);
      const mgr = userManager.instance();
      await mgr.removeUser();
      await mgr.signinRedirect({
        redirect_uri: `${context.selfHost}${DefaultRoute.CALLBACK_CONSENT}`,
        state: getVpcState(url, context, VPCToken),
        acr_values: flow,
        prompt: prompt,
        extraQueryParams: {
          hideExternalLogin: false,
          adultExperience: true,
          appcontext: context.appContext
        },
        ui_locales: context.culture
      });
    };

  export const DoLogout =
    (redirectUri: string): ThunkFunction<Promise<any>> =>
    (_, getState) => {
      const {
        context,
        vpc: { VPCToken }
      } = getState();

      const url = enrichReturnUrl(redirectUri, context);

      return userManager.instance().signoutRedirect({
        state: getVpcState(url, context, VPCToken)
      });
    };

  export const doLogoutAndCancel =
    (): ThunkFunction<Promise<any>> => (dispatch, getState) => {
      return dispatch(DoLogout("/cancelconsent"));
    };

  function enrichReturnUrl(redirect_uri: string, context: ContextState): URL {
    const url = new URL(redirect_uri, window.location.origin);

    if (context.clientid) url.searchParams.set("clientid", context.clientid);
    if (context.returnurl) url.searchParams.set("returnurl", context.returnurl);
    if (context.state) url.searchParams.set("state", context.state);
    url.searchParams.set("appContext", context.appContext ? "true" : "false");

    return url;
  }

  function getVpcState(
    url: URL,
    context: ContextState,
    vpcToken: string
  ): OidcState {
    return <OidcState>{
      redirect_uri: {
        pathname: url.pathname,
        search: url.search,
        hash: url.hash
      },
      context: context,
      vpcToken
    };
  }

  export const customCallback =
    (stateId: string): ThunkFunction<Promise<void>> =>
    async (dispatch): Promise<void> => {
      if (stateId) {
        const localStorage = StorageFactory.getStorage(window.localStorage);

        const stateString = localStorage.getItem(`oidc.${stateId}`);
        if (stateString) {
          const state = parseCallbackState<CustomCallbackState>(stateString);
          if (state.returnUrl) {
            if (state.vpcToken)
              dispatch(VpcActions.updateVpcToken(state.vpcToken));
            localStorage.removeItem(`oidc.${stateId}`);
            window.location.href = state.returnUrl;
          }
        }
      }
    };

  export const signinCallback =
    (): ThunkFunction<Promise<void>> =>
    async (dispatch, getState): Promise<void> => {
      try {
        const user = await userManager.instance().signinCallback();
        if (user && user.state) {
          const state: OidcState = user.state;
          redirectCallback(state, dispatch, null, user?.profile?.isadult);
        }
      } catch (error) {
        if (error?.state) {
          const state: OidcState = error.state;
          const cancelReason = getCancelReason();
          redirectCallback(state, dispatch, cancelReason);
        } else {
          console.error(
            `Error in callback consent. This might be browser back. ${JSON.stringify(
              error
            )}`
          );
          const { auth, context } = getState();
          if (isJourneyFlow(context.journey.flowType) && auth?.user?.state) {
            redirectCallback(auth.user.state, dispatch);
          } else {
            dispatch(push(DefaultRoute.LINK_CONSENT));
          }
        }
      }
    };

  function getCancelReason(): Reason {
    const hash =
      window.location.hash && window.location.hash.length > 0
        ? window.location.hash.substr(1)
        : "";
    if (hash.indexOf("reason") >= 0) {
      const reason = /reason=([^&]*)/.exec(hash)[1];
      if (reason === Reason.CANCEL || reason === Reason.ERROR) {
        return reason;
      }
    }
    return null;
  }

  export const signoutRedirectCallback =
    (): ThunkFunction<Promise<void>> => async (dispatch) => {
      const response = await userManager.instance().signoutRedirectCallback();
      if (response && response.state) {
        const state: OidcState = response.state;
        redirectCallback(state, dispatch);
      }
    };

  async function redirectCallback(
    state: OidcState,
    dispatch: ThunkDispatch<RootState, void, AnyAction>,
    reason: Reason = null,
    isadult?: boolean
  ) {
    const { context, redirect_uri, vpcToken } = state;
    const { journey } = context;
    try {
      if (vpcToken) dispatch(VpcActions.updateVpcToken(vpcToken));

      if (reason) dispatch(ReturnToClient(true, reason));
      else if (isJourneyFlow(journey.flowType)) {
        if (isadult === false) {
          dispatch(
            ErrorActions.ErrorAction({
              error: ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT
            })
          );
        } else {
          dispatch(
            VpcActions.startVpcJourney(
              context.journey,
              redirect_uri.pathname + redirect_uri.search
            )
          );
        }
      } else {
        window.location.href = createPath(state.redirect_uri);
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
    }
  }

  export const IsRedirectCancel = (): ThunkFunction<boolean> => (dispatch) => {
    const hash =
      window.location.hash && window.location.hash.length > 0
        ? window.location.hash.substr(1)
        : "";

    if (hash && hash.indexOf("reason") >= 0) {
      const reasonRegex = /reason=([^&]*)/;
      const reason = reasonRegex.exec(hash)[1];
      if (reason && (reason === Reason.CANCEL || reason === Reason.ERROR)) {
        dispatch(ReturnToClient(true, reason));
        return true;
      }
    }

    return false;
  };

  export function ValidateClient(): ThunkFunctionAsync<boolean> {
    return async (dispatch, getState) => {
      const { context } = getState();
      if (!context.clientIdWasProvidedByClient) {
        dispatch(
          ErrorActions.ErrorAction({ error: ErrorTypes.CLIENT_ID_NOT_PROVIDED })
        );
        return false;
      }
      try {
        const valid = await VPCApiClient.validateReturnUrl(
          context.serviceEndpoints.vpcRootDomain,
          context.clientid,
          context.returnurl,
          context.culture
        );
        if (!valid)
          dispatch(
            ErrorActions.ErrorAction({
              error: ErrorTypes.CLIENT_IS_NOT_VALID,
              behavior: ErrorActions.createGoBackBehavior()
            })
          );
        return valid;
      } catch (error) {
        dispatch(
          ErrorActions.ErrorAction({
            error:
              error && error.error
                ? error.error
                : "Error in client validation" + JSON.stringify(error)
          })
        );
        return false;
      }
    };
  }

  const getReturnToEditUrl = (context: ContextState) => {
    if (
      window.location.pathname === context.returnurl ||
      context.returnurl.charAt(0) === "/"
    )
      return `${context.serviceEndpoints.identityService}/${context.culture}/profile`;
    return context.returnurl;
  };

  export const ReturnToEdit = (): ThunkAction => (_, getState) => {
    const { context } = getState();
    const returnurl = getReturnToEditUrl(context);
    const url =
      returnurl && returnurl !== null && returnurl !== "null"
        ? new URL(returnurl)
        : null;
    // This is temporary until the two projects are merged an no redirect will be required
    if (
      ((url.host === "identity-local.dev.corp.lego.com:5000" ||
        url.host === "identity.dev.corp.lego.com" ||
        url.host === "identity.webqa.lego.com" ||
        url.host === "identity.lego.com") &&
        url.pathname.endsWith("/editprofile")) ||
      url.pathname.endsWith("/profile")
    ) {
      storage.clear();
      window.location.href = `${url.protocol}//${url.host}${url.pathname}${url.search}`;
    } else {
      console.log("Could not navigate to: " + returnurl);
    }
  };

  export const ReturnToClientWithReasonCancel = (skipToEndReturn = false) =>
    ReturnToClient(skipToEndReturn, Reason.CANCEL);

  export const ReturnToClient =
    (skipToEndReturn = false, reason?: Reason): ThunkAction =>
    (_, getState) => {
      const { context } = getState();
      let returnUrl = getParameterByName("returnUrl") ?? context.returnurl;
      let clientId = getParameterByName("clientid") ?? context.clientid;
      const state = getParameterByName("state") ?? context.state;

      if (skipToEndReturn === true) {
        let nestedReturn =
          getParameterByName("returnUrl", returnUrl) ||
          getParameterByName("redirect_uri", returnUrl);
        while (nestedReturn) {
          const decodedNestedReturn = decodeURIComponent(nestedReturn);
          const newclientid = decodeURIComponent(
            getParameterByName("clientid", decodedNestedReturn)
          );

          clientId =
            !!newclientid && newclientid !== "null" ? newclientid : clientId;
          returnUrl = decodedNestedReturn;
          nestedReturn =
            getParameterByName("returnUrl", decodedNestedReturn) ||
            getParameterByName("redirect_uri", decodedNestedReturn);
        }
      }
      storage.clear();
      const url = new URL(
        `${context.serviceEndpoints.vpcRootDomain}/api/v4/flow/ReturnToClient`
      );
      url.searchParams.set("clientId", clientId);
      url.searchParams.set("returnUrl", returnUrl);
      if (state) url.searchParams.set("state", state);
      if (reason) url.searchParams.set("reason", reason);
      window.location.href = url.toString();
    };
}

export type AuthActions = typeof AuthActions;
