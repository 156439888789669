import { forwardRef } from "react";
import { ChecklistItemProps, ChecklistItem } from "./ChecklistItem";
const style = require("./Checklist.less");

export interface ChecklistProps {
    list: ChecklistItemProps[]
    header?: string
    description?: string
    allChecked?: boolean
}

const Checklist = forwardRef<HTMLDivElement | null, ChecklistProps>((props, ref) => {
    const { list, header, description, allChecked } = props;
    list.sort((a, b) => (a.uniqueKey && b.uniqueKey && a.uniqueKey > b.uniqueKey) ? 1 : -1)
    return list?.length > 0 ? <>
        <div ref={ref} className={style.ChecklistWrapper}>
            {header && <div className={style.ChecklistHeader}>
                {header}
            </div>}
            {description && <div className={style.ChecklistDescription}>
                {description}
            </div>}
            <hr className={style.ChecklistBreaker} />
            <ul className={style.Checklist}>
                {
                    list.map((item, i) => <li key={item?.uniqueKey ? item.uniqueKey : i.toString()}>
                        <ChecklistItem title={item.title} description={item.description} checked={allChecked ?? item.checked} />
                    </li>
                    )
                }
            </ul>
            <hr className={style.ChecklistBreaker} />
        </div>
    </> : null
});

export { Checklist };