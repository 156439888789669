import {
  addGlobalContexts,
  newTracker,
  TrackerConfiguration,
  clearUserData,
  enableActivityTracking,
  trackPageView,
  BuiltInContexts
} from "@snowplow/browser-tracker";
import { ClientHintsPlugin } from "@snowplow/browser-plugin-client-hints";

// remove all props that have `null` or `undefined` as value from an object
const removeUndefinedAndNull = (obj: any) => Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== null && value !== undefined));

const getEnv = (environment: string) => environment === "dev" ? "development" : environment === "qa" ? "testing" : environment === "live" ? "production" : "local";

const trackerId = "identityFE";

const canTrack = async (serviceUrl: string, timeout = 3000) => {
  const controller = new AbortController();
  const id = setTimeout(
    () =>
      controller.abort(
        `Request for ${serviceUrl} aborted after ${timeout} milliseconds`
      ),
    timeout
  );
  try {
    const response = await fetch(serviceUrl, {
      signal: controller.signal
    });
    if (response?.ok) {
      const { basicstats = false } = await response.json();
      return basicstats;
    }
    return false;
  } catch (error) {
    console.error(error);
    return false;
  } finally {
    clearTimeout(id);
  }
};

export const initTracker = async (
  serviceUrl: string,
  timeout: number,
  environment: string,
  version: string,
  culture: string
): Promise<string> => {
  try {
    if (await canTrack(serviceUrl, timeout)) {
      console.log(`Snowplow : start initTracker [${trackerId}]`);

      const contexts: BuiltInContexts = {
        session: true,
        browser: false
      };
      const configuration: TrackerConfiguration = {
        appId: "lego-identity-frontend",
        discoverRootDomain: true,
        contexts,
        cookieName: "_sp_",
        cookieSameSite: "Lax", // Recommended
        cookieSecure: true,
        encodeBase64: false,
        sessionCookieTimeout: 1800,
        anonymousTracking: { withSessionTracking: true },
        plugins: [ClientHintsPlugin()],
        eventMethod: "beacon"
      };
      const spTracker = newTracker(
        trackerId,
        "scout.services.lego.com",
        configuration
      );

      const globalContext = [
        {
          schema: "iglu:com.lego/experience/jsonschema/1-0-1",
          data: removeUndefinedAndNull({
            environment: getEnv(environment),
            language: culture,
            version
          })
        }
      ];
      addGlobalContexts(globalContext);

      console.log("Snowplow : config");
      console.table(configuration);
      enableActivityTracking(
        {
          minimumVisitLength: 10,
          heartbeatDelay: 10
        },
        [trackerId]
      );

      trackPageView();

      const domainUserId = spTracker.getDomainUserId();

      console.log(`Snowplow : initTracker [${trackerId}] successful`);
      return `${domainUserId}`;
    }

    return "";
  } catch (error) {
    console.error(`Snowplow : initTracker [${trackerId}] failed`);
    console.error(error);
    return "";
  }
};

export const deleteUserData = (
  preserveSession: boolean = false,
  preserveUser: boolean = false
) => {
  try {
    clearUserData({ preserveSession, preserveUser });
    return true;
  } catch (error) {
    console.error("Snowplow : deleteUserData failed");
    console.error(error);
    return false;
  }
};

export const trackPage = (context: ContextState) =>
  trackPageView({
    context: [
      {
        schema: "iglu:com.lego.account/account_page/jsonschema/1-0-2",
        data: removeUndefinedAndNull({
          clientId: context.clientid,
          returnUrl: context.returnurl?.split("?")[0],
          adultExperience: context.adultexperience,
          appContext: context.appContext
        })
      }
    ],
    timestamp: new Date().valueOf()
  });
