import { VPCApiClient, IdentityApiClient } from "scripts/services";
import {
  ErrorActions,
  AuthActions,
  ErrorTypes,
  ContextActions
} from "scripts/actions";
import { FlowType } from "scripts/types/storeTypes";
import { JourneyRoute, resolveCulturedPath } from "scripts/routes";
import { VpcActions } from "./vpcActions";

export namespace ParentJourneyBeginActions {
  export const initialize = (
    session: string
  ): ThunkFunction<Promise<boolean>> => async (dispatch, getState) => {
    const { context } = getState();
    const {
      serviceEndpoints: { vpcRootDomain },
      culture
    } = context;

    try {
      const responseCreateTemporaryLinkToken = await VPCApiClient.createSessionFromToken(
        vpcRootDomain,
        session,
        culture
      );

      if (responseCreateTemporaryLinkToken.ok) {
        const {
          token: vpcToken,
          verifiedEmailToken,
          securityId
        } = responseCreateTemporaryLinkToken.payload;

        dispatch(VpcActions.updateVpcToken(vpcToken));
        await dispatch(validateNotRequestingUser());
        dispatch(
          ContextActions.JourneyContextChanged({
            flowType: FlowType.Email,
            verifiedEmailToken: verifiedEmailToken,
            securityId: securityId
          })
        );
        dispatch(triggerFlowRegisterAsync());
        return true;
      } else {
        const error =
          responseCreateTemporaryLinkToken.badRequest &&
          responseCreateTemporaryLinkToken.errors[0]
            ? responseCreateTemporaryLinkToken.errors[0]
            : ErrorTypes.GENERIC_ERROR;
        dispatch(ErrorActions.ErrorAction({ error }));
        return false;
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      return false;
    }
  };

  export const validateNotRequestingUser = (): ThunkFunction<Promise<void>> => async (dispatch, getState) => {
    const {
      context: {
        serviceEndpoints: { vpcRootDomain }
      },
      vpc: { VPCToken: vpcToken }
    } = getState();

    const user = await dispatch(AuthActions.DoSilentLogin());
    if(user) {
      const statusResponse = await VPCApiClient.getAccountLinksStatus(vpcRootDomain, vpcToken);
    
      const childUserId = statusResponse?.payload?.child?.userId;
      const currentUserId = user?.profile?.sub

      if(statusResponse.ok && childUserId && currentUserId && childUserId === currentUserId) {
        throw new Error(ErrorTypes.USER_IS_SAME_AS_REQUESTING_VPC);
      }
    }
  }

  export const getExperienceConfigAsync = (): ThunkFunction<
    Promise<GetExperienceContentResponse>
  > => async (dispatch, getState) => {
    const {
      context: {
        serviceEndpoints: { vpcRootDomain }
      },
      vpc: { VPCToken: vpcToken }
    } = getState();

    const response = await VPCApiClient.getExperienceConfiguration(
      vpcRootDomain,
      vpcToken
    );
    if (response.ok) return response.payload;
    else {
      dispatch(ErrorActions.ErrorResponse(response));
      return null;
    }
  };

  export const triggerFlowRegisterAsync = (): ThunkActionAsync => async (
    dispatch,
    getState
  ) => {
    const { context } = getState();
    const {
      serviceEndpoints: { identityService },
      journey: { securityId, verifiedEmailToken },
      culture
    } = context;

    try {
      if (securityId && verifiedEmailToken) {
        await IdentityApiClient.emailTokenVerification(
          identityService,
          verifiedEmailToken,
          securityId
        );
      }
      dispatch(
        AuthActions.DoRedirectLogin(
          resolveCulturedPath(JourneyRoute.CONSENT, culture),
          "flow:register",
          "select_account"
        )
      );
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
    }
  };
}

export type ParentJourneyBeginActions = typeof ParentJourneyBeginActions;
