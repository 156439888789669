import React from "react";
import { connect } from "react-redux";
import { AuthActions } from "../../actions/authActions";
import { bindActionCreators, Dispatch } from "redux";
import { push } from "connected-react-router";

export namespace CallbackSignout {
  export type Props = Partial<{
    navigate: (path: string) => any,
    authactions: ThunkActionsDispatch<AuthActions>
  }>
}

class CallbackSignout extends React.Component<CallbackSignout.Props> {
  async componentDidMount() {
    try {
      await this.props.authactions.signoutRedirectCallback();
    } catch (error) {
      console.warn(
        "Error in signout callback. This might be browser back. Showing expired token error." +
          JSON.stringify(error)
      );
      this.props.navigate("/consent");
    }
  }

  render(): JSX.Element {
    return null;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authactions: bindActionCreators(AuthActions, dispatch),
    navigate: (path:string) => dispatch(push(path))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CallbackSignout);
