import { memo, useEffect } from "react";
import { Dispatch, bindActionCreators } from "redux";
import { connect } from "react-redux";
import { LegalsActions } from "scripts/actions";
import { useTranslation, useModal } from "scripts/hooks";
import { Button } from "@lego/lego-account-ui";

namespace Legals {
  export type Props = {
    readonly context: ContextState;
    readonly legalsActions: ThunkActionsDispatch<LegalsActions>;
  };
}

const Legals = memo((props: Legals.Props): JSX.Element => {
  const { triggerModal, dismissModal } = useModal();
  const { t } = useTranslation(["LegalsComponent"]);

  const {
    legalsActions,
    context: {
      hideheader,
      appContext,
      legalTexts: { privacy, cookie }
    }
  } = props;

  useEffect(() => {
    legalsActions.prepareLegals();
  }, []);

  const openPolicyModal = async () => {
    triggerModal({
      title: t("LegalsComponent:privacy_policy_header_text"),
      testIdentifier: "Privacy",
      body: privacy,
      richBody: true,
      hideCloseIcon: false,
      hideCloseButton: false,
      onClose: async () => dismissModal()
    });
  }

  const openCookieModal = async () => {
    triggerModal({
      title: t("LegalsComponent:cookie_policy_header_text"),
      testIdentifier: "Cookie",
      body: cookie,
      richBody: true,
      hideCloseIcon: false,
      hideCloseButton: false,
      onClose: async () => dismissModal()
    });
  }
  if (appContext || hideheader) {
    return (
      <>
        <Button
          onPress={openPolicyModal}
          variant="link"
          aria-haspopup="true"
          data-testid="legal-privacy-policy"
        >
          {t("LegalsComponent:privacy_policy_link_text")}
        </Button>
        |
        <Button
          onPress={openCookieModal}
          variant="link"
          aria-haspopup="true"
          data-testid="legal-cookie-policy"
        >
          {t("LegalsComponent:cookie_policy_link_text")}
        </Button>
      </>
    );
  } else {
    return null;
  }
});

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    legalsActions: bindActionCreators(LegalsActions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(Legals);
