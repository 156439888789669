import React from 'react';
import { LinkedProfilePresentationProps, GlobalPresentation, ExperiencePresentation, BlanketPresentation, getConsentState } from '.';
import { ConsentSchemaType } from 'scripts/types/consentTypes';

export default ({consent, ...rest}:LinkedProfilePresentationProps) => {

  
  switch(consent.data.schema) {
    case ConsentSchemaType.Experience:
      return <ExperiencePresentation consent={consent as BaseConsent<ExperienceConsentData>} {...rest} />
    case ConsentSchemaType.Global:
      return <GlobalPresentation consent={consent as BaseConsent<GlobalConsentData>} {...rest} />
    case ConsentSchemaType.Blanket:
      return <BlanketPresentation consent={consent as BaseConsent<BlanketConsentData>} {...rest} />
    default:
      console.info('No presentation found')
      break;
  }

  return null
}