import React from "react";

const style = require("./Breaker.less");

const Breaker = ({text}: {text: string})  => {
    return (
        <h2 className={style.Breaker}><span>{text}</span></h2>
    );
};

export { Breaker };
