import React from "react";
import { connect } from "react-redux";
import { ErrorActions, ErrorTypes } from "../../actions/errorActions";

class NotFound404 extends React.Component {
  componentDidMount() {
    this.props.errorMessage(ErrorTypes.PAGE_NOT_FOUND);
  }

  render() {
    return null;
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    errorMessage: error => dispatch(ErrorActions.ErrorAction({ error }))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotFound404);
