import { useDispatch } from "react-redux";
import { ModalActionTypes } from "scripts/actions";

export type TriggerParams = Omit<ModalState, "type" | "isOpen">;

export const useModal = () => {
  const dispatch = useDispatch();

  const dismissModal = async () => {
    dispatch({
      type: ModalActionTypes.MODAL_DISMISSED
    });
  };

  const triggerModal = (params: TriggerParams) => {
    dispatch({
      type: ModalActionTypes.MODAL_TRIGGERED,
      ...params
    });
  };

  return {
    dismissModal,
    triggerModal
  };
};

export default useModal;
