import React, { useState } from "react";
import { GivenConsentState } from "scripts/types/consentTypes";

import consentIconHelper from "scripts/utils/consentIconHelper";
import { consentSorting } from "scripts/utils/consentHelper";
import { useTranslation } from "scripts/hooks/useTranslation";
import { BareListItem, ConsentCard, Headline } from "@lego/lego-account-ui";
import { PresentationProps } from ".";

export type GlobalConsentsPresentationProps = {
  consents: BaseConsent<GlobalConsentData>[];
};

export default (props: PresentationProps & GlobalConsentsPresentationProps) => {
  const { t } = useTranslation([
    "ConsentPage",
    "ExpandableFormCard",
    "DashboardPage"
  ]);
  const { consentsData, consents } = props;
  const [selectedConsentState, setSelectedConsentState] = useState<
    Record<string, GivenConsentState>
  >({});

  const handleConsentChange = (uri: string, value: boolean) => {
    const consentStateValue: GivenConsentState = value
      ? GivenConsentState.Granted
      : GivenConsentState.Denied;
    props.onConsentChange(uri, consentStateValue);
    setSelectedConsentState({
      ...selectedConsentState,
      [uri]: consentStateValue
    });
  };

  const getDefaultConsentState = (uri: string): GivenConsentState => {
    return consentsData.consents.find(
      (consent) => consent.consentOptionUri === uri
    )?.state;
  };

  const getConsentStateValue = (uri: string): boolean => {
    const currentState =
      selectedConsentState[uri] || getDefaultConsentState(uri) || undefined;

    if (currentState) {
      return currentState === GivenConsentState.Granted;
    }
    return undefined;
  };

  const renderConsent = (
    consent: BaseConsent<GlobalConsentData>,
    iconOverride?: string
  ) => {
    return (
      <BareListItem key={`global-${consent.data.title}`}>
        <ConsentCard
          visual={
            <img
              alt={consent.data.title}
              src={iconOverride || consentIconHelper(consent.data.icon)}
              width="100%"
            />
          }
          title={consent.data.title}
          description={consent.data.description}
          openDescriptionText={t("ExpandableFormCard:read_more_text")}
          onChange={(e) => {
            handleConsentChange(consent.consentOptionUri, e);
            return e;
          }}
          closeDescriptionText={t("ExpandableFormCard:read_less_text")}
          ariaAcceptLabel={t("ConsentPage:consent_label_yes_text")}
          ariaConsentTitle={consent.data.title}
          ariaDeclineLabel={t("ConsentPage:consent_label_no_text")}
          state={getConsentStateValue(consent.consentOptionUri)}
          portalContainer={document.getElementById("main") || document.body}
          data-testid="globalConsentItem"
        />
      </BareListItem>
    );
  };

  return consents.length > 0 ? (
    <>
      <BareListItem key={`global-${consentsData.experience.data.title}`}>
        <Headline variant="h5" data-testid="globalConsentsHeader">
          {t("ConsentPage:general_consents_headline")}
        </Headline>
      </BareListItem>
      {consents
        .sort(consentSorting)
        .map((consent: BaseConsent<GlobalConsentData>) =>
          renderConsent(consent)
        )}
    </>
  ) : null;
};
