const getBrowserLanguage = (): string => {
    try {
        const nav = window.navigator,
            browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (let i = 0; i < nav.languages.length; i++) {
                const language = nav.languages[i];
                if (isLanguageSupported(language)) {
                    return language;
                }
            }
        }

        // support for other well known properties in browsers
        for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
            // @ts-ignore
            const language = correctCasing(nav[browserLanguagePropertyKeys[i]]);
            if (isLanguageSupported(language)) {
                return language ?? "en-US";
            }
        }
    } catch (error) {
        console.error(error);
    }
    return "en-US";
};
const culturePathRegex = /^\/([a-zA-Z]{2}-[a-zA-Z]{2})\/?/;
const getLanguageFromPath = (): string | null => {
    try {
        const pathCultureMatch = culturePathRegex.exec(window.location.pathname);
        if (pathCultureMatch) {
            const language = correctCasing(pathCultureMatch[1]);
            return isLanguageSupported(language) ? language : null;
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}
const getLanguageFromQuery = (): string | null => {
    try {
        const search = window.location.search;
        if (search && search.length > 1) {
            const queryCollection = new URLSearchParams(search);
            const culture = correctCasing(queryCollection.get("culture"));
            if (isLanguageSupported(culture))
                return culture;
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}
const getLanguageFromUiLocales = (): string | null => {
    try {
        const search = window.location.search;
        if (search && search.length > 1) {
            const queryCollection = new URLSearchParams(search);
            const returnUrl = queryCollection.get("returnUrl");
            if (returnUrl) {
                const url = new URL(returnUrl, window.location.origin);
                const uiLocales = correctCasing(url.searchParams.get("ui_locales"));
                if (isLanguageSupported(uiLocales))
                    return uiLocales;
            }
        }
    } catch (error) {
        console.error(error);
    }
    return null;
}

const languages = [
    "cs-CZ",
    "da-DK",
    "de-DE",
    "el-GR",
    "en-US",
    "en-CA",
    "en-AU",
    "en-GB",
    "en-IN",
    "en-MY",
    "en-NZ",
    "en-SG",
    "es-AR",
    "es-ES",
    "es-MX",
    "es-US",
    "et-EE",
    "fi-FI",
    "fr-BE",
    "fr-CA",
    "fr-FR",
    "hu-HU",
    "id-ID",
    "it-IT",
    "ja-JP",
    "ko-KR",
    "lt-LT",
    "lv-LV",
    "nb-NO",
    "nl-BE",
    "nl-NL",
    "pl-PL",
    "pt-BR",
    "pt-PT",
    "ro-RO",
    "ru-RU",
    "sk-SK",
    "sl-SI",
    "sv-SE",
    "tr-TR",
    "uk-UA",
    "zh-CN",
    "zh-HK",
    "zh-TW"
];
const isLanguageSupported = (language: string | null): boolean => {
    if (language && language.length === 5) {
        return languages.indexOf(language) > -1;
    }
    return false;
}
const correctCasing = (language: string | null): string | null => {
    if (language && language.indexOf('-') == 2) {
        const components = language.split("-");
        return `${components[0].toLowerCase()}-${components[1].toUpperCase()}`;
    }
    return language;
}

export { getBrowserLanguage, isLanguageSupported, getLanguageFromPath, getLanguageFromQuery, getLanguageFromUiLocales };