import React, { useState } from "react";
const style = require("./ControlPanelAccordion.less");
import { CSSTransition } from "react-transition-group";
const showAnimation = require("../Transitions/ShowAccordion.less");

import { Avatar } from "..";
import classNames from "classnames";
import { Button } from "../Button";
export interface ControlPanelAccordionProps{
    usernameLabel: string
    nicknameLabel: string
    avatarurl: string
    nickname: string
    username: string
    unlinkLabel: string
    unlinkOnclick: Function
    onToggle?: (expanded:boolean) => void
    toggleLabel?: string
}

const ControlPanelAccordion: React.FunctionComponent<ControlPanelAccordionProps> = props => {
    const toggleLabel = (props.toggleLabel != null) ? props.toggleLabel : "Toggle";

    const [expanded, setExpanded] = useState<boolean>(false);

    const toggle = () => {
        setExpanded(!expanded)
        if(props.onToggle) {
            props.onToggle(expanded)
        }
    }

    const handleUnlink = () => props.unlinkOnclick()

    return (
        <>
        <div className={classNames([style.Accordion, { [style.AccordionExpanded]: expanded }])} data-component="ControlPanelAccordion">
        <div className={style.AccordionTop}>
            <div className={style.Avatar}>
                <Avatar className={style.AvatarImg} avatarId={""} nickname={props.nickname} url={props.avatarurl} showNickname={false} />
            </div>
            <div className={style.Information}>
                <div className={style.InformationPiece}>
                    <span className={style.InformationHeader}>{props.nicknameLabel}</span>
                    <span className={style.InformationBody}>{props.nickname}</span>
                </div>
                <div className={style.InformationPiece}>
                    <span className={style.InformationHeader}>{props.usernameLabel}</span>
                    <span className={style.InformationBody}>{props.username}</span>
                </div>
                <div className={style.Unlink}>
                    <Button
                        id={`btnUnlink-${props.username}`}
                        className="test"
                        onClick={handleUnlink}
                        mode="link"
                        aria-label={`${props.unlinkLabel} ${props.usernameLabel} ${props.username}`}>
                        {props.unlinkLabel}
                    </Button>
                </div>
            </div>
            <button className={style.AccordionIcon}
                aria-expanded={expanded}
                aria-controls={`${props.username}-section`}
                        id={`${props.username}-accordion`}
                        aria-label={`${toggleLabel} ${props.usernameLabel} ${props.username}`}
                onClick={toggle}>
                {toggleLabel}
            </button>
        </div>
        <CSSTransition in={expanded} timeout={200} classNames={showAnimation} unmountOnExit>
            <div id={`${props.username}-section`}
                role="region"
                aria-labelledby={`${props.username}-accordion`}
                className={style.AccordionBody}>
                {props.children}
            </div>
        </CSSTransition>  
        </div>


        </>
    );
};

export { ControlPanelAccordion };
