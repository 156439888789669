export enum GivenConsentState {
  Unknown = 0,
  Granted = 1,
  Denied = 2
}

export enum ConsentSchemaType {
  Global = "https://vpc.lego.com/schemas/consents/global.json",
  Experience = "https://vpc.lego.com/schemas/consents/experience.json",
  Blanket = "https://vpc.lego.com/schemas/consents/blanket.json"
}

declare global {
  interface ConsentConfiguration {
    readonly experience: ConsentConfigurationExperience;
    readonly consents: BaseConsent<BaseConsentData>[];
  }
  interface StartConsentData {
    readonly profile: Partial<UserProfile>;
    readonly hasLinkedParent: boolean;
  }
  interface ConsentConfigurationExperience {
    readonly experienceAuthority: string;
    readonly data: ConsentConfigurationExperienceData;
  }

  interface ConsentConfigurationExperienceData {
    readonly title: string;
    readonly logo?: string;
  }

  interface BaseConsent<
    T extends
      | string
      | BaseConsentData
      | GlobalConsentData
      | ExperienceConsentData
      | BlanketConsentData
  > {
    readonly state: GivenConsentState;
    readonly consentOptionUri: string;
    readonly data: T;
  }

  interface BaseConsentData {
    readonly schema: ConsentSchemaType;
    readonly order?: number;
  }

  interface GlobalConsentData extends BaseConsentData {
    readonly title: string;
    readonly description: string;
    readonly icon: string;
  }

  interface ExperienceConsentData extends GlobalConsentData {}

  interface BlanketConsentData extends BaseConsentData {
    readonly title: string;
    readonly terms: BlanketConsentTerm[];
  }

  interface BlanketConsentTerm {
    readonly title: string;
    readonly description: string;
    readonly icon: string;
  }
}
