import sanitizeHtml from "sanitize-html";

class ContentServices {
  createMarkup(encodedMarkup: string) {
    // TODO: Add sanitizing logic
    return { __html: sanitizeHtml(encodedMarkup) };
  }
}

export const contentServices = new ContentServices();
