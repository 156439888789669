import React, { useState } from "react";

import { GivenConsentState } from "scripts/types/consentTypes";

import { consentSorting } from "scripts/utils/consentHelper";
import { useTranslation } from "scripts/hooks/useTranslation";
import {
  BareListItem,
  Headline,
  MultiConsentCard,
  Paragraph
} from "@lego/lego-account-ui";
import { PresentationProps } from ".";

export type BlanketConsentsPresentationProps = {
  consents: BaseConsent<BlanketConsentData>[];
};

export default (
  props: PresentationProps & BlanketConsentsPresentationProps
) => {
  const { t } = useTranslation(["ConsentPage", "ExpandableFormCard"]);
  const { consentsData, consents } = props;
  const [selectedConsentState, setSelectedConsentState] = useState<
    Record<string, GivenConsentState>
  >({});

  const handleConsentChange = (uri: string, value: boolean) => {
    const consentStateValue: GivenConsentState = value
      ? GivenConsentState.Granted
      : GivenConsentState.Denied;
    props.onConsentChange(uri, consentStateValue);
    setSelectedConsentState({
      ...selectedConsentState,
      [uri]: consentStateValue
    });
  };

  const getDefaultConsentState = (uri: string): GivenConsentState => {
    return consentsData.consents.find(
      (consent) => consent.consentOptionUri === uri
    )?.state;
  };

  const getConsentStateValue = (uri: string): boolean => {
    const currentState =
      selectedConsentState[uri] || getDefaultConsentState(uri) || undefined;

    if (currentState) {
      return currentState === GivenConsentState.Granted;
    }
    return undefined;
  };

  const renderConsent = (consent: BaseConsent<BlanketConsentData>) => {
    return (
      <>
        <BareListItem key={`blanket-${consentsData.experience.data.title}`}>
          <Headline variant="h5" data-testid="blanketConsentsHeader">
            {t("ConsentPage:experience_consents_header", {
              EXPERIENCE_NAME: consentsData.experience.data.title
            })}
          </Headline>
          <Paragraph>
            {t("ConsentPage:experience_consents_subtitle", {
              EXPERIENCE_NAME: consentsData.experience.data.title
            })}
          </Paragraph>
        </BareListItem>
        <BareListItem>
          <MultiConsentCard
            consents={consent.data.terms}
            key={`consent-${consent.consentOptionUri}`}
            state={getConsentStateValue(consent.consentOptionUri)}
            onChange={(e) => {
              handleConsentChange(consent.consentOptionUri, e);
              return e;
            }}
            data-testid="blanketConsentItem"
            openDescriptionText={t("ExpandableFormCard:read_more_text")}
            closeDescriptionText={t("ExpandableFormCard:read_less_text")}
            ariaAcceptLabel={t("ConsentPage:consent_label_yes_text")}
            ariaDeclineLabel={t("ConsentPage:consent_label_no_text")}
            acceptAllText={t("ConsentPage:blanket_consent_accept_label")}
            ariaConsentTitle={consent.data.title}
            portalContainer={document.getElementById("main") || document.body}
          />
        </BareListItem>
      </>
    );
  };

  return consents.length > 0 ? (
    <>
      {consents
        .sort(consentSorting)
        .map((consent: BaseConsent<BlanketConsentData>) =>
          renderConsent(consent)
        )}
    </>
  ) : null;
};
