import React, { useState } from "react";
import classNames from "classnames";
const style = require("./Radio.less");


export interface RadioProps {
    name: string
    value: string
    label?: string
    isSelected?: boolean
    onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) 
    id: string,
    ariaLabelledBy:string
}

const Radio = (props: RadioProps) => {
    const {
        name,
        value,
        label,
        isSelected = false,
        onChange,
        id,
        ariaLabelledBy
    } = props;

    const [focus, setFocus] = useState<boolean>(false);

    const classesCircle = classNames(style.Circle);

    const classesInnerCircle = classNames(style.CircleInside, {
    [style.CircleInsideChecked]: isSelected
    });

    return (
        <label
        className={classNames(style.Radio, {[style.RadioFocus]: focus})}
        htmlFor={`radio-${id}`}
        data-testid={`radio-${id}`}
        data-isselected={isSelected}
        aria-label={`radio-${id}`}
        data-component="Radio">
        <input
            type="radio"
            id={`radio-${id}`}
            name={name}
            value={value}
            aria-labelledby={ariaLabelledBy}
            checked={isSelected}
            className={style.Input}
            onChange={onChange}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
        />
        <div className={style.RadioLeft}>
            <div className={classesCircle}>
            <div className={classesInnerCircle} />
            </div>
        </div>
          {label && <span
            id={`radioLbl-${id}`}
            className={style.Label}
          >
            {label}
        </span>}
      </label>
    )
}

export { Radio };