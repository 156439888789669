import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  Headline,
  AvatarCard,
  RichText,
  Paragraph,
  HorizonAnchor
} from "@lego/lego-account-ui";
import {
  VpcActions,
  ConsentActions,
  AuthActions,
  ErrorActions,
  ErrorTypes,
  VerifyActions
} from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";
import "../../../../styles/vpc.less";
import { Loader, usePageTitle } from "@library";
import { Legals, PageContainer } from "scripts/components";
import { abortFlow } from "scripts/utils/ModalTextsGenerator";
import { GivenConsentState } from "scripts/types/consentTypes";
import { useTranslation } from "scripts/hooks";
import Presentation from "./Presentation";

type StateProps = {
  context: ContextState;
  consent: ConsentState;
  user: UserState;
  vpc: VpcState;
};
type ActionProps = {
  verifyActions: ThunkActionsDispatch<VerifyActions>;
  consentActions: ThunkActionsDispatch<ConsentActions>;
  vpcActions: ThunkActionsDispatch<VpcActions>;
  authactions: ThunkActionsDispatch<AuthActions>;
  errorActions: ThunkActionsDispatch<ErrorActions>;
};
type State = {
  loading: boolean;
  buttonDisabled: boolean;
  consentConfig: ConsentConfiguration;
  consents: VpcConsentEntryState[];
};

export type ConsentPageProps = StateProps & ActionProps & State;

const ConsentPage = (props: ConsentPageProps): JSX.Element => {
  const {
    user,
    context,
    vpc,
    vpcActions,
    authactions: { doLogoutAndCancel },
    errorActions,
    consentActions: { saveConsents, redirectToNextStep }
  } = props;
  const { hideCloseButton } = context;
  const { consentActions, verifyActions } = props;
  const [loading, setLoading] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [consentConfig, setConsentConfig] =
    useState<ConsentConfiguration>(null);
  const [consents, setConsents] = useState<VpcConsentEntryState[]>([]);

  const { t } = useTranslation([
    "ConsentPage",
    "ExpandableFormCard",
    "AbortFlow",
    "DashboardPage"
  ]);
  usePageTitle(t("ConsentPage:header"));

  const searchParams = new URLSearchParams(window.location.search);
  const childNickname = searchParams.get("n");
  const childPuid = searchParams.get("uid");

  const updateMissingConsents = (newConsents: VpcConsentEntryState[]) => {
    const isConsentSet = (uri: string): boolean =>
      !!newConsents.find((c) => c.consentOptionUri === uri);
    const missingResponse = consentConfig.consents.filter(
      (consent) =>
        consent.state === GivenConsentState.Unknown &&
        !isConsentSet(consent.consentOptionUri)
    );
    if (missingResponse.length === 0) {
      setButtonDisabled(false);
    }
  };

  useEffect(() => {
    if (!vpc.VPCToken) {
      vpcActions.ensureVpcToken();
    }
  }, []);
  useEffect(() => {
    (async () => {
      setConsentConfig(await consentActions.loadConsentConfiguration());
      await verifyActions.isParentCountrySupported();
      setLoading(false);
    })();
  }, [vpc.IsLoaded === true]);
  useEffect(() => {
    if (consentConfig?.consents.length > 0) {
      const newConsents = [
        ...consents,
        ...consentConfig.consents
          .filter((c) => c.state === 1 || c.state === 2)
          .map((c) => ({
            consentOptionUri: c.consentOptionUri,
            state: c.state
          }))
      ];
      setConsents(newConsents);
      updateMissingConsents(newConsents);
    }
  }, [consentConfig?.consents]);

  const saveContents = async () => {
    if (await saveConsents(consents)) {
      await redirectToNextStep(consents.length !== 0);
    } else {
      errorActions.ErrorAction({ error: ErrorTypes.SERVER_ERROR });
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Loader />
      </PageContainer>
    );
  }

  const handleSubmit = () => {
    if (consentConfig.consents.length !== consents.length) {
      return;
    }
    setButtonDisabled(true);
    saveContents().catch(() => {
      setButtonDisabled(false);
    });
  };

  const renderInfoMessage = () =>
    consentConfig.consents.length !== consents.length && (
      <Paragraph data-testid="consentInfoMsg">
        {t("ConsentPage:no_consent_text")}
      </Paragraph>
    );

  const handleConsentChange = (
    consentOptionUri: string,
    state: GivenConsentState
  ) => {
    const newConsents = [
      ...consents.filter((c) => c.consentOptionUri !== consentOptionUri),
      { consentOptionUri, state }
    ];
    setConsents(newConsents);
    updateMissingConsents(newConsents);
  };

  const renderConsents = () => (
    <Box marginTop="sm" marginBottom="lg">
      <HorizonAnchor horizonType="uphill">
        <Presentation
          consentsData={consentConfig}
          onConsentChange={handleConsentChange}
        />
      </HorizonAnchor>
    </Box>
  );

  const renderContent = () => (
    <>
      <Headline variant="h1" marginBottom="lg">
        <span data-testid="consentPageHeader">{t("ConsentPage:header")}</span>
      </Headline>
      <RichText marginBottom="md">{t("ConsentPage:subheader")}</RichText>

      <AvatarCard
        margin="none"
        avatarSrc={`${context.serviceEndpoints.avatarService}/api/v4/avatar/${childPuid}/bighead`}
      >
        <Paragraph data-testid="child-nickname">
          <b>{t("ConsentPage:child_nickname")}</b>
          <br />
          {childNickname}
        </Paragraph>
      </AvatarCard>

      {renderConsents()}
      {renderInfoMessage()}
      <Box marginTop="md">
        <Button
          data-testid="consentNextBtn"
          isDisabled={buttonDisabled}
          onPress={handleSubmit}
        >
          {t("ConsentPage:next_button")}
        </Button>
      </Box>
    </>
  );

  const closeButtonParameters = {
    display: !hideCloseButton,
    showWarning: true,
    onClick: () => doLogoutAndCancel(),
    closeButtonLabel: t("close_button"),
    modalContent: abortFlow(t)
  };

  return (
    <>
      <PageContainer
        closeButtonParameters={{
          ...closeButtonParameters,
          display: hideCloseButton !== true
        }}
        legalComponent={<Legals context={context} />}
      >
        {renderContent()}
      </PageContainer>
    </>
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    context: state.context,
    vpc: state.vpc,
    consent: state.consent,
    user: state.auth.user
  };
}

function mapDispatchToProps(dispatch: Dispatch): ActionProps {
  return {
    vpcActions: bindActionCreators(VpcActions, dispatch),
    verifyActions: bindActionCreators(VerifyActions, dispatch),
    consentActions: bindActionCreators(ConsentActions, dispatch),
    authactions: bindActionCreators(AuthActions, dispatch),
    errorActions: bindActionCreators(ErrorActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsentPage);
