import NoExternalLinksTransform from "../utils/NoExternalLinksTransform";
import constants from "scripts/constants";
import { requestAsync } from "scripts/services";

export enum LegalsActionsTypes {
  LEGALS_FETCHED = "[LEGALS] LEGALS_FETCHED"
}

export interface LegalInformation {
  readonly PrivacyContent?: {
    readonly Title?: string;
    readonly Content?: string;
  };
  readonly CookiesContent?: {
    readonly Title?: string;
    readonly Content?: string;
  };
}

export namespace LegalsActions {
  const legalsFetched = (cookie: string, privacy: string): ThunkAction => (
    dispatch
  ) => {
    dispatch({
      type: LegalsActionsTypes.LEGALS_FETCHED,
      privacy,
      cookie
    });
  };

  const fetchContent = async (
    host: string,
    policyType: string,
    culture: string = constants.defaultCulture
  ) => {
    const response = await requestAsync<LegalInformation>({
      endpoint: host,
      path: `/static-assets/profile/${culture.toLowerCase()}/${policyType}/LegalInformation.json`,
      method: "GET",
      credentials: "omit",
      mode: "no-cors"
    });
    if (response.ok) {
      return response.payload;
    }
    return null;
  };

  const getOverlayContent = async (
    culture: string,
    context: ContextState,
    isAdult: boolean
  ) => {
    const policyType = isAdult ? "adult" : "child";
    const response = await fetchContent(
      context.serviceEndpoints.vpcRootDomain,
      policyType,
      culture
    );
    if (response === null) {
      if (
        culture.toLowerCase() === constants.defaultCulture.toLocaleLowerCase()
      ) {
        return null;
      } else {
        return await fetchContent(
          context.serviceEndpoints.vpcRootDomain,
          policyType,
          constants.defaultCulture
        );
      }
    }
    return response;
  };

  export const prepareLegals = (): ThunkAction => async (
    dispatch,
    getState
  ) => {
    const { context } = getState();

    const transformer = new NoExternalLinksTransform(
      context.appContext,
      context.serviceEndpoints.vpcRootDomain
    );

    // OBS: Maybe make sure we can swith the adult text for child text for pages where children is present?
    const content = await getOverlayContent(context.culture, context, true);
    dispatch(
      legalsFetched(
        transformer.Apply(content?.CookiesContent?.Content),
        transformer.Apply(content?.PrivacyContent?.Content)
      )
    )
  };
}

export type LegalsActions = typeof LegalsActions;
