import appConfigJson from "scripts/appConfigs.json";

export interface ApplicationConfig {
  readonly identityClientId: string;
  readonly oAuthConsentClientIds: string[];
  readonly requestMode?: string;
  readonly overrideHideHeader: boolean;
  readonly serviceEndpoints: {
    readonly displaynameService: string;
    readonly avatarService: string;
    readonly rootDomain: string;
    readonly vpcRootDomain: string;
    readonly wwwRootDomain: string;
    readonly identityService: string;
    readonly sandboxRootDomain: string;
    readonly trackingService: string;
    readonly trackingServiceTimeout: number;
  };
  readonly userManager: {
    readonly authority: string;
    readonly redirectUri: string;
    readonly silentRedirectUri: string;
    readonly postLogoutRedirectUri: string;
    readonly customRedirectUri: string;
    readonly scopes: string[];
  };
}

const parseConfigs = (
  configs: Record<string, ApplicationConfig>,
  configKey: string
): ApplicationConfig | undefined => configs[configKey] || undefined;

class ApplicationConfigContainer {
  appConfig: ApplicationConfig = null;
  init() {
    this.appConfig = parseConfigs(appConfigJson, window.location.origin);
  }

  instance() {
    if (!this.appConfig) {
      this.init();
    }
    return this.appConfig;
  }
}
const container = new ApplicationConfigContainer();
export default container;

export const getAppConfig = () => container.instance();

export const getRequestMode = (fallback: RequestMode): RequestMode =>
  (getAppConfig().requestMode as RequestMode) || fallback;
