import React, { useState } from "react";
import { GivenConsentState } from "scripts/types/consentTypes";
import consentIconHelper from "scripts/utils/consentIconHelper";
import { consentSorting } from "scripts/utils/consentHelper";
import { useTranslation } from "scripts/hooks/useTranslation";
import {
  ConsentCard,
  Headline,
  Paragraph,
  BareListItem
} from "@lego/lego-account-ui";
import { PresentationProps } from ".";

export type ExperienceConsentsPresentationProps = {
  consents: BaseConsent<ExperienceConsentData>[];
};

export default (
  props: PresentationProps & ExperienceConsentsPresentationProps
) => {
  const { t } = useTranslation(["ConsentPage", "ExpandableFormCard"]);
  const { consentsData, consents } = props;
  const [selectedConsentState, setSelectedConsentState] = useState<
    Record<string, GivenConsentState>
  >({});

  const handleConsentChange = (uri: string, value: boolean) => {
    const consentStateValue: GivenConsentState = value
      ? GivenConsentState.Granted
      : GivenConsentState.Denied;
    props.onConsentChange(uri, consentStateValue);
    setSelectedConsentState({
      ...selectedConsentState,
      [uri]: consentStateValue
    });
  };

  const getDefaultConsentState = (uri: string): GivenConsentState => {
    return consentsData.consents.find(
      (consent) => consent.consentOptionUri === uri
    )?.state;
  };

  const getConsentStateValue = (uri: string): boolean => {
    const currentState =
      selectedConsentState[uri] || getDefaultConsentState(uri) || undefined;

    if (currentState) {
      return currentState === GivenConsentState.Granted;
    }
    return undefined;
  };

  const renderConsent = (
    consent: BaseConsent<GlobalConsentData>,
    iconOverride?: string
  ) => {
    return (
      <BareListItem key={`experience-${consent.consentOptionUri}`}>
        <ConsentCard
          visual={
            <img
              alt={consent.data.title}
              src={iconOverride || consentIconHelper(consent.data.icon)}
              width="100%"
            />
          }
          state={getConsentStateValue(consent.consentOptionUri)}
          onChange={(e) => {
            handleConsentChange(consent.consentOptionUri, e);
            return e;
          }}
          title={consent.data.title}
          description={consent.data.description}
          openDescriptionText={t("ExpandableFormCard:read_more_text")}
          data-testid="experienceConsentItem"
          closeDescriptionText={t("ExpandableFormCard:read_less_text")}
          ariaAcceptLabel={t("ConsentPage:consent_label_yes_text")}
          ariaConsentTitle={consent.data.title}
          ariaDeclineLabel={t("ConsentPage:consent_label_no_text")}
          portalContainer={document.getElementById("main") || document.body}
        />
      </BareListItem>
    );
  };

  return consents.length > 0 ? (
    <>
      <BareListItem
        data-testid="featureConsentsList"
        key={`experience-${consentsData.experience.data.title}`}
      >
        <Headline variant="h5" data-testid="experienceConsentsHeader">
          {t("ConsentPage:experience_consents_header", {
            EXPERIENCE_NAME: consentsData.experience.data.title
          })}
        </Headline>
        <Paragraph>
          {t("ConsentPage:experience_consents_subtitle", {
            EXPERIENCE_NAME: consentsData.experience.data.title
          })}
        </Paragraph>
      </BareListItem>
      {consents
        .sort(consentSorting)
        .map((consent: BaseConsent<ExperienceConsentData>) =>
          renderConsent(consent)
        )}
    </>
  ) : null;
};
