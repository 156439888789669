const icons = require.context("../../assets/consents", false);

export default (variant: string): string => {
  if (variant === null || variant === undefined) return icons("./Generic.svg");

  if (variant.toLowerCase().startsWith("https")) return variant;
  if (icons.keys().indexOf(`./${variant}.svg`) !== -1)
    return icons(`./${variant}.svg`);
  return icons("./Generic.svg");
};
