import constants from "./constants";
import { getParameterByName, convertToBool, getParameterAsBool } from "./utils";
import StorageFactory from "./utils/StorageFactory";
import {
  getBrowserLanguage,
  getLanguageFromPath,
  getLanguageFromQuery
} from "@library";

const storage = StorageFactory.getStorage();

// optionalOverrideQuerystring can be used to search for a value in the return url for example
function getParameter(
  name: string,
  defaultValue?: string,
  optionalOverrideQuerystring?: string
) {
  var param;

  // First get some persistent storage
  if (storage.getItem(name)) {
    param = storage.getItem(name);
  }

  // Then override with provided values if they exist
  if (getParameterByName(name)) {
    param = getParameterByName(name);
  }
  if (optionalOverrideQuerystring) {
    if (getParameterByName(name, optionalOverrideQuerystring)) {
      param = getParameterByName(name, optionalOverrideQuerystring);
    }
  }

  // Then use default value if still not present
  var isProvided = true;
  if (!param) {
    param = defaultValue;
    isProvided = false;
  } else if (param === defaultValue) {
    isProvided = false;
  }

  if (param) {
    storage.setItem(name, param);
  }

  return { value: param, isProvided };
}

export default function getCurrentStateFromQueryParameters(
  ownClientId: string
): Partial<ContextState> {
  const isott = convertToBool(getParameterByName("isott"));

  // Culture
  const culture = new Intl.Locale(
    getLanguageFromPath() ??
    getLanguageFromQuery() ??
    storage.getItem("culture") ??
    getBrowserLanguage()
  ).baseName;

  storage.setItem("culture", culture);

  // ReturnUrl
  var {
    value: returnurl,
    isProvided: returnUrlWasProvidedByClient
  } = getParameter("returnUrl", "/" + culture + "/dashboard");

  // AppContext
  var { value: appContext } = getParameter("appContext", "false", returnurl);

  let hideheader = getParameterAsBool("hideheader", returnurl);
  let hideCloseButton = getParameterAsBool("hideclosebutton", returnurl);

  // Storage defaultTheme from constants
  storage.setItem("theme", constants.defaultTheme);
  
  // ClientId
  var {
    value: clientid,
    isProvided: clientIdWasProvidedByClient
  } = getParameter("clientId", ownClientId, returnurl);
  const { value: state } = getParameter("state");

  var { value: featureToggleString } = getParameter("feature", "0");
  var feature = 0;
  if (featureToggleString) {
    feature = parseInt(featureToggleString);
  }

  return {
    returnurl,
    returnUrlWasProvidedByClient,
    appContext: convertToBool(appContext),
    hideheader,
    hideCloseButton,
    clientid,
    clientIdWasProvidedByClient,
    culture: culture.replace(/(^\/)|(\/$)/g, ""),
    isott,
    state,
    theme: constants.defaultTheme,
    feature
  };
}
