import React, { useEffect, useRef, useState } from "react";
import {
  Header,
  Loader,
} from "@library";
import { BackButtonParameters, CloseButtonParameters, PageContainer } from "scripts/components";
import { AvatarPicker, Box, Button, FlexItem, NicknamePicker, HorizonAnchor } from "@lego/lego-account-ui";

export namespace PublicProfilePresentation {
  export type I18N = {
    header: string;
    buttonLabel: string;
    a11yNext: string;
    a11yPrevious: string;
    loadingText: string;
  };

  export type Props = {
    i18n: I18N;
    avatars: AvatarOutfitEntry[];
    nicknames: string[];
    colors?: string[];
    disabled?: boolean;
    onContinue?: () => void;
    onChange?: (
      avatar: AvatarOutfitEntry,
      nickname: string
    ) => void;
    backButtonParameters?: BackButtonParameters;
    closeButtonParameters?: CloseButtonParameters;
    isLoading?: boolean;
    hideCloseButton?: boolean;
    legalComponent?: JSX.Element;
  };
}

export const PublicProfilePresentation = React.memo(
  ({
    i18n,
    avatars,
    nicknames,
    disabled = false,
    onContinue = () => {},
    onChange = () => {},
    backButtonParameters,
    closeButtonParameters,
    isLoading = false,
    hideCloseButton = false,
    legalComponent = <div></div>
  }: PublicProfilePresentation.Props) => {
    const renderContent = () => {
      const [avatarIndex, setAvatarIndex] = useState(0);
      const [nickname, setNickname] = useState(nicknames[0]);

      const isMounted = useRef(false);

      useEffect(() => {
        if (isMounted.current) {
          onChange(avatars[avatarIndex], nickname);
        } else {
          isMounted.current = true;
        }
      }, [avatarIndex, nickname]);

      return (
        <>
          <Header title={i18n.header} headerTestId="avatarHeader" />
            
            <FlexItem direction="column" gap="lg">
              <HorizonAnchor horizonType="circle">
                <Box>
                  <AvatarPicker
                    avatars={avatars.map(avatar => avatar.minifigureImageUrl)}
                    onChange={(index) => {
                      setAvatarIndex(index);
                    }}
                    nextButtonLabel={i18n.a11yNext}
                    previousButtonLabel={i18n.a11yPrevious}
                    />
                </Box>
              </HorizonAnchor>

              <NicknamePicker
                displayNames={nicknames}
                nextLabel={i18n.a11yNext}
                prevLabel={i18n.a11yPrevious}
                onChange={(newName) => setNickname(newName)}
                />

              <Button
                data-testid="continueBtn"
                isDisabled={disabled}
                onPress={onContinue}
              >
                {i18n.buttonLabel}
              </Button>
            </FlexItem>
        </>
      );
    };

    return (
      <>
        <PageContainer
          backButtonParameters={backButtonParameters}
          closeButtonParameters={{
            ...closeButtonParameters,
            display: hideCloseButton !== true
          }}
          legalComponent={legalComponent}
        >
          {isLoading ? (
            <Loader ariaLabel={i18n.loadingText} />
          ) : (
            renderContent()
          )}
        </PageContainer>
      </>
    );
  }
);
