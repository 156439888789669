import React, { forwardRef, LegacyRef } from "react";
const style = require("./ActionAccordionList.less");

export interface ActionAccordionListProps {
  testId?: string;
  children: React.ReactNode;
}

const ActionAccordionList = forwardRef((props: ActionAccordionListProps, ref: LegacyRef<HTMLUListElement>) => {

  return (
    <ul ref={ref} className={style.ActionAccordionList} data-component="ActionAccordionList" data-testid={props.testId}>
      {props.children}
    </ul>
  );
});

export { ActionAccordionList };
