import { httpGet, httpPost, httpPut } from "scripts/services/httpClient";
import { requestAsync, RestResponse } from "scripts/services/restClient";
import { createHeader } from "scripts/services/headerFactory";
import { getAccessTokenAsync } from "scripts/services";
import { ErrorTypes } from "scripts/actions/errorActions";
import {
  VerificationError,
  VerificationMethod,
  VerificationStatus
} from "scripts/types/storeTypes";
import { getRequestMode } from "scripts/utils/applicationConfig";

declare global {
  interface DataEnvelope<T> {
    readonly data: T;
  }
  interface FinalizeFlowResponse {
    readonly token: string;
  }
  interface GetVerificationStatusResponse {
    readonly verified: boolean;
    readonly VerifiedAt: string;
  }
  interface VerificationStatusData {
    readonly status: number;
    readonly verificationStatus: string;
    readonly messageId: string;
    error_type?: ErrorTypes;
  }
  interface PostConsentsData {
    readonly status: string;
  }

  interface CreateSessionFromChildResponse {
    readonly token: string;
    readonly expires: string;
  }

  interface CreateSessionFromTokenResponse {
    readonly token: string;
    readonly expires: string;
    readonly verifiedEmailToken: string;
    readonly securityId: string;
  }

  interface CurrentUserData {
    readonly type: string;
    readonly title: string;
    readonly status: number;
    readonly detail: string;
    readonly instance: string;
  }

  interface AccountLinksStatusData {
    readonly upgradeToken: string;
    readonly child: {
      readonly requiresUpgrade: boolean;
      readonly requiresAcceptingTos: boolean;
      readonly nickname: string;
      readonly userId: string;
      readonly isLinked: boolean;
    };
  }

  interface PaymentSessionData {
    readonly paymentSession: string;
  }

  interface UserConsentsResponse {
    readonly experiences: ConsentConfiguration[];
    readonly globalConsents: BaseConsent<BaseConsentData>[];
  }
  interface ChildConsentsPayload {
    sub: string;
    consents: VpcConsentEntryState[];
  }

  interface ClientConsentsData {
    readonly consents: ConsentsData[];
    readonly clientId: string;
    readonly returnUrl: string;
  }
  interface GetCurrentChildConsentsResponse {
    readonly consents: Consent[];
  }
  interface Consent {
    readonly optionId: string;
    readonly state: ConsentStateValue;
  }
  export type ConsentStateValue = "UNDECIDED" | "GRANTED" | "DENIED";

  interface GetAllConsentsContentResponse {
    readonly globalConsents: GetConsentContentResponse[];
    readonly experiences: GetAllExperienceConsentContentResponse[];
  }
  interface GetAllExperienceConsentContentResponse {
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly icon?: string;
    readonly consents: GetConsentContentResponse[];
  }

  interface GetExperienceConsentsContentResponse {
    readonly consents: GetConsentContentResponse[];
  }
  interface GetConsentContentResponse {
    readonly id: string;
    readonly global?: GetGlobalConsentContentResponse;
    readonly experience?: GetExperienceConsentContentResponse;
    readonly blanket?: GetBlanketConsentContentResponse;
  }
  interface GetGlobalConsentContentResponse {
    readonly title: string;
    readonly description: string;
    readonly icon?: string;
  }
  interface GetExperienceConsentContentResponse {
    readonly title: string;
    readonly description: string;
    readonly icon?: string;
  }
  interface GetBlanketConsentContentResponse {
    readonly title: string;
    readonly terms: GetBlanketConsentContentTermResponse[];
  }
  interface GetBlanketConsentContentTermResponse {
    readonly title: string;
    readonly description: string;
    readonly icon?: string;
  }
  interface ConsentsData {
    readonly consentOptionUri: string;
    state: number;
    data: string;
  }

  interface ParentEmailPayload {
    readonly parentEmail: string;
    readonly culture: string;
    readonly clientId: string;
    readonly returnUrl: string;
    readonly upgradeToken: string;
  }

  interface RequestUpdateConsentsPayload {
    readonly culture: string;
    readonly clientId: string;
    readonly returnUrl: string;
  }

  interface GetExperienceContentResponse {
    readonly id: string;
    readonly title: string;
    readonly logo: string;
    readonly culture: string;
  }

  interface RequestVerificationsSessionResponse {
    readonly redirectUrl: string;
    readonly sessionId: string;
  }

  interface RequestVerificationResponse {
    status: VerificationStatus;
    errorCode: VerificationError;
  }

  interface RequestVerificationMethodsResponse {
    readonly methods: VerificationMethod[];
  }

  interface LinkedChild {
    readonly userId: string;
    readonly nickName: string;
    readonly userName: string;
  }

  interface LinkedParent {
    readonly userId: string;
  }

  interface LinkedChildrenData {
    readonly children: LinkedChild[];
  }

  interface CurrentParentsData {
    readonly parents: LinkedParent[];
  }

  interface LinkedChildConsentsData {
    readonly experiences: LinkedChildConsentsExperience[];
    readonly globalConsents: BaseConsent<string>[];
  }
  interface CreditCardPaymentData {
    readonly status: number;
    readonly verificationStatus: string;
    readonly verified: string;
    messageId: string;
    error_type: string;
  }

  interface LinkedChildConsentsExperience {
    readonly experience: LinkedChildConsentsExperienceEntry;
    readonly consents: BaseConsent<string>[];
  }

  interface LinkedChildConsentsExperienceEntry {
    readonly experienceAuthority: string;
    readonly data: string;
  }
}

export namespace VPCApiClient {
  export async function saveChildConsents(
    vpcRootDomain: string,
    childId: string,
    consents: VpcConsentEntryState[],
    culture: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<void>({
      method: "PUT",
      endpoint: vpcRootDomain,
      path: `/api/v4/Consents/Child/${encodeURIComponent(childId)}`,
      mode: getRequestMode("same-origin"),
      data: {
        culture: culture,
        consents: consents
      },
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  export async function getUserConsents(vpcRootDomain: string, userId: string) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<GetCurrentChildConsentsResponse>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: `/api/v4/Consents/Child/${userId}`,
      mode: getRequestMode("same-origin"),
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  export async function getClientConsentsJourney(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    return await requestAsync<ClientConsentsData>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: "/api/v2/consent",
      mode: getRequestMode("same-origin"),
      headers: createHeader({ "Lego-VPC-Token": vpcToken })
    });
  }

  export async function getAllConsentsContent(
    vpcRootDomain: string,
    culture: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<GetAllConsentsContentResponse>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: `/api/v4/Consents/Content/${encodeURIComponent(culture)}`,
      mode: getRequestMode("same-origin"),
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });
  }

  export async function getConsentsContent(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    return await requestAsync<GetExperienceConsentsContentResponse>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: "/api/v4/Consents/Content/Current",
      mode: getRequestMode("same-origin"),
      headers: createHeader({ "Lego-VPC-Token": vpcToken })
    });
  }
  export async function getChildConsents(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    return await requestAsync<GetCurrentChildConsentsResponse>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: "/api/v4/Consents/Child",
      mode: getRequestMode("same-origin"),
      headers: createHeader({ "Lego-VPC-Token": vpcToken })
    });
  }

  export async function postConsents(
    vpcRootDomain: string,
    vpcToken: string,
    consents: VpcConsentEntryState[]
  ) {
    const data = {
      consents
    };

    const url = `${vpcRootDomain}/api/v2/consent/save`;

    return (await httpPost(
      url,
      data,
      createHeader({ "Lego-VPC-Token": vpcToken })
    )) as PostConsentsData;
  }

  export async function persistConsents(
    vpcRootDomain: string,
    vpcToken: string
  ): Promise<void> {
    const response = await requestAsync<{}>({
      method: "PUT",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v2/consent/persist`,
      headers: { "Lego-VPC-Token": vpcToken }
    });
    if (response.ok) return;
    if (response.internalError) throw ErrorTypes.SERVER_ERROR;
    if (response.unauthorized) throw ErrorTypes.LOGIN_REQUIRED;
    if (response.badRequest) throw ErrorTypes.BAD_REQUEST;
    if (response.errors?.length > 0) throw response.errors[0];
    throw response.error ?? ErrorTypes.GENERIC_ERROR;
  }

  export async function finalize(
    vpcRootDomain: string,
    accessToken: string,
    vpcToken: string
  ): Promise<FinalizeFlowResponse> {
    const response = await requestAsync<DataEnvelope<FinalizeFlowResponse>>({
      method: "POST",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v2/consent/finalize`,
      credentials: "same-origin",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Lego-VPC-Token": vpcToken
      }
    });
    if (response.ok) return response.payload.data;

    if (response.internalError) throw ErrorTypes.SERVER_ERROR;
    if (response.unauthorized) throw ErrorTypes.LOGIN_REQUIRED;
    if (response.badRequest) throw ErrorTypes.BAD_REQUEST;
    if (response.errors?.length > 0) throw response.errors[0];
    throw response.error ?? ErrorTypes.GENERIC_ERROR;
  }

  export async function getCurrentParents(
    vpcRootDomain: string,
    accessToken: string
  ) {
    const url = `${vpcRootDomain}/api/v2/users/current/parents`;

    const res = await httpGet<DataEnvelope<CurrentParentsData>>(
      url,
      createHeader({ Authorization: accessToken })
    );

    return res.data;
  }

  export async function postParentEmail(
    vpcRootDomain: string,
    childAccessToken: string,
    parentEmail: string,
    culture: string,
    clientId: string,
    returnUrl: string,
    upgradeToken: string
  ): Promise<RestResponse<ParentEmailPayload>> {
    const data: ParentEmailPayload = {
      parentEmail,
      culture,
      clientId,
      returnUrl,
      upgradeToken
    };

    return await requestAsync<ParentEmailPayload>({
      method: "POST",
      endpoint: vpcRootDomain,
      mode: getRequestMode("same-origin"),
      path: "/api/v3/accountLinks/requestLinking",
      headers: {
        authorization: `bearer ${childAccessToken}`
      },
      data
    });
  }

  export async function accountLinksStatus(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<AccountLinksStatusData>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `${vpcRootDomain}/api/v3/accountLinks/status`,
      headers: createHeader({
        Authorization: accessToken,
        "Lego-VPC-Token": vpcToken
      })
    });
  }

  export async function createSessionFromChild(
    vpcRootDomain: string,
    clientId: string,
    culture: string
  ): Promise<RestResponse<CreateSessionFromChildResponse>> {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<CreateSessionFromChildResponse>({
      method: "POST",
      endpoint: vpcRootDomain,
      mode: getRequestMode("same-origin"),
      path: "/api/v4/Sessions/Child",
      headers: createHeader({
        Authorization: accessToken,
        "Content-Type": "application/json"
      }),
      data: { clientId, culture }
    });
  }

  export async function createSessionFromToken(
    vpcRootDomain: string,
    session: string,
    culture: string
  ): Promise<RestResponse<CreateSessionFromTokenResponse>> {
    return await requestAsync<CreateSessionFromTokenResponse>({
      method: "POST",
      endpoint: vpcRootDomain,
      mode: getRequestMode("same-origin"),
      path: "/api/v4/Sessions/Token",
      headers: createHeader({
        "Content-Type": "application/json"
      }),
      data: { session, culture }
    });
  }

  export async function SetCurrentAdultUser(
    vpcRootDomain: string,
    vpcToken: string
  ): Promise<RestResponse<void>> {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<void>({
      method: "POST",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v4/Sessions/Adult",
      headers: createHeader({
        Authorization: accessToken,
        "Lego-VPC-Token": vpcToken,
        "Content-Type": "application/json"
      }),
      data: {}
    });
  }

  export async function postRequestUpdateConsents(
    vpcRootDomain: string,
    childAccessToken: string,
    culture: string,
    clientId: string,
    returnUrl: string
  ): Promise<RestResponse<RequestUpdateConsentsPayload>> {
    const data: RequestUpdateConsentsPayload = {
      culture,
      clientId,
      returnUrl
    };

    return await requestAsync<RequestUpdateConsentsPayload>({
      method: "POST",
      endpoint: vpcRootDomain,
      mode: getRequestMode("same-origin"),
      path: "/api/v3/accountLinks/requestUpdateConsents",
      headers: {
        authorization: `bearer ${childAccessToken}`
      },
      data
    });
  }

  export async function getExperienceConfiguration(
    vpcRootDomain: string,
    vpcToken: string
  ): Promise<RestResponse<GetExperienceContentResponse>> {
    return await requestAsync<GetExperienceContentResponse>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v4/Experiences/Content/Current",
      headers: createHeader({
        "Content-Type": "application/json",
        "Lego-VPC-Token": vpcToken
      })
    });
  }

  export async function getVerificationMethods(vpcRootDomain: string) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<RequestVerificationMethodsResponse>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v4/verifications/methods",
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export async function getVerificationSession(
    method: VerificationMethod,
    vpcRootDomain: string,
    culture: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<RequestVerificationsSessionResponse>({
      method: "POST",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v4/verifications/${method}`,
      data: {
        culture
      },
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export async function getPendingVerificationStatus(
    sessionId: string,
    vpcRootDomain: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<RequestVerificationResponse>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v4/verifications/${sessionId}`,
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export async function getCurrentUserVerificationStatus(
    vpcRootDomain: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await getVerificationStatus(vpcRootDomain, accessToken);
  }

  export async function getVerificationStatus(
    vpcRootDomain: string,
    accessToken: string
  ) {
    return await requestAsync<GetVerificationStatusResponse>({
      method: "GET",
      endpoint: vpcRootDomain,
      path: "/api/v4/Verifications",
      mode: getRequestMode("same-origin"),
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export async function putRelationsCurrent(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<void>({
      method: "PUT",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v3/relations/current",
      headers: createHeader({
        "Content-Type": "application/json",
        "Lego-VPC-Token": vpcToken,
        Authorization: accessToken
      })
    });
  }

  export async function getRelationsCurrentChildren(vpcRootDomain: string) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<LinkedChildrenData>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v3/relations/current/children",
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export async function validateReturnUrl(
    vpcRootDomain: string,
    clientId: string,
    returnurl: string,
    culture: string
  ): Promise<boolean> {
    const response = await requestAsync<LinkedChildrenData>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: "/api/v4/flow/ReturnToClient/validate",
      query: {
        returnurl: returnurl,
        clientId: clientId,
        culture: culture
      },
      headers: createHeader({
        "Content-Type": "application/json"
      })
    });
    return response.ok;
  }

  export async function deleteRelationsCurrent(
    vpcRootDomain: string,
    userId: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<void>({
      method: "DELETE",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v3/relations/current/${userId}`,
      headers: createHeader({
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }

  export const delayResponse = async <T>(
    waitFor: Promise<T>,
    delay: number
  ): Promise<T> => {
    return Promise.all([
      waitFor,
      new Promise((resolve) => setTimeout(resolve, delay))
    ]).then((values) => values[0]);
  };

  interface AccountLinksStatusResponse {
    child: {
      requiresUpgrade: boolean;
      requiresAcceptingTos: boolean;
      userId: string;
      nickname: string;
      isLinked: string;
    },
    upgradeToken: string;
  }

  export async function getAccountLinksStatus(
    vpcRootDomain: string,
    vpcToken: string
  ) {
    const accessToken = await getAccessTokenAsync();
    return await requestAsync<AccountLinksStatusResponse>({
      method: "GET",
      mode: getRequestMode("same-origin"),
      endpoint: vpcRootDomain,
      path: `/api/v4/accountLinks/status`,
      headers: createHeader({
        "Lego-VPC-Token": vpcToken,
        "Content-Type": "application/json",
        Authorization: accessToken
      })
    });
  }
}
