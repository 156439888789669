interface Header {
    "Authorization"?: string;
    "Lego-VPC-Token"?: string;
    "Content-Type"?: string;
}

export const createHeader = (headerOptions: Header) => {
    const header = {
        ...headerOptions
    };

    if (headerOptions.Authorization) {
        header.Authorization = `Bearer ${headerOptions.Authorization}`;
    }

    return header;
}