import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
const style = require("./FormElement.less");

export type CheckBoxElementAlignment = "start" | "center" | "end";

export interface CheckBoxElementProps {
  /**
   * The alignment of the element withing its container
   */
  align?: CheckBoxElementAlignment

  labelTestId?: string
}

const CheckBoxElement = (props: CheckBoxElementProps & FormElementProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  var {label, labelTestId, error, align = "start", ...inputProps} = props;

  const classnames = classNames(
    { [style.CheckBox]: true,
    },
    props.className);

    const classnamesContainer = classNames(
    { [style.CheckBoxContainer]: true,
      [style.CheckBoxContainerAlignStart]: align === "start",
      [style.CheckBoxContainerAlignCenter]: align === "center",
      [style.CheckBoxContainerAlignEnd]: align === "end",
    });
    const classnamesCheckbox = classNames(
    { [style.CheckBox]: true,
      [style.CheckBoxError]: error && error !== "" && error.length !== 0
    });
  
  const renderElement = () => {
    return <label data-testid={labelTestId} htmlFor={inputProps.id} className={classnamesContainer}>
      <input
        {...inputProps}
        type="checkbox"
        className={classnames}
      />
      <span className={classnamesCheckbox} />
      <span className={style.CheckBoxLabel}>{props.label}</span>
    </label>
  }

  return (
    <FormElement
      {...props}
      hideLabel
      element={renderElement()} />
  )

}

export { CheckBoxElement };