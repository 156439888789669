import React, { useEffect, useState } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions, JourneyActions } from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { RequestReconsent } from "scripts/components/presentation/RequestReconsent";
import { useTranslation } from "scripts/hooks/useTranslation";

export type StateProps = {
  context: ContextState;
};
export type ActionProps = {
  authActions: ThunkActionsDispatch<AuthActions>;
  journeyActions: ThunkActionsDispatch<JourneyActions>;
};
export type Props = StateProps & ActionProps;

const RequestReconsentContainer = (props: Props) => {
  const { t } = useTranslation(["Generic", "JourneyGetYourParents"]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { context, authActions, journeyActions } = props;
  const { hideCloseButton } = context;

  usePageTitle(t("JourneyGetYourParents:title"));
  useTrackPage(PAGE_TYPE.FLOW_OPTIONS);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextClick = async () => {
    setIsLoading(true);
    try {
      await journeyActions.triggerReconsentByEmailAsync();
    } catch (_) {
      setIsLoading(false);
    }
  };

  const handleCloseButtonOnClick = () =>
    authActions.ReturnToClientWithReasonCancel(true);

  const closeButtonParameters = {
    onClick: handleCloseButtonOnClick,
    closeButtonLabel: t("close_button")
  };

  return (
    <RequestReconsent
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      legalComponent={<Legals context={context} />}
      onContinue={nextClick}
      isContinueLoading={isLoading}
      i18n={{
        header: t("JourneyGetYourParents:header"),
        text: t("JourneyGetYourParents:already_linked_text"),
        continueLabel: t("JourneyGetYourParents:form_submit_label"),
        loadingText: t("loading_text")
      }}
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    context: state.context
  };
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    journeyActions: bindActionCreators(JourneyActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestReconsentContainer);
