import { Button, HorizonAnchor } from "@lego/lego-account-ui";
import {
  Section,
  Header,
  Img,
  Loader
} from "@library";
import { BackButtonParameters, CloseButtonParameters, PageContainer } from "scripts/components";
const assets = require.context("./assets/", false);

export namespace GetYourParents {
  export type I18N = {
    header: string;
    text: string;
    buttonLabel: string;
    loadingText: string;
  };

  export type Props = {
    i18n: I18N;
    onContinue?: () => void;
    backButtonParameters?: BackButtonParameters;
    closeButtonParameters?: CloseButtonParameters;
    isLoading?: boolean;
    hideCloseButton?: boolean;
    legalComponent?: JSX.Element;
  };
}

export const GetYourParents = ({
  i18n,
  onContinue = () => { },
  backButtonParameters,
  closeButtonParameters,
  isLoading = false,
  hideCloseButton = false,
  legalComponent = <div></div>
}: GetYourParents.Props) => {

  const renderContent = () => {

    if (isLoading)
      return <Loader />

    return (
      <>
        <Header
          title={i18n.header}
          introText={i18n.text}
          headerTestId="getParentHeader"
        />

        <Section>
          <HorizonAnchor horizonType="downhill">
            <Img
              srcImg={[
                assets("./kid-parent@1x.png"),
                assets("./kid-parent@2x.png"),
                assets("./kid-parent@3x.png"),
                assets("./kid-parent@4x.png")
              ]}
              alt="kid with phone and parent"
            />
          </HorizonAnchor>

          <Button
            data-testid="iAmParentBtn"
            onPress={onContinue}
          >
            {i18n.buttonLabel}
          </Button>
        </Section>
      </>
    );
  };

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        closeButtonParameters={{
          ...closeButtonParameters,
          display: hideCloseButton !== true
        }}
        legalComponent={legalComponent}
      >
        {renderContent()}
      </PageContainer>
    </>
  );
};
