export const consentSorting = (consentA:BaseConsent<BaseConsentData>, consentB:BaseConsent<BaseConsentData>):number => {
  if(consentA.data.order && consentB.data.order) {
    if(consentA.data.order > consentB.data.order)
      return 1;
    if(consentA.data.order < consentB.data.order)
      return -1
  } else {
    if(consentA.data.order)
      return 1;
    if(consentB.data.order)
      return -1
  }
  return 0
}