import { GivenConsentState } from "scripts/types/consentTypes";
import { ActionAccordionValue } from "@library";

export type LinkedProfilePresentationProps = {
  profile: LinkedChild;
  consent:
    | BaseConsent<BaseConsentData>
    | BaseConsent<GlobalConsentData>
    | BaseConsent<ExperienceConsentData>
    | BaseConsent<BlanketConsentData>;
  consentState: VpcConsentEntryState[];
  onChange?: (id: string, value: GivenConsentState) => void;
  experience?: ConsentConfigurationExperience;
};

export const getConsentState = (
  consentStates: VpcConsentEntryState[],
  consent: BaseConsent<BaseConsentData>
): GivenConsentState => {
  const found = consentStates.find(
    (item) => item.consentOptionUri === consent.consentOptionUri
  );
  return found ? found.state : consent.state;
};

export const mapConsentStateToValue = (
  state: GivenConsentState
): ActionAccordionValue => {
  switch (state) {
    case GivenConsentState.Granted:
      return ActionAccordionValue.Yes;
    case GivenConsentState.Denied:
      return ActionAccordionValue.No;
    case GivenConsentState.Unknown:
      return ActionAccordionValue.NotSet;
    default:
      throw new Error(`GivenConsentState had invalid state ${state}`);
  }
};

export { default as PresentationFactory } from "./PresentationFactory";
export { default as ExperiencePresentation } from "./ExperiencePresentation";
export { default as GlobalPresentation } from "./GlobalPresentation";
export { default as BlanketPresentation } from "./BlanketPresentation";
