import { Button } from "@lego/lego-account-ui";
import { InputElement, Loader, Section } from "@library";
import { FormikHelpers, Formik, Form, Field, FieldProps } from "formik";
import constants from "scripts/constants";
import { ObjectSchema as YupObjectSchema, object as YupObject, string as YupString } from "yup";

export type ParentEmailFormValues = {
  readonly email: string;
};

export type ParentEmailFormI18N = {
  readonly emailLabelText: string;
  readonly emailPlaceholderText: string;
  readonly emailErrorMessages: {
    readonly [index: string]: string;
    readonly patternMismatch: string;
    readonly valueMissing: string;
  };
  readonly submitLabel: string;
  readonly loadingText: string;
};

export type Props = {
  readonly i18n: ParentEmailFormI18N;
  readonly onSubmit?: (
    values: ParentEmailFormValues,
    formikHelpers: FormikHelpers<ParentEmailFormValues>
  ) => void;
};

export const ParentEmailForm = ({
  i18n,
  onSubmit = () => {}
}: Props): JSX.Element => {

  const upgradeSchema: YupObjectSchema<any> = YupObject().shape({
    email: YupString()
      .max(255, i18n.emailErrorMessages.patternMismatch)
      .matches(constants.emailPattern, {
        message: i18n.emailErrorMessages.patternMismatch,
        excludeEmptyString: true
      })
      .required(i18n.emailErrorMessages.valueMissing)
  });

  return (
    <>
      <Section>
        <Formik
          validationSchema={upgradeSchema}
          enableReinitialize
          initialValues={{
            email: ""
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form name="upgradeemail" id="upgradeemail" className="newform">
              <Field name="email">
                {({
                  field,
                  form: { touched, errors }
                }: FieldProps<null, ParentEmailFormValues>) => (
                  <InputElement
                    id="email"
                    label={i18n.emailLabelText}
                    inputMode="email"
                    autoComplete="email"
                    placeholder={i18n.emailPlaceholderText}
                    disabled={isSubmitting}
                    value={field.value}
                    error={touched.email && errors.email && errors.email}
                    {...field}
                  />
                )}
              </Field>
              <Button
                isLoading={isSubmitting}
                loadingLabel={i18n.loadingText}
                type="submit"
                data-testid="ParentEmailButton"
                isDisabled={isSubmitting}
              >
                {i18n.submitLabel}
              </Button>
            </Form>
          )}
        </Formik>
      </Section>
    </>
  );
};
