import {
  Header,
  Img,
  Loader,
  Section
} from "@library";
import {
  ParentEmailForm,
  ParentEmailFormValues
} from "scripts/components/presentation/FlowOptions";
import { BackButtonParameters, CloseButtonParameters, PageContainer } from "scripts/components";
import { Button, HorizonAnchor } from "@lego/lego-account-ui";

const assets = require.context(
  "scripts/components/presentation/GetYourParents/assets/",
  false
);
export namespace GetYourParentsWithEmail {
  export type I18N = {
    header: string;
    text: string;
    modalButtonLabel: string;
    emailLabelText: string;
    emailPlaceholderText: string;
    submitLabel: string;
    loadingText: string;
    emailErrorMessages: {
      patternMismatch: string;
      valueMissing: string;
    };
  };

  export type Props = {
    i18n: I18N;
    onContinue?: (values: ParentEmailFormValues) => Promise<void>;
    onOpenModal?: () => void;
    backButtonParameters?: BackButtonParameters;
    closeButtonParameters?: CloseButtonParameters;
    isLoading?: boolean;
    hideCloseButton?: boolean;
    legalComponent?: JSX.Element;
  };
}

export const GetYourParentsWithEmail = ({
  i18n,
  onContinue,
  onOpenModal,
  backButtonParameters,
  closeButtonParameters,
  isLoading = false,
  hideCloseButton = false,
  legalComponent = <div></div>
}: GetYourParentsWithEmail.Props) => {

  const renderContent = () => (
    <>
      <Header
        title={i18n.header}
        introText={i18n.text}
        headerTestId="getParentWithEmailHeader"
      />
      <Section>
        <HorizonAnchor horizonType="uphill">
          <Img
            srcImg={[
              assets("./kid-parent@1x.png"),
              assets("./kid-parent@2x.png"),
              assets("./kid-parent@3x.png"),
              assets("./kid-parent@4x.png")
            ]}
            alt="kid with phone and parent"
          />
        </HorizonAnchor>
        <ParentEmailForm
          onSubmit={onContinue}
          i18n={{
            emailErrorMessages: {
              patternMismatch: i18n.emailErrorMessages.patternMismatch,
              valueMissing: i18n.emailErrorMessages.valueMissing
            },
            emailLabelText: i18n.emailLabelText,
            emailPlaceholderText: i18n.emailPlaceholderText,
            submitLabel: i18n.submitLabel,
            loadingText: i18n.loadingText
          }}
        />
        <Button
          data-testid="GetYourParentsWithEmailModal"
          variant="link"
          onPress={onOpenModal}
        >
          {i18n.modalButtonLabel}
        </Button>
      </Section>
    </>
  );

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        closeButtonParameters={{
          ...closeButtonParameters,
          display: hideCloseButton !== true
        }}
        legalComponent={legalComponent}
      >
        {isLoading ? <Loader ariaLabel={i18n.loadingText} /> : renderContent()}
      </PageContainer>
    </>
  );
};
