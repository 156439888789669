import React from "react";
import { GivenConsentState } from "scripts/types/consentTypes";

import {
  ActionAccordion,
  ActionAccordionValue
} from "@library/ActionAccordion";
import { Headline } from "@library/Typography";
import { useTranslation } from "scripts/hooks/useTranslation";
import {
  LinkedProfilePresentationProps,
  getConsentState,
  mapConsentStateToValue
} from ".";

export type BlanketPresentationProps = {
  consent: BaseConsent<BlanketConsentData>;
};

export default ({
  consentState,
  profile,
  consent,
  experience,
  onChange
}: LinkedProfilePresentationProps & BlanketPresentationProps) => {
  const { t } = useTranslation(["ConsentPage", "ExpandableFormCard"]);
  const handleConsentChange = (id: string, value: string) => {
    if (onChange)
      onChange(
        id,
        value === ActionAccordionValue.Yes
          ? GivenConsentState.Granted
          : GivenConsentState.Denied
      );
    return true;
  };

  return (<ActionAccordion
      key={`profile-${profile.userId}-consent-blanket-${consent.consentOptionUri}`}
      id={`profile-${profile.userId}-consent-blanket-${consent.consentOptionUri}`}
      imageSrc={experience?.data?.logo}
      imageAlt={experience?.data?.title}
      value={mapConsentStateToValue(getConsentState(consentState, consent))}
      onChange={(e) =>
        handleConsentChange(consent.consentOptionUri, e.target.value)
      }
      className="ControlPanelConsents"
      i18n={{
        labelNo: t("ConsentPage:consent_label_no_text"),
        labelYes: t("ConsentPage:consent_label_yes_text"),
        readLess: t("ExpandableFormCard:read_less_text"),
        readMore: t("ExpandableFormCard:read_more_text"),
        title: experience?.data?.title
      }}
      testId={`ActionAccordion-${consent.consentOptionUri}`}
    >
      {consent.data.terms.map((term: any, key: number) => (
        <div key={`consent-${consent.consentOptionUri}-term-${key}`}>
          <Headline variant="h6" align="left">
            {term.title}
          </Headline>
          <p>{term.description}</p>
        </div>
      ))}
    </ActionAccordion>
  );
};
