import { VPCApiClient, IdentityApiClient } from "scripts/services";
import {
  AuthActions,
  ErrorActions,
  ErrorTypes,
  NavigationRoute,
  RouteActions
} from "scripts/actions";
import { push } from "connected-react-router";
import { JourneyRoute, resolvePath } from "scripts/routes";

export enum JourneyActionTypes {
  USER_CONSENTABLE_STATE_VALIDATED = "[JOURNEY] USER_CONSENTABLE_STATE_VALIDATED"
}

export namespace JourneyActions {
  export const validateCurrentUserIsConsentable = (): ThunkActionAsync => async (
    dispatch
  ) => {
    const currentUser = await dispatch(AuthActions.GetCurrentUser());

    if (!currentUser) {
      throw new Error(ErrorTypes.ACCOUNT_REQUIRED);
      return;
    }

    if (currentUser?.profile?.isadult === true) {
      dispatch(
        ErrorActions.ErrorAction({
          error: ErrorTypes.ACCOUNT_IS_ADULT_IN_CHILD_CONTEXT
        })
      );
      return;
    }

    dispatch<UserConsentableStateLoaded>({
      type: JourneyActionTypes.USER_CONSENTABLE_STATE_VALIDATED,
      isConsentable: true
    });
  };

  export const triggerParentFlowByEmailAsync = (
    email: string
  ): ThunkActionAsync => async (dispatch, getState) => {
    const { context } = getState();
    const {
      culture,
      clientid,
      returnurl,
      serviceEndpoints: { vpcRootDomain, identityService }
    } = context;

    const user = await dispatch(AuthActions.GetCurrentUser());
    try {
      const responseGetUpgradeToken =
        user?.profile?.is_upgradable === true
          ? await IdentityApiClient.getUpgradeToken(identityService)
          : null;
      const response = await VPCApiClient.postParentEmail(
        vpcRootDomain,
        user.access_token,
        email,
        culture,
        clientid,
        returnurl,
        responseGetUpgradeToken?.payload?.token
      );

      if (response.ok) {
        dispatch(RouteActions.goto(NavigationRoute.PARENT_EMAIL_SENT));
      } else if (response.badRequest) {
        if (
          response.errors.includes(ErrorTypes.USER_LINKED_TO_DIFFERENT_ACCOUNT)
        ) {
          throw new Error(ErrorTypes.USER_LINKED_TO_DIFFERENT_ACCOUNT);
        } else if (response.errors.includes(ErrorTypes.USER_IS_ADULT)) {
          throw new Error(ErrorTypes.USER_IS_ADULT);
        } else if (response.errors.includes(ErrorTypes.INVALID_USER)) {
          throw new Error(ErrorTypes.INVALID_USER);
        } else if (response.errors.includes(ErrorTypes.BANNED_EMAIL)) {
          throw new Error(ErrorTypes.BANNED_EMAIL);
        } else {
          dispatch(ErrorActions.ErrorResponse(response));
        }
      } else {
        dispatch(ErrorActions.ErrorResponse(response));
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
    }
  };

  export const triggerReconsentByEmailAsync = (): ThunkActionAsync => async (
    dispatch,
    getState
  ) => {
    const { context } = getState();
    const {
      culture,
      clientid,
      returnurl,
      serviceEndpoints: { vpcRootDomain }
    } = context;

    const user = await dispatch(AuthActions.GetCurrentUser());
    try {
      const response = await VPCApiClient.postRequestUpdateConsents(
        vpcRootDomain,
        user?.access_token,
        culture,
        clientid,
        returnurl
      );

      if (response.ok) {
        dispatch(RouteActions.goto(NavigationRoute.CHILD_RECONSENT_SUCCESS));
      } else if (response.badRequest) {
        if (response.errors.includes(ErrorTypes.USER_IS_ADULT)) {
          throw new Error(ErrorTypes.USER_IS_ADULT);
        } else if (response.errors.includes(ErrorTypes.INVALID_USER)) {
          throw new Error(ErrorTypes.INVALID_USER);
        } else if (response.errors.includes(ErrorTypes.BANNED_EMAIL)) {
          throw new Error(ErrorTypes.BANNED_EMAIL);
        } else {
          dispatch(ErrorActions.ErrorResponse(response));
          throw new Error(ErrorTypes.BAD_REQUEST);
        }
      } else {
        dispatch(ErrorActions.ErrorResponse(response));
        throw new Error(ErrorTypes.GENERIC_ERROR);
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  };

  export const redirectChildBasedOnLinkState = (): ThunkActionAsync => async (
    dispatch,
    getState
  ) => {
    const { context, router } = getState();
    const {
      culture,
      serviceEndpoints: { vpcRootDomain }
    } = context;

    const currentUser = await dispatch(AuthActions.GetCurrentUser());
    if (currentUser) {
      const currentParentsResponse = await VPCApiClient.getCurrentParents(
        vpcRootDomain,
        currentUser?.access_token
      );
      if (currentParentsResponse.parents.length > 0) {
        dispatch(
          push(
            resolvePath(
              JourneyRoute.RECONSENT_REQUEST,
              culture,
              router.location.search,
              router.location.hash
            )
          )
        );
        return;
      }
    }

    dispatch(
      push(
        resolvePath(
          JourneyRoute.PUBLIC_PROFILE,
          culture,
          router.location.search,
          router.location.hash
        )
      )
    );
  };
}

export type JourneyActions = typeof JourneyActions;
