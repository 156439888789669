export enum ModalActionTypes {
    MODAL_TRIGGERED = "[MODAL] MODAL_TRIGGERED",
    MODAL_DISMISSED = "[MODAL] MODAL_DISMISSED",
}

type TriggerParams = Omit<ModalState, "type" | "isOpen">

export namespace ModalActions {

    export const trigger = (params: TriggerParams): ThunkAction => (dispatch) => {
        dispatch({
            type: ModalActionTypes.MODAL_TRIGGERED,
            ...params
        });
    }

    export const dismiss = (): ThunkAction => (dispatch) => {
        dispatch({
            type: ModalActionTypes.MODAL_DISMISSED,
        });
    }

}

export type ModalActions = typeof ModalActions;
