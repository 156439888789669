export enum ContextActionTypes {
    QUERY_CONTEXT_CHANGED = "[CONTEXT] QUERY_CONTEXT_CHANGED",
    CLIENT_CONTEXT_CHANGED = "[CONTEXT] CLIENT_CONTEXT_CHANGED",
    JOURNEY_CONTEXT_CHANGED = "[JOURNEY] JOURNEY_CONTEXT_CHANGED"
}

export namespace ContextActions {
    export function QueryContextChanged(returnurl: string, clientid: string, state: string, theme: string, adultexperience: boolean, appContext: boolean, childsignupexperience: string) : QueryContextChanged {
        return {
            type: ContextActionTypes.QUERY_CONTEXT_CHANGED,
            clientid,
            returnurl,
            state,
            theme,
            adultexperience,
            appContext,
            childsignupexperience
        };
    };
    export function ClientContextChanged(returnurl: string, clientid: string, state: string, theme: string) : ClientContextChanged {
        return {
            type: ContextActionTypes.CLIENT_CONTEXT_CHANGED,
            clientid,
            returnurl,
            state,
            theme
        };
    };
    export function JourneyContextChanged(journey:JourneyContext) : JourneyContextChanged {
        return {
            type: ContextActionTypes.JOURNEY_CONTEXT_CHANGED,
            journey
        };
    };

}

export type ContextActions = typeof ContextActions;