import React from "react";
import { Avatar } from "../Avatar";
const style = require("./ParentInfo.less");

export interface ParentInfoProps{
    emailLabel: string
    nicknameLabel: string
    avatarurl: string
    nickname: string
    email: string
    verified: boolean
    verifiedLabel: string
}

const ParentInfo: React.FunctionComponent<ParentInfoProps> = props => {
     return (
        <div className={style.ParentInfo} data-component="ParentInfo">
            <div className={style.Avatar}>
                <Avatar className={style.AvatarImg} avatarId={""} nickname={props.nickname} url={props.avatarurl} showNickname={false} />
            </div>
            <div className={style.Information}>
                <div className={style.InformationPiece}>
                    <span className={style.InformationHeader}>{props.nicknameLabel}</span>
                    <span data-testid="nickname">{props.nickname}</span>
                </div>
                <div className={style.InformationPiece}>
                    <span className={style.InformationHeader}>{props.emailLabel}</span>
                    <span data-testid="email">{props.email}</span>
                </div>
                {props.verified && 
                    <div className={style.InformationVerified}>{props.verifiedLabel} <span className={style.Icon}></span></div>
                }
            </div>
        </div>
    );
};

export { ParentInfo };
