import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { History } from "history";
import { persistReducer, PersistConfig } from "redux-persist";
import {
  ContextReducer,
  AuthReducer,
  VPCReducer,
  ConsentReducer,
  VerifyReducer,
  JourneyReducer,
  PublicProfileReducer,
  ModalReducer
} from "scripts/reducers";
import StorageFactory from "scripts/utils/StorageFactory";

const authConfig: PersistConfig = {
  key: "auth",
  storage: StorageFactory.storageAsync,
  whitelist: ["country"]
};
const journeyConfig: PersistConfig = {
  key: "context",
  storage: StorageFactory.storageAsync,
  whitelist: ["journey"]
};

const vpcConfig: PersistConfig = {
  key: "vpc",
  storage: StorageFactory.storageAsync,
  whitelist: ["VPCToken"]
};

const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    context: persistReducer(journeyConfig, ContextReducer),
    auth: persistReducer(authConfig, AuthReducer),
    vpc: persistReducer(vpcConfig, VPCReducer),
    consent: ConsentReducer,
    verify: VerifyReducer,
    journey: JourneyReducer,
    publicProfile: PublicProfileReducer,
    modal: ModalReducer
  });

export default rootReducer;
