/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { Loader, useTrackPage } from "@library";
import {
  AuthActions,
  ErrorActions,
  ErrorTypes,
  GetYourParentsActions,
  JourneyActions
} from "scripts/actions";
import { PAGE_TYPE } from "scripts/services";
import { useTranslation } from "scripts/hooks/useTranslation";

type ActionProps = {
  authActions: ThunkActionsDispatch<AuthActions>;
  getYourParentsActions: ThunkActionsDispatch<GetYourParentsActions>;
  journeyActions: ThunkActionsDispatch<JourneyActions>;
  errorActions: ThunkActionsDispatch<ErrorActions>;
};

type Props = ActionProps;

const ChildJourneyBeginPage = (props: Props) => {
  const {
    authActions,
    journeyActions,
    getYourParentsActions,
    errorActions
  } = props;
  const { t } = useTranslation("Generic");

  useTrackPage(PAGE_TYPE.CHILD_JOURNEY_BEGIN);

  useEffect(() => {
    Promise.all([
      authActions.ValidateClient(),
      getYourParentsActions.EnsureLoggedInUser(false)
    ])
      .then(async ([validClient, loggedInUser]) => {
        if (validClient && loggedInUser) {
          await journeyActions.validateCurrentUserIsConsentable();
          await journeyActions.redirectChildBasedOnLinkState();
        } else {
          errorActions.ErrorAction({ error: ErrorTypes.GENERIC_ERROR });
        }
      })
      .catch((error) => errorActions.ErrorAction({ error }));
  }, []);

  return <Loader ariaLabel={t("loading_text")} />;
};

const mapDispatchToProps = (dispatch: Dispatch): Props => {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    getYourParentsActions: bindActionCreators(GetYourParentsActions, dispatch),
    journeyActions: bindActionCreators(JourneyActions, dispatch),
    errorActions: bindActionCreators(ErrorActions, dispatch)
  };
};

export default connect(null, mapDispatchToProps)(ChildJourneyBeginPage);
