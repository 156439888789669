import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
const style = require("./FormElement.less");

export enum ComponentStyle {
  "consent"
}

export enum ComponentType {
  "checkmark",
  "close"
}

interface RadioProps {
  componentstyle?: ComponentStyle
  componenttype?: ComponentType
}

const RadioElement = (props: RadioProps & FormElementProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const {formname, label, error, ...inputProps} = props;

    const classnamesContainer = classNames(
    {
      [style.RadioContainer]: true,
      [style.RadioConsent]: props.componentstyle === ComponentStyle.consent
    });

    const classnamesRadio = classNames(
    {
      [style.Radio]: true,
      [style.RadioError]: error && error !== "" && error.length !== 0,
      [style.Checkmark]: props.componenttype === ComponentType.checkmark,
      [style.Close]: props.componenttype === ComponentType.close,
    });

  const renderElement = () => <label
    htmlFor={inputProps.id}
    className={classnamesContainer}
    tabIndex={-1}
    aria-label={props.label}
  >
    {props.label}
    <input
      {...inputProps}
      type="radio"
      className={classnamesRadio}
    />
    <span className={classnamesRadio} />
  </label>

  return (
    <FormElement
      customClass={classNames({ [style.RadioConsentFormElement]: props.componentstyle === ComponentStyle.consent})}
      {...props}
      hideLabel
      element={renderElement()} />
  )

}

export { RadioElement };