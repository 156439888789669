import React from "react";
import { Avatar } from "../Avatar";
import classNames from "classnames";
const style = require("./DisplayAvatarLogo.less");

export type IconType = "userExperienceIcon" | "backIcon"

export interface DisplayAvatarLogoProps {
  appLogo: string;
  appTitle?: string;
  avatarId: string,
  transitionType: IconType;
  avatarLogo: string;
  alt: string;
}


const DisplayAvatarLogo: React.FC<DisplayAvatarLogoProps> = ({
  appLogo,
  appTitle,
  avatarId,
  transitionType,
  alt,
  avatarLogo
}) => {

  const imgStyle = {
    backgroundImage: `url(${ appLogo })`
  };

  
  
  const typeStyle = classNames(transitionType ? { [style[transitionType || ""]]: true } : {});


  return (
    <div className={style.flexBox} data-component="DisplayAvatarLogo">
      <Avatar
        className={style.CustomAvatarSpan}
        showNickname={false}
        avatarId={avatarId}
        url={avatarLogo}
      />
      <div className={style.transitionContainer}>
      <img alt={alt} className={classNames(typeStyle)}/>
      </div>
      <div className={style.appLogo}>
        <div className={style.appLogoWrapper}>
            <div className={style.appLogoImage} style={imgStyle} data-testid="appLogoImage"></div>
        </div>
          { appTitle ? <span className={style.appLogoText}>{appTitle}</span> : null }
      </div>
    </div>
  );
};
export { DisplayAvatarLogo };
