import * as React from "react";
import { Route } from "react-router";

const Refresh = ({ path = "/", history, location }) => (
  <Route
    path={path}
    component={() => {
      history.replace({
        ...location,
        pathname: location.pathname.split("/refresh")[1]
      });
      return null;
    }}
  />
);

export default Refresh;
