const assets = require.context("./assets", false);
import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
import { useState } from "react";
const style = require("./FormElement.less");
export type InputType = "password" | "text" | "number" | "email" | "tel";

export interface InputProps {
  /**
   * If set will be called when enter is pressed in the input field
   */
  onEnter?: () => void,
  type?: InputType,
  /**
   * autoComplete value from https://www.w3.org/TR/WCAG21/#input-purposes
   */
  autoComplete?: string,
  /**
   * Placeholder text used when inputType === "password"
   */
  passwordPlaceholder?: string,
  inputMode?: string,
  /**
   * Controls the "viewability" of the password. If true will show password as default
   */
  defaultIsPasswordVisible?: boolean
  /**
   * Aria label for the password toggle icon
   */
  ariaLabelPasswordToggle?: string
}


const InputElement = (props: InputProps & FormElementProps & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>) => {
  const {
    id,
    error,
    onEnter,
    type = "text",
    placeholder,
    passwordPlaceholder = "********",
    inputMode = "text",
    disabled,
    className,
    label,
    defaultIsPasswordVisible,
    ariaLabelPasswordToggle,
    ...inputProps } = props;
  const [inputType, setInputType] = useState(type === "password" && defaultIsPasswordVisible === true ? "text" : type);

    const classnames = classNames(
      {
        [style.Input]: !type || type !== "password",
        [style.InputContainer]: true,
        [style.InputContainerDisabled]: disabled,
        [style.PasswordToggleContainer]: type === "password",
        [style.InputError]: error && error !== "" && error.length !== 0
      },
      props.className);

    const togglePassword = (evt: any) => {
      if (evt.type === "click" || (evt.which || evt.keyCode) === 13) {
        if (inputType === "password") {
          setInputType("text");
        } else if (inputType === "text") {
          setInputType("password");
        }
      }
    }

  const onEnterInternal = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (onEnter && (evt.which || evt.keyCode) === 13) {
      onEnter();
    }
  }

  const renderElement = () => {
    switch (type) {
      case "password":
        return (
          <div className={classnames}>
            <input
              {...inputProps}
              id={id}
              className={classNames([style.PasswordInput, style.Input])}
              type={inputType}
              disabled={disabled}
              placeholder={inputType === "password" ? passwordPlaceholder : placeholder}
              inputMode={inputMode}
              onKeyPress={onEnterInternal}
            />
            <button
              id={`${id}-toggle`}
              tabIndex={-1}
              aria-label={ariaLabelPasswordToggle}
              type="button"
              onKeyDown={togglePassword}
              onClick={togglePassword}
              className={classNames(style.PasswordToggleImage, { [style.IconEyeClosed]: inputType === "password" }, { [style.IconEyeOpen]: inputType !== "password" })} />
          </div>
        );
      default:
        return <input
          {...inputProps}
          id={id}
          disabled={disabled}
          inputMode={inputMode}
          placeholder={placeholder}
          className={classnames}
          onKeyPress={onEnterInternal}
        />
    }
  }

  return (
    <FormElement
      {...inputProps}
      id={id}
      label={label}
      error={error}
      element={renderElement()} />
  )

}

export { InputElement };