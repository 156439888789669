import React from "react";

const style = require("./Label.less");

export interface LabelProps {
  value: string
}

const Label: React.FC<LabelProps> = ({value, children}) => {

  return (
    <dl className={style.container}>
      <dt className={style.topic}>{value}</dt>
      <dd className={style.description}>{children}</dd>
    </dl>
  )

}

export { Label };