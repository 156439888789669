import { ErrorActions, ErrorTypes } from "./errorActions";
import { VPCApiClient } from "../services/vpcApiClient";
import {
  ConsentSchemaType,
  GivenConsentState
} from "scripts/types/consentTypes";
import { RestResponse } from "scripts/services";
import { AuthActions } from ".";
import { t } from "scripts/utils/i18n";

export enum ProfileActionTypes {}

export namespace ProfileActions {
  export const GetIsVerified =
    (): ThunkFunction<Promise<boolean>> => async (_dispatch, getState) => {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain }
        }
      } = getState();

      const response = await VPCApiClient.getCurrentUserVerificationStatus(
        vpcRootDomain
      );
      if (response.ok) return response.payload.verified;
      return false;
    };

  export const Unlink =
    (sub: string): ThunkFunction<Promise<void>> =>
    async (dispatch, getState) => {
      try {
        const {
          context: {
            serviceEndpoints: { vpcRootDomain }
          }
        } = getState();

        await VPCApiClient.deleteRelationsCurrent(vpcRootDomain, sub);
      } catch (error) {
        dispatch(ErrorActions.ErrorAction({ error }));
        throw error;
      }
    };

  export const SaveLinkedConsents =
    (payloads: ChildConsentsPayload[]): ThunkFunction<Promise<void>> =>
    async (dispatch, getState) => {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain },
          culture
        }
      } = getState();

      const requests: Promise<RestResponse<void>>[] = [];
      payloads.forEach((payload: ChildConsentsPayload) => {
        requests.push(
          VPCApiClient.saveChildConsents(
            vpcRootDomain,
            payload.sub,
            payload.consents,
            culture
          )
        );
      });

      const results = await Promise.all(requests);

      for (const result of results) {
        if (!result.ok) {
          dispatch(ErrorActions.ErrorResponse(result));
          throw ErrorTypes.LINKED_CHILD_CONSENTS_NOT_SAVED;
        }
      }
    };

  export const GetChildren =
    (): ThunkFunction<Promise<LinkedChild[]>> => async (dispatch, getState) => {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain }
        },
        router
      } = getState();

      const response = await VPCApiClient.getRelationsCurrentChildren(
        vpcRootDomain
      );
      if (response.forbidden || response.unauthorized) {
        // Not verified user or not logged in
        return [];
      }
      if (!response.ok) {
        dispatch(ErrorActions.ErrorResponse(response));
        return [];
      }
      return response.payload.children;
    };

  export const GetLinkedConsents =
    (sub: string): ThunkFunction<Promise<UserConsentsResponse>> =>
    async (dispatch, getState) => {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain },
          culture
        }
      } = getState();

      const choicesReponse = await VPCApiClient.getUserConsents(
        vpcRootDomain,
        sub
      );
      if (!choicesReponse.ok) {
        dispatch(ErrorActions.ErrorResponse(choicesReponse));
        return;
      }
      let consentRecords: Record<string, Consent> = {};
      for (const consent of choicesReponse.payload.consents) {
        consentRecords[consent.optionId] = consent;
      }
      const contentReponse = await VPCApiClient.getAllConsentsContent(
        vpcRootDomain,
        culture
      );
      if (!contentReponse.ok) {
        dispatch(ErrorActions.ErrorResponse(contentReponse));
        return;
      }

      const mappedResponse: UserConsentsResponse = {
        globalConsents: convertToBaseConsents(
          contentReponse.payload.globalConsents,
          consentRecords
        ),
        experiences: contentReponse.payload.experiences.map(
          (entry): ConsentConfiguration => ({
            experience: {
              experienceAuthority: entry.id,
              data: {
                title: entry.title,
                logo: entry.icon
              }
            },
            consents: convertToBaseConsents(entry.consents, consentRecords)
          })
        )
      };
      return mappedResponse;
    };
  function convertToBaseConsents(
    consentsContent: GetConsentContentResponse[],
    consentRecords: Record<string, Consent>
  ): BaseConsent<BaseConsentData>[] {
    return consentsContent.map((consent: GetConsentContentResponse) => {
      let c: BaseConsent<BaseConsentData>;
      if (consent.id === null) {
        throw "Missing id on consent";
      }
      if (consent.blanket)
        c = <BaseConsent<BlanketConsentData>>{
          consentOptionUri: consent.id,
          data: {
            title: consent.blanket.title,
            schema: "https://vpc.lego.com/schemas/consents/blanket.json",
            terms: consent.blanket.terms.map(
              (term) =>
                <BlanketConsentTerm>{
                  title: term.title,
                  icon: term.icon,
                  description: term.description
                }
            )
          },
          state: convertState(consentRecords[consent.id]?.state)
        };
      else if (consent.experience) {
        c = <BaseConsent<ExperienceConsentData>>{
          consentOptionUri: consent.id,
          data: {
            title: consent.experience.title,
            description: consent.experience.description,
            icon: consent.experience.icon,
            schema: "https://vpc.lego.com/schemas/consents/experience.json"
          },
          state: convertState(consentRecords[consent.id]?.state)
        };
      } else if (consent.global) {
        c = <BaseConsent<GlobalConsentData>>{
          consentOptionUri: consent.id,
          data: {
            title: consent.global.title,
            description: consent.global.description,
            icon: consent.global.icon,
            schema: "https://vpc.lego.com/schemas/consents/global.json"
          },
          state: convertState(consentRecords[consent.id]?.state)
        };
      } else {
        throw `Missing content on consent ${consent.id}`;
      }
      return c;
    });
  }
  const convertState = (state?: ConsentStateValue): GivenConsentState => {
    switch (state) {
      case "DENIED": {
        return GivenConsentState.Denied;
      }
      case "GRANTED": {
        return GivenConsentState.Granted;
      }
      case "UNDECIDED": {
        return GivenConsentState.Unknown;
      }
      default: {
        return GivenConsentState.Unknown;
      }
    }
  };
}

export type ProfileActions = typeof ProfileActions;
