import React from "react";
import { ReactNode } from "react";

const style = require('./ScreenReader.less');

const ScreenReader = ({className, children}: {children: ReactNode, className?: string})  => {
    return (
        <div className={style.ScreenReader}>{children}</div>
    );
};

export { ScreenReader };