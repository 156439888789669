import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { TrackingService } from "@library";
import { AuthActions, VpcActions, ConsentActions } from "scripts/actions";
import { PAGE_TYPE } from "scripts/services";
import { getParameterByName, convertToBool } from "scripts/utils";
import { Legals } from "scripts/components";
import "../../../../styles/vpc.less";
import constants from "scripts/constants";
import { Success } from "scripts/components/presentation/Success";
import i18n, { t } from "scripts/utils/i18n";

export namespace SuccessPage {
  export type StateProps = {
    context: ContextState;
    vpc: VpcState;
    auth: AuthState;
  };
  export type ActionProps = {
    authActions: ThunkActionsDispatch<AuthActions>;
    vpcActions: ThunkActionsDispatch<VpcActions>;
    consentActions: ThunkActionsDispatch<ConsentActions>;
  };
  export type Props = StateProps & ActionProps;

  export type State = {
    loading: boolean;
    buttonDisabled: boolean;
    isRedirect: boolean;
  };
}

class SuccessPage extends React.Component<
  SuccessPage.Props,
  SuccessPage.State
> {
  constructor (props: SuccessPage.Props) {
    super(props);
    this.state = {
      loading: true,
      buttonDisabled: false,
      isRedirect: false
    };
  }

  async consentPersistAsync(): Promise<void> {
    const {
      consentActions: { consentPersistData, addPersistFalseToUrl }
    } = this.props;
    if (this.shouldPersist()) {
      await consentPersistData();
      addPersistFalseToUrl();
      const promptType = await this.props.consentActions.finalizeFlowAsync();

      if (promptType === 'redirect')
        this.setState({ isRedirect: true });

    }
  }

  // To prevent persisting on reload we set persist=false in the querystring after load
  shouldPersist(): boolean {
    const persist = getParameterByName("persist");
    // If persist is not set, then we should persist
    return !persist || convertToBool(persist);
  }

  async componentDidMount() {
    await i18n.loadNamespaces([
      "UpgradeSuccessPage",
      "SuccessPage",
      "JourneyNickname"
    ]);
    document.title = `${constants.prefixTitlePage}${t("SuccessPage:header")}`;

    window.scrollTo(0, 0);
    TrackingService.trackPage(PAGE_TYPE.VERIFY_SUCCESS);

    if (this.props.vpc.IsLoaded === true) await this.consentPersistAsync();
    else if (this.shouldPersist()) this.props.vpcActions.ensureVpcToken();

    this.setState({ loading: false });
  }

  async componentDidUpdate(prevProps: SuccessPage.Props) {
    if (prevProps.vpc.IsLoaded === false && this.props.vpc.IsLoaded === true) {
      await this.consentPersistAsync();
    }
  }

  handleSubmit = () => {
    this.setState({ buttonDisabled: true }, () =>
      this.props.authActions.ReturnToClient()
    );
  };

  render() {
    const { loading, isRedirect } = this.state;
    const { context, auth } = this.props;
    const { serviceEndpoints } = context;

    const getAvatar = () => {
      const profile = auth?.user?.profile;
      if (profile) {
        const avatarLogo = `${serviceEndpoints.avatarService}/api/v4/avatar/${profile.sub}/Large`;
        return {
          image: avatarLogo,
          nickname: profile.nickname
        };
      }

      return null;
    };
    const avatar = getAvatar();

    return (
      <Success
        isLoading={loading || isRedirect}
        minifigure={
          avatar
            ? {
              srcImg: [avatar.image],
              alt: avatar.nickname
            }
            : null
        }
        hideCloseButton={true}
        legalComponent={<Legals context={context} />}
        i18n={{
          header: t("SuccessPage:header"),
          text: t("SuccessPage:text"),
          continueButtonLabel: t("SuccessPage:close_button"),
          loadingText: t("loading_text")
        }}
        onContinue={this.handleSubmit}
      />
    );
  }
}

function mapStateToProps(state: RootState): SuccessPage.StateProps {
  return {
    context: state.context,
    vpc: state.vpc,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch: Dispatch): SuccessPage.ActionProps {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    vpcActions: bindActionCreators(VpcActions, dispatch),
    consentActions: bindActionCreators(ConsentActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
