import React, { useRef, useState, useEffect} from 'react';
import Lottie, { EventListener } from 'react-lottie';

import animationData from './assets/animation.json';
const style = require("./MiniFigureHead.less");

export enum MiniFigureHeadAnimationType {
  "wink",
  "smile",
  "sad",
  "sunglasses",
  "sunglassesSmile"
}

type MiniFigureHeadProps = {
  animationType?: MiniFigureHeadAnimationType
}

type MiniFigureHeadAnimationSettings = {
  isPaused: boolean
  isStopped: boolean
  segments: number[]
  loop: boolean
  complete: boolean
}

const getAnimationSettings = (type:MiniFigureHeadAnimationType):MiniFigureHeadAnimationSettings => {
  const animationBase:MiniFigureHeadAnimationSettings = {
    isPaused: false,
    isStopped: false,
    loop: false,
    complete: false,
    segments: [0, 100]
  };

  switch (type) {
    case MiniFigureHeadAnimationType.wink:
      return {
        ...animationBase,
        segments: [0, 100],
        loop: true
      };
    case MiniFigureHeadAnimationType.smile:
      return {
        ...animationBase,
        segments: [100, 170],
      };
    case MiniFigureHeadAnimationType.sad:
      return {
        ...animationBase,
        segments: [180, 210],
      };
    case MiniFigureHeadAnimationType.sunglasses:
      return {
        ...animationBase,
        segments: [250, 290],
      };
    case MiniFigureHeadAnimationType.sunglassesSmile:
      return {
        ...animationBase,
        segments: [290, 330],
      };
    default:
        return animationBase;
  };
}

export const MiniFigureHead = ({animationType = MiniFigureHeadAnimationType.wink}: MiniFigureHeadProps) => {
  const lot = useRef<any>(null)
  const settings = getAnimationSettings(animationType);

  const [loopCount, setLoopCount] = useState<number>(0);
  const [buster, setBuster] = useState<number>(Date.now());

  useEffect(() => {
    // We introduce a buster to force react-lottie to rerender since animationData !== prev.animationData
    // This we cause registered events to de-registered and updated with new events in react-lottie
    setBuster(Date.now())
  }, [animationType])

  const eventListeners:EventListener[] = [{
    eventName: "DOMLoaded",
    callback: () => {
      if(lot !== null && lot.current !== null && lot.current.anim !== null) {
        const { anim } = lot.current;
        anim.playSegments(settings.segments, true);
      }
    }
  }, {
    eventName: "complete",
    callback: () => {
      if(settings.loop === true) {
        // Update component state to force reload of animationSettings
        setLoopCount(count => {
          return count+1
        });
      }
    }
  }];

  return (
    <div className={style.MiniFigureHead} aria-hidden="true">
      <Lottie
        ref={lot} 
        options={{
          animationData: {...animationData, "_bust": buster},
          loop: false 
        }}
        isClickToPauseDisabled={true}
        segments={settings.segments}
        isStopped={settings.isStopped}
        isPaused={settings.isPaused} 
        eventListeners={eventListeners}
        />
    </div>
  )

}
