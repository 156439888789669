import React, { useEffect } from "react";
import { connect } from "react-redux";
import { VerifyActions } from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";
import { getParameterByName } from "scripts/utils";
import { Loader } from "@library";
import { t } from "scripts/utils/i18n";
import { VerificationError } from "scripts/types/storeTypes";

interface Props {
  verifyActions: ThunkActionsDispatch<VerifyActions>;
}

const VerificationCallback = (props: Props) => {
  const { verifyActions } = props;

  useEffect(() => {
    const verificationMethod = getParameterByName("verificationMethod");
    const sessionId = getParameterByName("sessionId");
    const status = getParameterByName("status");
    const errorCode: VerificationError = getParameterByName(
      "errorCode"
    ) as VerificationError;

    (async () => {
      return verifyActions.handleCallback(
        verificationMethod,
        sessionId,
        status,
        errorCode
      );
    })();
  }, []);

  return <Loader ariaLabel={t("loading_text")} />;
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    verifyActions: bindActionCreators(VerifyActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(VerificationCallback);
