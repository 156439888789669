import React, { useEffect, useRef } from "react";
import sanitizeHtml from "sanitize-html";
import "./SafeContent.less";

type SafeContentProps = {
    content: string
}

const replaceTagNameElement = (el: Element, newTagName: string) => {
    if (el && el.tagName.toLowerCase() !== newTagName) {
        const newEl = document.createElement(newTagName);
        newEl.innerHTML = sanitizeHtml(el.innerHTML);
        el.parentNode.replaceChild(newEl, el);
    }
};

const createHtmlElement = (content: string) => {
    const html = document.createElement("div") as HTMLElement;
    html.classList.add("text", "formatted-text");
    html.innerHTML = sanitizeHtml(content, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img"])
    });
    return html;
};

const replacingHeadings = (
    root: HTMLElement,
    headings: string[] = ["h3", "h4", "h5", "h6"]
): HTMLElement => {
    if (!root) {
        return root;
    }
    let next = -1;
    let replaceWith: string[] = null;
    const findFirstMissingHeading = (heading: string) =>
        root.querySelectorAll(heading).length <= 0;
    headings.forEach((heading, index) => {
        if (!replaceWith && findFirstMissingHeading) {
            replaceWith = headings.slice(index);
        } else {
            const foundedHeadings = root.querySelectorAll(heading);
            if (foundedHeadings.length > 0) {
                if (next < replaceWith.length) {
                    next = next + 1;
                }
                foundedHeadings.forEach((e) =>
                    replaceTagNameElement(e, replaceWith[next])
                );
            }
        }
    });
    return root;
};

const SafeContent = ({ content = "" }: SafeContentProps) => {
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
        if (ref.current) {
            ref.current.appendChild(replacingHeadings(createHtmlElement(content)));
        }
    }, [ref, content]);

    return (
        <div ref={ref} className="safe-content" />
    );
};

export default SafeContent;