import { push } from "connected-react-router";
import {
  DefaultRoute,
  isJourneyFlow,
  JourneyRoute,
  resolveCulturedPath
} from "scripts/routes";
import { FlowType } from "scripts/types/storeTypes";

export enum NavigationRoute {
  LINK_CONSENT = "LINK_CONSENT",
  CONSENT = "CONSENT",
  VERIFY_OPTIONS = "VERIFY_OPTIONS",
  VERIFY_PROCESSING = "VERIFY_PROCESSING",
  VERIFY_ID_FAIL = "VERIFY_ID_FAIL",
  SUCCESS = "SUCCESS",
  PARENT_SUCCESS = "PARENT_SUCCESS",
  FLOW_INFO = "FLOW_INFO",
  PARENT_EMAIL_SENT = "PARENT_EMAIL_SENT",
  CHILD_RECONSENT_SUCCESS = "CHILD_RECONSENT_SUCCESS"
}
export namespace RouteActions {
  const gotoUrl = (
    flowType: FlowType,
    defaultRoute: DefaultRoute,
    journeyRoute: JourneyRoute,
    culture: string,
    search: URLSearchParams
  ): string => {
    const route = isJourneyFlow(flowType) ? journeyRoute : defaultRoute;
    return resolveCulturedPath(
      `${route}?${search.toString()}${location.hash}`,
      culture
    );
  };

  export const getRoute = (
    route: NavigationRoute,
    extraSearch?: URLSearchParams,
    useCurrentLocation: boolean = true
  ): ThunkFunction<Promise<string>> => async (_, getState) => {
    const { context, router } = getState();
    const { culture, journey } = context;
    const { location } = router;
    let url: string = undefined;
    const urlSearchParams = new URLSearchParams(
      useCurrentLocation === true ? location.search : ""
    );
    if (extraSearch) {
      extraSearch.forEach((value, key) => {
        urlSearchParams.set(key, value);
      });
    }

    switch (route) {
      case NavigationRoute.LINK_CONSENT:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.LINK_CONSENT,
          JourneyRoute.LINK_CONSENT,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.CONSENT:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.CONSENT,
          JourneyRoute.CONSENT,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.VERIFY_OPTIONS:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.OPTIONS_VERIFY,
          JourneyRoute.OPTIONS_VERIFY,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.VERIFY_PROCESSING:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.VERIFY_PROCESSING,
          JourneyRoute.VERIFY_PROCESSING,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.SUCCESS:
      case NavigationRoute.PARENT_SUCCESS:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.SUCCESS,
          JourneyRoute.PARENT_SUCCESS,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.CHILD_RECONSENT_SUCCESS:
        url = resolveCulturedPath(
          JourneyRoute.CHILD_RECONSENT_SUCCESS,
          culture
        );
        break;
      case NavigationRoute.FLOW_INFO:
        url = gotoUrl(
          journey.flowType,
          DefaultRoute.FLOW_INFO,
          null,
          culture,
          urlSearchParams
        );
        break;
      case NavigationRoute.PARENT_EMAIL_SENT:
        url = resolveCulturedPath(JourneyRoute.PARENT_EMAIL_SENT, culture);
        break;
      default:
        console.error(`Route "${route}" not handled.`);
        break;
    }
    return url;
  };

  export const goto = (
    route: NavigationRoute,
    extraSearch?: URLSearchParams,
    useCurrentLocation: boolean = true
  ): ThunkActionAsync => async (dispatch) => {
    dispatch(
      push(await dispatch(getRoute(route, extraSearch, useCurrentLocation)))
    );
  };
}

export type RouteActions = typeof RouteActions;
