import sanitizeHtml from "sanitize-html";

export default class NoExternalLinksTransform {
  appContext:boolean;
  rootDomain:string;

  constructor(appContext: boolean, rootDomain:string) {
    this.appContext = appContext;
    this.rootDomain = rootDomain
      .replace("http://", "")
      .replace("https://", "")
      .replace("www.", "");

    return this;
  }

  Apply(rawContentString: string) {
    if (!this.appContext) {
      return rawContentString;
    }

    //Locate all links
    let html = document.createElement("div");
    html.innerHTML = sanitizeHtml(rawContentString, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img' ])
    });
    const links = html.getElementsByTagName("a");
    let elementsToTransform:Array<HTMLAnchorElement> = [];

    //Find all links that should be transformed
    Object.keys(links).map((_, index) => {
      const linkElement = links[index];
      if (linkElement) {
        const href = linkElement.href;

        if (href) {
          const linkIsLegit = href.includes("://");
          const linkIsAnchor = linkElement.hash ? true : false;
          const linkIsLocal = href.includes(this.rootDomain);

          const shouldTransform =
            (linkIsLegit && linkIsLocal === false) || linkIsAnchor === false;

          if (shouldTransform) {
            elementsToTransform.push(linkElement);
          }
        }
      }
    });

    //Transform the links by using DOM manipulation since string replace will fail for parsed inner html content.
    elementsToTransform.forEach(linkElement => {
      const contentAfter = `${linkElement.innerHTML} (${linkElement.href})`;

      const newContent = document.createElement("span");
      newContent.innerHTML = contentAfter;
      linkElement.parentNode.replaceChild(newContent, linkElement);
    });

    return html.innerHTML;
  }
}
