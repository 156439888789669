import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { AuthActions, ConsentActions } from "scripts/actions";
import { Loader } from "@library";

import "../../../../styles/vpc.less";

type StateProps = {};
type ActionProps = {
  authActions: ThunkActionsDispatch<AuthActions>;
  consentActions: ThunkActionsDispatch<ConsentActions>;
};
type Props = StateProps & ActionProps;

const UpgradeSuccessPage = (props: Props) => {
  const { authActions, consentActions } = props;

  useEffect(() => {
    (async () => {
      if (!authActions.IsRedirectCancel()) {
        const user = await authActions.DoSilentLogin();
        if (user?.profile?.isadult === true) {
          authActions.ReturnToClient(true);
        } else {
          await consentActions.startConsent();
        }
      }
    })();
  }, []);

  return <Loader />;
};

function mapStateToProps(): StateProps {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch): ActionProps {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    consentActions: bindActionCreators(ConsentActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeSuccessPage);
