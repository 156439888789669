import {
  getAccessTokenAsync,
  requestAsync,
  createHeader
} from "scripts/services";
import { getAppConfig } from "scripts/utils/applicationConfig";
import StorageFactory from "scripts/utils/StorageFactory";
import random from "./random.js";

export enum AccountContext {
  Child = "child",
  Adult = "adult"
}
interface UpgradeTokenPayload {
  readonly token: string;
}
export namespace IdentityApiClient {
  export const emailTokenVerification = async (
    endpoint: string,
    token: string,
    securityId: string
  ) => {
    return requestAsync<{}>({
      endpoint,
      method: "PUT",
      path: "/api/v3/EmailTokens/VerificationToken/",
      data: {
        token,
        securityId
      },
      mode: "cors",
      credentials: "include"
    });
  };

  export const getUpgradeToken = async (endpoint: string) => {
    const accessToken = await getAccessTokenAsync();
    return requestAsync<UpgradeTokenPayload>({
      endpoint,
      method: "POST",
      headers: createHeader({ Authorization: accessToken }),
      path: "/api/v3/UpgradeTokens",
      data: {},
      mode: "cors",
      credentials: "include"
    });
  };

  export const upgradeUser = async (
    culture: string,
    state: string,
    appContext: boolean,
    upgradeToken: string,
    accountContext: AccountContext,
    initiator: AccountContext
  ) => {
    const appConfig = getAppConfig();
    if (!appConfig.userManager?.customRedirectUri)
      throw new Error("Missing appConfig value customRedirectUri");

    const stateId = random();
    StorageFactory.getStorage(window.localStorage).setItem(
      `oidc.${stateId}`,
      state
    );

    const uri = new URL(
      "/api/v3/userJourneys/upgrade",
      appConfig.serviceEndpoints.identityService
    );
    uri.searchParams.append("clientId", appConfig.identityClientId);
    uri.searchParams.append("culture", culture);
    uri.searchParams.append("state", stateId);
    uri.searchParams.append("appContext", appContext.toString());
    uri.searchParams.append("upgradeToken", upgradeToken);
    uri.searchParams.append("accountContext", accountContext);
    uri.searchParams.append("initiator", initiator);
    uri.searchParams.append(
      "returnUrl",
      appConfig.userManager.customRedirectUri
    );

    window.location.href = uri.toString();
  };
}
