import { PageType, TrackingEvent } from "@library";

type PageTypeKey =
  | "GET_YOUR_PARENTS"
  | "NICKNAME"
  | "FLOW_INFO"
  | "FLOW_OPTIONS"
  | "SUCCESS_ASYNC"
  | "CONSENT"
  | "CONSENTS"
  | "UPGRADE_SUCCESS_PAGE"
  | "VERIFY_SUCCESS"
  | "VERIFY_OPTIONS"
  | "VERIFY_OPTIONS_INFO_HELP"
  | "VERIFY_INFO"
  | "VERIFY_CREDIT_CARD_INFO"
  | "VERIFY_CREDIT_CARD_INFO_HELP"
  | "VERIFY_CREDIT_CARD_FORM"
  | "VERIFY_CREDIT_CARD_FORM_HELP"
  | "VERIFY_CREDIT_CARD_PROCESSING_PAGE"
  | "VERIFY_PROCESSING_PAGE"
  | "VERIFY_ID_PROCESSING_PAGE"
  | "HELP"
  | "HELP_INFO_MODAL"
  | "FAIL"
  | "PRIVACY_POLICY"
  | "COOKIE_POLICY"
  | "DASHBOARD"
  | "TOS"
  | "VERIFY_ID_COUNTRY_SELECT"
  | "VERIFY_TEST"
  | "VERIFY_ID_FLOW"
  | "UNLINK_MODAL"
  | "TOS_MODAL"
  | "ERROR_MODAL"
  | "PARENT_JOURNEY_BEGIN"
  | "CHILD_JOURNEY_BEGIN";

export const PAGE_TYPE: Record<PageTypeKey, PageType> = {
  GET_YOUR_PARENTS: {
    page: ["account-upgrade", "get-parents"],
    event: TrackingEvent.PARENT_ACTIVATION_INITIATED,
    channel: "vpc"
  },
  NICKNAME: {
    page: ["nickname"],
    channel: "vpc"
  },
  FLOW_INFO: {
    page: ["help", "flow-info"],
    channel: "vpc"
  },
  FLOW_OPTIONS: {
    page: ["flow-options"],
    channel: "vpc"
  },
  SUCCESS_ASYNC: {
    page: ["flow-options"],
    //event: TrackingEvent.VPC_COMPLETE, // Should we trigger an event?
    channel: "vpc"
  },
  CONSENT: {
    page: ["consent"],
    channel: "vpc"
  },
  CONSENTS: {
    page: ["consents"],
    channel: "vpc"
  },
  UPGRADE_SUCCESS_PAGE: {
    page: ["account-upgrade", "confirmation"],
    event: TrackingEvent.PARENT_ACTIVATION_COMPLETE,
    channel: "vpc"
  },
  VERIFY_SUCCESS: {
    page: ["verify", "confirmation"],
    event: TrackingEvent.VPC_COMPLETE,
    channel: "vpc"
  },
  VERIFY_OPTIONS: {
    page: ["verify", "options"],
    channel: "vpc"
  },
  VERIFY_OPTIONS_INFO_HELP: {
    page: ["verify", "options", "info", "help"],
    channel: "vpc"
  },
  VERIFY_INFO: {
    page: ["verify", "id"],
    channel: "vpc"
  },
  VERIFY_CREDIT_CARD_INFO: {
    page: ["verify", "creditCard", "info"],
    channel: "vpc"
  },
  VERIFY_CREDIT_CARD_INFO_HELP: {
    page: ["verify", "creditCard", "info", "help"]
  },
  VERIFY_CREDIT_CARD_FORM: {
    page: ["verify", "creditCard", "form"],
    channel: "vpc"
  },
  VERIFY_CREDIT_CARD_FORM_HELP: {
    page: ["verify", "creditCard", "form", "help"]
  },
  VERIFY_CREDIT_CARD_PROCESSING_PAGE: {
    page: ["verify", "creditCard", "processing"],
    channel: "vpc"
  },
  VERIFY_PROCESSING_PAGE: {
    page: ["verify", "processing"],
    channel: "vpc"
  },
  VERIFY_ID_PROCESSING_PAGE: {
    page: ["verify", "id", "processing"],
    channel: "vpc"
  },
  VERIFY_ID_COUNTRY_SELECT: {
    page: ["verify", "id", "countryselect"],
    channel: "vpc"
  },
  VERIFY_TEST: {
    page: ["verify-test"],
    channel: "vpc"
  },
  VERIFY_ID_FLOW: {
    page: ["verify", "id", "countryselect"],
    channel: "vpc"
  },
  HELP: {
    page: ["help"],
    channel: "vpc"
  },
  HELP_INFO_MODAL: {
    page: ["help", "info", "modal"],
    channel: "vpc"
  },
  FAIL: {
    page: ["verify", "fail"],
    channel: "vpc"
  },
  PRIVACY_POLICY: {
    page: ["privacy-policy"],
    channel: "vpc"
  },
  COOKIE_POLICY: {
    page: ["cookie-policy"],
    channel: "vpc"
  },
  DASHBOARD: {
    page: ["dashboard"],
    channel: "vpc"
  },
  TOS: {
    page: ["consent", "tos"],
    channel: "vpc"
  },
  UNLINK_MODAL: {
    page: ["dashboard", "unlink", "modal"],
    channel: "vpc"
  },
  TOS_MODAL: {
    page: ["dashboard", "tos", "modal"],
    channel: "vpc"
  },
  ERROR_MODAL: {
    page: ["error"],
    channel: "vpc"
  },
  PARENT_JOURNEY_BEGIN: {
    page: ["parent-journey-begin"],
    channel: "vpc"
  },
  CHILD_JOURNEY_BEGIN: {
    page: ["child-journey-begin"],
    channel: "vpc"
  }
};
