import { RouterState } from "connected-react-router";
import { LocationState } from "history";
import React from "react";
import { Redirect, RouteProps } from "react-router-dom";
import {
  CallbackConsent,
  CallbackSignout
} from "scripts/experiences/callbacks";
import {
  GetYourParentsContainer,
  ChildSuccessContainer,
  PublicProfileContainer,
  ParentJourneyBeginContainer,
  FinishedContainer,
  ChildJourneyBeginPage,
  RequestReconsentContainer
} from "scripts/experiences/journey";
import {
  CancelConsent,
  ConsentPage,
  LinkConsent,
  StartConsent,
  VerifyOptionsPage,
  VerifyProcessingPage
} from "scripts/experiences/vpc";
import { createRoute, resolvePath } from "scripts/routes";

export enum JourneyRoute {
  ROOT = "/journey",
  PARENT_JOURNEY_BEGIN_PAGE = "/journey/parent/begin",
  CHILD_JOURNEY_BEGIN_PAGE = "/journey/child/begin",
  PUBLIC_PROFILE = "/journey/child/public-profile",
  GETYOURPARENTS = "/journey/child/getyourparents",
  RECONSENT_REQUEST = "/journey/child/reconsent",
  CALLBACK_CONSENT = "/journey/callbackconsent",
  SIGN_OUT = "/journey/signout",
  CANCEL_CONSENT = "/journey/cancelconsent",
  START_CONSENT = "/journey/startconsent",
  LINK_CONSENT = "/journey/linkconsent",
  CONSENT = "/journey/consent",
  OPTIONS_VERIFY = "/journey/verify/options",
  VERIFY_PROCESSING = "/journey/verify/processing",
  PARENT_EMAIL_SENT = "/journey/child/success",
  CHILD_RECONSENT_SUCCESS = "/journey/child/reconsent/success",
  PARENT_SUCCESS = "/journey/parent/success"
}

export const journeyRoutes = (
  router: RouterState<LocationState>,
  culture: string
): RouteProps[] => [
  {
    path: createRoute(JourneyRoute.ROOT),
    component: () => (
      <Redirect
        to={resolvePath(
          JourneyRoute.CHILD_JOURNEY_BEGIN_PAGE,
          culture,
          router.location.search,
          router.location.hash
        )}
      />
    ),
    exact: true
  },
  {
    path: createRoute(JourneyRoute.CHILD_JOURNEY_BEGIN_PAGE),
    component: ChildJourneyBeginPage
  },
  {
    path: createRoute(JourneyRoute.PUBLIC_PROFILE),
    component: PublicProfileContainer
  },
  {
    path: createRoute(JourneyRoute.GETYOURPARENTS),
    component: GetYourParentsContainer
  },
  {
    path: createRoute(JourneyRoute.CHILD_RECONSENT_SUCCESS),
    component: ChildSuccessContainer
  },
  {
    path: createRoute(JourneyRoute.RECONSENT_REQUEST),
    component: RequestReconsentContainer
  },
  {
    path: createRoute(JourneyRoute.PARENT_JOURNEY_BEGIN_PAGE),
    component: ParentJourneyBeginContainer
  },
  {
    path: createRoute(JourneyRoute.CALLBACK_CONSENT),
    component: CallbackConsent
  },
  {
    path: createRoute(JourneyRoute.SIGN_OUT),
    component: CallbackSignout
  },
  {
    path: createRoute(JourneyRoute.CANCEL_CONSENT),
    component: CancelConsent
  },
  {
    path: createRoute(JourneyRoute.START_CONSENT),
    component: StartConsent
  },
  {
    path: createRoute(JourneyRoute.LINK_CONSENT),
    component: LinkConsent
  },
  {
    path: createRoute(JourneyRoute.CONSENT),
    component: ConsentPage
  },
  {
    path: createRoute(JourneyRoute.PARENT_EMAIL_SENT),
    component: ChildSuccessContainer
  },
  {
    path: createRoute(JourneyRoute.OPTIONS_VERIFY),
    component: VerifyOptionsPage
  },
  {
    path: createRoute(JourneyRoute.VERIFY_PROCESSING),
    component: VerifyProcessingPage
  },
  {
    path: createRoute(JourneyRoute.PARENT_SUCCESS),
    component: FinishedContainer
  }
];
