import { ErrorBehaviorType } from "../actions/errorActions";
import { GivenConsentState } from "./consentTypes";
import { AuthFlowType } from "scripts/actions/authActions";
import { LocationDescriptorObject, LocationState } from "history";
import { RouterState } from "connected-react-router";
import { DialogRole } from '@lego/lego-account-ui';

export enum FlowType {
  SameDevice = 0,
  Email = 1
}

export enum VerificationMethod {
  CreditCard = "CreditCard",
  IdCard = "IdCard",
  FaceDetection = "FaceDetection",
  IdCardCreditCardFaceDetection = "IdCardCreditCardFaceDetection"
}

export enum VerificationStatus {
  Pending = "Pending",
  Success = "Success",
  Failure = "Failure",
  Error = "Error"
}

export enum VerificationError {
  None = "None",
  InternalServerError = "InternalServerError",
  ImageProcessingError = "ImageProcessingError",
  SessionExpired = "SessionExpired",
  ImageUploadFailed = "ImageUploadFailed",
  ClientError = "ClientError",
  ClientUploadDisabled = "ClientUploadDisabled",
  ClientDoesNotSupportCamera = "ClientDoesNotSupportCamera",
  NotReadableId = "NotReadableId",
  FacialMatchNotPossible = "FacialMatchNotPossible",
  FacialNotAMatch = "FacialNotAMatch",
  UnsupportedIDType = "UnsupportedIDType",
  UnsupportedIDCountry = "UnsupportedIDCountry",
  NoIdUploaded = "NoIdUploaded",
  Fraud = "Fraud",
  TooManyFailedAttempts = "TooManyFailedAttempts"
}

declare global {
  interface RootState {
    readonly auth: AuthState;
    readonly context: ContextState;
    readonly vpc: VpcState;
    readonly consent: ConsentState;
    readonly router: RouterState<LocationState>;
    readonly verify: VerifyState;
    readonly journey: JourneyState;
    readonly publicProfile: PublicProfileState;
    readonly modal: ModalState;
  }

  // ***** verify options
  interface VerifyState {
    readonly options: VerificationMethod[];
  }

  interface MembersTosState {
    id: string;
    accepted: boolean;
    showSavedMessage: boolean;
  }

  // ***** consent
  interface ConsentState {
    readonly consents: Consent[]; //TODO: Is probably not used anymore
    readonly clientConsents: VpcConsentEntryState[];
  }

  interface JourneyState {
    readonly isConsentable: boolean;
  }

  interface PublicProfileState {
    readonly nicknames: string[];
    readonly nicknameLanguage: string;
    readonly isNicknameEditable: boolean;
    readonly outfits: AvatarOutfitEntry[];
    readonly initialOutfitId?: string;
  }

  interface AvatarOutfitEntry {
    readonly id: string;
    readonly imageUrl: string;
    readonly minifigureImageUrl: string;
    readonly altText: string;
  }

  interface Consent {
    readonly id: string;
    readonly name: string;
    readonly title: string;
    readonly description: string;
    readonly longDescription: string;
    readonly locale: string;
    readonly iconUrl: string;
    readonly examples?: ConsentExample[];
  }
  interface ConsentExample {
    readonly imageUrl: string;
    readonly caption: string;
  }

  // ***** vpc
  interface VpcState {
    readonly VPCToken: string;
    readonly IsLoaded: boolean;
    readonly VPCParentValidated: boolean;
  }

  interface VpcConsentEntryState {
    readonly consentOptionUri: string;
    readonly state: GivenConsentState;
  }
  // ***** modal
  interface ModalValue {
    readonly loadModal: boolean;
    readonly id: string;
  }

  // ***** error
  interface ErrorState {
    readonly type?: string;
    readonly heading?: string;
    readonly details?: string;
    readonly image?: string;
    readonly errorBehavior?: ErrorBehavior;
  }

  type ErrorBehavior =
    | ErrorBehaviorRetry
    | ErrorBehaviorReturnToClient
    | ErrorBehaviorGoBack
    | ErrorBehaviorGotoPage
    | ErrorBehaviorReAuth
    | ErrorBehaviorDismissError;
  interface ErrorBehaviorBase {
    readonly buttonText?: string;
  }
  interface ErrorBehaviorRetry extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_RETRY;
  }
  interface ErrorBehaviorReturnToClient extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_RETURN_TO_CLIENT;
    readonly reason: string;
  }
  interface ErrorBehaviorGoBack extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_GO_BACK;
  }
  interface ErrorBehaviorGotoPage extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_GOTO_PAGE;
    readonly gotourl: string;
  }
  interface ErrorBehaviorReAuth extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_REAUTH;
    readonly returnurl: string;
    readonly flow: AuthFlowType;
  }
  interface ErrorBehaviorDismissError extends ErrorBehaviorBase {
    readonly action: ErrorBehaviorType.ERROR_BEHAVIOR_DISMISS_ERROR;
  }

  // ***** auth
  interface AuthState {
    readonly user?: UserState;
    readonly unauthorized?: boolean;
    readonly completed: boolean;
    readonly isadult?: boolean;
    readonly email_verified?: boolean;
    readonly country?: string;
  }
  interface UserProfile {
    readonly isadult: boolean;
    readonly email_verified: boolean;
    readonly sub: string;
    readonly email?: string;
    readonly nickname: string;
    readonly country?: string;
    readonly is_upgradable?: boolean;
  }
  interface UserState {
    readonly profile: Partial<UserProfile>;
    readonly access_token: string;
    readonly state: OidcState;
  }
  interface OidcState {
    redirect_uri: LocationDescriptorObject;
    context: ContextState;
    vpcToken: string;
  }
  // ***** context
  interface ContextState {
    readonly returnurl: string;
    readonly clientid: string;
    readonly state: string;
    readonly theme: string;
    readonly appContext: boolean;
    readonly hideheader: boolean;
    readonly hideCloseButton: boolean;
    readonly adultexperience: boolean;
    readonly culture: string;
    readonly lang: string;
    readonly serviceEndpoints?: Required<ContextServiceEndpoints>;
    readonly returnUrlWasProvidedByClient: boolean;
    readonly clientIdWasProvidedByClient: boolean;
    readonly selfHost: string;
    readonly isott: boolean;
    readonly vpcClientid: string;
    readonly feature: number;
    readonly legalTexts: LegalTextState;
    readonly journey: JourneyContext;
  }

  interface LegalTextState {
    readonly cookie: string;
    readonly privacy: string;
  }
  type ContextContent = any;
  interface ContextServiceEndpoints {
    readonly rootDomain: string;
    readonly wwwRootDomain: string;
    readonly vpcRootDomain: string;
    readonly identityService: string;
    readonly avatarService: string;
    readonly sandboxRootDomain: string;
    readonly displaynameService: string;
  }

  interface JourneyContext {
    readonly flowType: FlowType;
    readonly verifiedEmailToken: string;
    readonly securityId: string;
  }
  interface ModalState {
    readonly isOpen: boolean;
    readonly title: string | React.ReactNode;
    readonly onClose?: () => Promise<void>;
    readonly onConfirm?: () => Promise<void>;
    readonly testIdentifier?: string;
    readonly body?: React.ReactNode;
    readonly richBody?: boolean;
    readonly visual?: React.ReactNode | string;
    readonly hideCloseIcon?: boolean;
    readonly hideCloseButton?: boolean;
    readonly destructiveConfirm?: boolean;
    readonly closeText?: string;
    readonly confirmText?: string;
    readonly role?: DialogRole
  }
}
