import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
import { useState } from "react";
const style = require("./FormElement.less");

export type DateResult = Date | "invalid" | ""
export interface DateElementProps {
  /**
   * Format: (dd-mm-yyyy|mm-dd-yyyy|yyyy-mm-dd|...). Date pattern to show the input fields in the correct order. Default: dd-mm-yyyy.
   */
  datePattern: string

  placeholderDay: string
  placeholderMonth: string
  placeholderYear: string
  labelDay: string
  labelMonth: string
  labelYear: string

  /**
   * The selected date if any. Or InvalidDate if the date is not valid.
   */
  value?: DateResult

  /**
   * Called when the date changes. Null if no date is set. InvalidDate if a complete but invalid date is entered.
   */
  onChange: (value: DateResult) => void

  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  /**
   * ClassName for each input field in the set
   */
  className?: string

  /**
   * Disabled to be applied to each input field in the set
   */
  disabled?: boolean

  minYear?: number
  maxYear?: number
}

const DateElement = (props: DateElementProps & FormElementProps) => {
  var {
    label,
    error,
    labelDay,
    labelMonth,
    labelYear,
    placeholderDay,
    placeholderMonth,
    placeholderYear,
    datePattern = "dd-mm-yyyy",
    onChange,
    onBlur,
    minYear = new Date().getFullYear() - 125,
    maxYear = new Date().getFullYear(),
    ...inputProps } = props;

  const date = props.value !== "invalid" ? props.value : undefined

  const [day, setDay] = useState<number | "">(date ? date.getDate() : "");
  const [month, setMonth] = useState<number | "">(date ? date.getMonth() + 1 : "");
  const [year, setYear] = useState<number | "">(date ? date.getFullYear() : "");

  const propagateDate = (y: number | "", m: number | "", d: number | "", e: React.ChangeEvent<HTMLInputElement>) => {
    if (y === "" || m === "" || d === "") {
      onChange("");
      return;
    }

    // check that day is valid in this month
    const daysInMonth = new Date(y, m, 0).getDate();
    if (d <= 0 || d > daysInMonth) {
      onChange("invalid");
      return;
    }

    if (y < minYear || y > maxYear) {
      onChange("invalid");
      return;
    }

    if (new Date(y, m - 1, d) > new Date()) {
      onChange("invalid");
      return;
    }

    const date = new Date(y, m - 1, d);
    // Make sure the day is not changed (that the days in the month were too high)
    if (date.getDate() === d) {
      onChange(date);
    } else {
      onChange("invalid");
    }

  }

  const onChangeInternal = (e: React.ChangeEvent<HTMLInputElement>) => {

    try {
      const value = (e.target.value === "" || e.target.value === "-" || e.target.value === ".") ? "" : parseInt(e.target.value);

      var daysInMonth = 31;
      if (year !== "" && month !== "") {
        daysInMonth = new Date(year, month, 0).getDate();
      }

      if (e.target.id === "month" && (value === "" || (value <= 12 && value > 0))) {
        setMonth(value);
        propagateDate(year, value, day, e);
      } else if (e.target.id === "day" && (value === "" || (value <= daysInMonth && value > 0))) {
        setDay(value);
        propagateDate(year, month, value, e);
      } else if (e.target.id === "year" && (value === "" || (value <= 9999 && value > 0))) {
        setYear(value);
        propagateDate(value, month, day, e);
      }

    } catch {
      onChange("invalid");
    }

  }
  const classnames = classNames(
    {
      [style.Input]: true,
      [style.InputContainer]: true,
      [style.InputError]: error && error !== "" && error.length !== 0
    },
    props.className);

  const renderElement = () => {
    const datePatternParsed = datePattern.toLowerCase().replace(/\/|-/g, "").replace(/(.)(?=.*\1)/g, "").replace(" ", "").replace(".", "");

    const monthElement = (
      <div className={style.DateContainerPart}>
        <label htmlFor="month" className={style.Label}>{labelMonth}</label>
        <div className={style.FieldContainer}>
          <input
            {...inputProps}
            type="number"
            id="month"
            autoComplete="bday-month"
            maxLength={2}
            className={classnames}
            placeholder={placeholderMonth}
            value={month}
            onChange={onChangeInternal}
          />
        </div>
      </div>);
    const dayElement = (
      <div className={style.DateContainerPart}>
        <label htmlFor="day" className={style.Label}>{labelDay}</label>
        <div className={style.FieldContainer}>
          <input
            {...inputProps}
            type="number"
            id="day"
            autoComplete="bday-day"
            maxLength={2}
            className={classnames}
            placeholder={placeholderDay}
            value={day}
            onChange={onChangeInternal}
          />
        </div>
      </div>);
    const yearElement = (
      <div className={style.DateContainerPart}>
        <label htmlFor="year" className={style.Label}>{labelYear}</label>
        <div className={style.FieldContainer}>
          <input
            {...inputProps}
            type="number"
            id="year"
            autoComplete="bday-year"
            maxLength={4}
            className={classnames}
            placeholder={placeholderYear}
            value={year}
            onChange={onChangeInternal}
          />
        </div>
      </div>);
    const dateArray: JSX.Element[] = [];
    dateArray[datePatternParsed.indexOf("d")] = dayElement;
    dateArray[datePatternParsed.indexOf("m")] = monthElement;
    dateArray[datePatternParsed.indexOf("y")] = yearElement;

    const blurIndex = dateArray.length - 1;
    dateArray[blurIndex] = React.cloneElement(dateArray[blurIndex], {
      onBlur: (e: React.FocusEvent<HTMLElement>) => {
        if (onBlur) {
          onBlur(e);
        }
      }
    });

    return (
      <div>
        {dateArray[0]}
        <div className={style.DateContainerDivider} />
        {dateArray[1]}
        <div className={style.DateContainerDivider} />
        {dateArray[2]}
      </div>
    );
  }

  return (
    <FormElement
      label={label}
      error={error}
      customClass={style.DateElement}
      {...inputProps}
      onBlur={undefined}
      element={renderElement()} />
  )

}

export { DateElement };