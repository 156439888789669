import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import rootReducer from "../reducers/rootReducer";
import { persistStore } from "redux-persist";
import { History } from "history";

// Handle the type for redux dev tools
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

// Configuring the Store. PreloadState is the initial State.
export function configureStore(
  preloadedState: Partial<RootState>,
  history: History
) {
  const rMiddleware = routerMiddleware(history);

  const composeEnhancers =
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose;
  const enhancers = composeEnhancers(
    applyMiddleware(thunkMiddleware, rMiddleware)
  );

  const store = createStore(rootReducer(history), preloadedState, enhancers);

  const persistor = persistStore(store);

  return {
    store,
    persistor
  };
}
