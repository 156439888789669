import i18n, { StringMap, TOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import { TypedTFunction } from "i18next-typescript";
import { TranslationKeys } from "../types/i18n";

export type TFunction = TypedTFunction<TranslationKeys>;

i18n.use(Backend).use(initReactI18next);

export const initializeI18N = (language: string) => {
  i18n.init({
    lng: language,
    fallbackLng: "en-US",
    debug: true,
    ns: ["Generic", "Errors"],
    defaultNS: "Generic",
    load: "currentOnly",
    backend: {
      loadPath: `/i18n/{{lng}}/{{ns}}.json`
    },
    lowerCaseLng: true,
    returnObjects: true
  });
};

export const t = (
  key: TranslationKeys,
  options?: string | TOptions<StringMap>
): string => {
  return i18n.t(key, options);
};

export default i18n;
