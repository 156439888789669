import React from "react";
import cx from "classnames";

const style = require("./AnchorButton.less");

export type AnchorButtonIcons = 
    "security" | 
    "information" | 
    "logout" | 
    "link"

interface AnchorButtonProps {
    text: string,
    onClick: Function,
    icon?: AnchorButtonIcons
}
const AnchorButton: React.FC<AnchorButtonProps> = (props)  => {
    const iconStyle = props.icon ? {[style.icon]: props.icon, [style[props.icon]]: props.icon} : {};
    return (
        <button {...props} className={cx([style.anchorButton, iconStyle])} onClick={() => props.onClick()}>{props.text}</button>
    );
};

export { AnchorButton };
