import { TypedTFunction } from "i18next-typescript";
import { TranslationKeys } from "scripts/types/i18n";

export interface ModalTexts {
  title: string;
  text: string;
  closeButtonAriaLabel: string;
  testIdentifier: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

export const abortFlow = (t: TypedTFunction<TranslationKeys>): ModalTexts => {
  return {
    text: t("AbortFlow:abort_vp_cflow_modal_text"),
    title: t("AbortFlow:abort_vp_cflow_modal_header"),
    testIdentifier: "AbortFlow",
    cancelButtonText: t("AbortFlow:abort_vp_cflow_modal_cancel_button"),
    confirmButtonText: t("AbortFlow:abort_vp_cflow_modal_continue_button"),
    closeButtonAriaLabel: t("AbortFlow:abort_vp_cflow_modal_continue_button")
  };
};
