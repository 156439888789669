import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
const style = require("./CookieConsent.less");

export interface CookieConsentProps {
    cookieBody: string,
    buttonText: string,
    culture: string,
    cookieName: string,
    expires: number
}

const CookieConsent: React.FunctionComponent<CookieConsentProps> = props => {
    let [visible, setVisible] = useState(false);
    const { buttonText, culture, cookieBody, cookieName, expires } = props;
    /**
    * Set a persistent cookie
   */
    const accept = () => {
        Cookies.set(
            cookieName,
            "true",
            {
                expires: expires,
                path: "/"
            });
        setVisible(false);
    }

    useEffect(() => {
        if (Cookies.get(cookieName) === undefined) {
            setVisible(true);
        }
    }, []);

    // If the bar shouldn't be visible don't render anything.
    if (!visible) {
        return null;
    }

    return (
        <div className={style.CookieConsent}>
            <div className={style.CookieMessage}>
                {cookieBody}
            </div>
            <button
                id="cookieAcceptConsentBtn"
                data-testid="cookieAcceptConsentBtn"
                onClick={() => {
                    accept();
                }}
            >
                {buttonText}
            </button>
        </div>
    );
}
export default React.memo(CookieConsent);