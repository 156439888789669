import { Button } from "@lego/lego-account-ui";
import {
  Section,
  Header,
  Loader,
  Img,
} from "@library";
import { BackButtonParameters, CloseButtonParameters, PageContainer } from "scripts/components";

const assets = require.context("./assets/", false);

type I18N = {
  readonly header: string;
  readonly text?: string;
  readonly continueLabel: string;
  readonly loadingText: string;
};

type Props = {
  i18n: I18N;
  onContinue?: () => void;
  backButtonParameters?: BackButtonParameters;
  closeButtonParameters?: CloseButtonParameters;
  isLoading?: boolean;
  hideCloseButton?: boolean;
  legalComponent?: JSX.Element;
  isContinueLoading?: boolean;
};

export const RequestReconsent = ({
  i18n,
  onContinue = () => { },
  backButtonParameters,
  closeButtonParameters,
  isLoading = false,
  hideCloseButton = false,
  legalComponent = <div></div>,
  isContinueLoading = false
}: Props) => {
  const renderContent = () => (
    <>
      <Header
        title={i18n.header}
        introText={i18n.text}
        headerTestId="requestReconsentHeader"
      />

      <Section>
        <Img
          srcImg={[
            assets("./enterEmail@1x.png"),
            assets("./enterEmail@2x.png"),
            assets("./enterEmail@3x.png"),
            assets("./enterEmail@4x.png")
          ]}
          alt="girl with laptop on a lego brick"
        />
        <Button
          isLoading={isContinueLoading}
          loadingLabel={i18n.loadingText}
          onPress={onContinue}
          data-testid="requestReconsentContinue"
        >
          {i18n.continueLabel}
        </Button>
      </Section>
    </>
  );

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        closeButtonParameters={{
          ...closeButtonParameters,
          display: hideCloseButton !== true
        }}
        legalComponent={legalComponent}
      >
        {isLoading ? <Loader ariaLabel={i18n.loadingText} /> : renderContent()}
      </PageContainer>
    </>
  );
};
