import React from "react";
import { Loader, Section, Button } from "@library";
import { useTranslation } from "scripts/hooks/useTranslation";

namespace SaveSection {
  export type DisplayState = "default" | "loading" | "disabled" | "saved";

  export type Props = {
    label: string;
    onSave: (event: React.MouseEvent<HTMLButtonElement>) => void;
    displayState?: DisplayState;
  };
}

const SaveSection = ({
  label,
  onSave,
  displayState = "disabled"
}: SaveSection.Props) => {
  const { t } = useTranslation(["DashboardPage"]);
  return (
    <Section margin="small">
      {displayState === "loading" ? (
        <Loader ariaLabel={t("loading_text")} />
      ) : (
        <>
          {displayState !== "saved" ? (
            <>
              <Button
                disabled={displayState === "disabled"}
                  data-testid="btnSaveProfile"
                onClick={onSave}
              >
                {label}
              </Button>
            </>
          ) : (
            <div className="profile--save-msg">
              {t("DashboardPage:saved_message")}
            </div>
          )}
        </>
      )}
    </Section>
  );
};

export { SaveSection };
