import React, { useState, useEffect } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions, GetYourParentsActions } from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { GetYourParents } from "scripts/components/presentation/GetYourParents";
import { useTranslation } from "scripts/hooks/useTranslation";

type StateProps = {
  context: ContextState;
};
type ActionProps = {
  getYourParentsActions: ThunkActionsDispatch<GetYourParentsActions>;
  authactions: ThunkActionsDispatch<AuthActions>;
};
type Props = StateProps & ActionProps;

const GetYourParentsContainer = (props: Props) => {
  const { t } = useTranslation(["Generic", "GetYourParentsPage"]);

  const { context, getYourParentsActions, authactions } = props;
  const { hideCloseButton } = context;

  const [loading, setLoading] = useState<boolean>(true);

  usePageTitle(t("GetYourParentsPage:header"));
  useTrackPage(PAGE_TYPE.GET_YOUR_PARENTS);

  const next = async () => {
    getYourParentsActions.CleanSessionState();
    if (!context.isott) await authactions.DoSilentLogin();
    getYourParentsActions.continueToConsent();
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    (async () => {
      const isValidClient = await authactions.ValidateClient();
      if (isValidClient) {
        const isLoggedIn = await getYourParentsActions.EnsureLoggedInUser();
        if (isLoggedIn) {
          const user = await authactions.GetCurrentUser();
          if (user?.profile?.email_verified === false) {
            next();
          } else {
            setLoading(false);
          }
        }
      }
    })();
  }, []);

  const handleCloseButtonOnClick = () =>
    authactions.ReturnToClientWithReasonCancel(true);

  const closeButtonParameters = {
    onClick: handleCloseButtonOnClick,
    closeButtonLabel: t("close_button")
  };

  const handleNextClick = () => next();

  return (
    <GetYourParents
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      isLoading={loading}
      legalComponent={<Legals context={context} />}
      onContinue={handleNextClick}
      i18n={{
        buttonLabel: t("GetYourParentsPage:iam_the_parent_button"),
        header: t("GetYourParentsPage:header"),
        text: t("GetYourParentsPage:text"),
        loadingText: t("loading_text")
      }}
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    context: state.context
  };
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  return {
    getYourParentsActions: bindActionCreators(GetYourParentsActions, dispatch),
    authactions: bindActionCreators(AuthActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetYourParentsContainer);
