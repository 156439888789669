import axios, { AxiosRequestConfig, ResponseType } from "axios";
import { mapBackendToFrontendError } from "./httpErrorHandler";
import { ErrorTypes } from "../actions/errorActions";
const instance = axios.create();

export const responseOkay = (status: number) => status >= 200 && status <= 299;

async function makeRequest<T>(request: AxiosRequestConfig): Promise<T> {
  try {
    const res = await instance.request<T>(request);

    if (
      res &&
      res.data &&
      ["fail", "error"].includes((res.data as any).status)
    ) {
      const errorMessage = mapBackendToFrontendError(res);

      throw { message: errorMessage };
    }

    return res.data;
  } catch (error) {
    var status = error && error.status;
    if (!status) {
      status = error && error.response && error.response.status;
    }

    if (status) {
      if (status >= 500) throw ErrorTypes.SERVER_ERROR;
      if (status === 401) throw ErrorTypes.LOGIN_REQUIRED;
      if (status >= 400) throw ErrorTypes.BAD_REQUEST;
    }
    if (error && error.message) throw error;
    throw error || ErrorTypes.GENERIC_ERROR;
  }
}

/**
 * @deprecated The method should not be used. Use requestAsync instead
 */
export async function httpGet<T>(
  url: string,
  headers: any,
  responseType: ResponseType = "json"
): Promise<T> {
  return makeRequest<T>({
    method: "get",
    url,
    responseType,
    headers
  });
}

/**
 * @deprecated The method should not be used. Use requestAsync instead
 */
export const httpPost = async (
  url: string,
  data: any,
  headers: any,
  responseType: ResponseType = "json"
) => {
  return makeRequest({
    method: "post",
    url,
    responseType,
    data,
    headers
  });
};

/**
 * @deprecated The method should not be used. Use requestAsync instead
 */
export const httpPut = async (
  url: string,
  data: any,
  headers: any,
  responseType: ResponseType = "json"
) => {
  return makeRequest({
    method: "put",
    url,
    responseType,
    data,
    headers
  });
};
