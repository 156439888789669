import {
  AuthActions,
  ErrorActions,
  ErrorTypes,
  RouteActions,
  NavigationRoute
} from "scripts/actions/";
import {
  VerificationError,
  VerificationMethod,
  VerificationStatus
} from "scripts/types/storeTypes";
import { VPCApiClient } from "scripts/services";
import { stall } from "scripts/utils";
import StorageFactory from "scripts/utils/StorageFactory";
import { VpcActions } from "./vpcActions";
import {
  compileCallbackState,
  parseCallbackState,
  VerificationCallbackState
} from "scripts/utils/callbackHelper";

export enum VerifyActionTypes {
  VERIFY_OPTIONS_LOADED = "[VERIFY] OPTIONS_LOADED",
  ALLOWED_VERIFICATION_METHOD = "[VERIFY] ALLOWED_VERIFICATION_METHOD",
  VERIFY_SESSION_RESUMED = "[VERIFY] VERIFY_SESSION_RESUMED"
}

const storage = StorageFactory.getStorage(window.localStorage);
export namespace VerifyActions {
  export function VerifyMethodOptionLoaded(
    options: VerificationMethod[]
  ): VerifyLoadOptionsActions {
    return {
      type: VerifyActionTypes.VERIFY_OPTIONS_LOADED,
      options: options
    };
  }

  export const isParentCountrySupported =
    (): ThunkFunction<Promise<boolean>> => async (dispatch, getState) => {
      try {
        const {
          context: {
            serviceEndpoints: { vpcRootDomain }
          }
        } = getState();
        let user = await dispatch(AuthActions.GetCurrentUser());
        if (!user) {
          user = await dispatch(AuthActions.DoSilentLogin());
        }
        if (user?.profile === null) {
          dispatch(
            ErrorActions.ErrorAction({ error: ErrorTypes.LOGIN_REQUIRED })
          );
          return;
        }
        if (!user.profile.isadult) {
          dispatch(
            ErrorActions.ErrorAction({
              error: ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT
            })
          );
          return;
        }
        const response = await VPCApiClient.getVerificationMethods(
          vpcRootDomain
        );

        if (response.ok && response.payload.methods.length > 0) {
          dispatch(
            VerifyActions.VerifyMethodOptionLoaded(response.payload.methods)
          );
          return true;
        } else if (response.ok && response.payload.methods.length <= 0) {
          dispatch(
            ErrorActions.ErrorAction({ error: ErrorTypes.VPC_NO_ACCESS })
          );
          return false;
        } else {
          dispatch(ErrorActions.ErrorResponse(response));
          return false;
        }
      } catch (error) {
        console.error("isParentCountrySupported", JSON.stringify(error));
        dispatch(ErrorActions.ErrorAction({ error }));
        return false;
      }
    };

  export const getVerificationMethodAllowed =
    (): ThunkAction => async (dispatch, getState) => {
      try {
        const {
          context: {
            serviceEndpoints: { vpcRootDomain }
          }
        } = getState();
        const response = await VPCApiClient.getVerificationMethods(
          vpcRootDomain
        );

        if (response.ok) {
          if (response.payload.methods.length > 0) {
            dispatch(VerifyMethodOptionLoaded(response.payload.methods));
            if (
              response.payload.methods.includes(
                VerificationMethod.IdCardCreditCardFaceDetection
              )
            ) {
              dispatch(
                redirectToNextStep(
                  VerificationMethod.IdCardCreditCardFaceDetection
                )
              );
            }
          } else {
            dispatch(
              ErrorActions.ErrorAction({ error: ErrorTypes.VPC_NO_ACCESS })
            );
          }
        }
      } catch (error) {
        console.error("getVerificationMethodAllowed", JSON.stringify(error));
        dispatch(ErrorActions.ErrorAction({ error }));
        throw error;
      }
    };

  export const redirectToNextStep =
    (value: VerificationMethod): ThunkAction =>
    async (dispatch) => {
      try {
        dispatch(VerifyActions.startVerification(value));
      } catch (error) {
        dispatch(ErrorActions.ErrorAction({ error }));
      }
    };

  export const startVerification =
    (method: VerificationMethod): ThunkActionAsync =>
    async (dispatch, getState) => {
      const {
        context: {
          culture,
          serviceEndpoints: { vpcRootDomain }
        },
        vpc: { VPCToken: vpcToken }
      } = getState();

      try {
        const response = await VPCApiClient.getVerificationSession(
          method,
          vpcRootDomain,
          culture
        );

        if (response.ok) {
          const { sessionId, redirectUrl } = response.payload;
          storage.setItem(
            `verification.${sessionId}`,
            // Add VPC token to verification state, to resume on callback
            compileCallbackState<VerificationCallbackState>({
              vpcToken
            })
          );
          if (redirectUrl) window.location.href = redirectUrl;
          return;
        } else if (response.badRequest) {
          //TODO: Better error handling
        }

        dispatch(ErrorActions.ErrorResponse(response));
      } catch (error) {
        console.error(`startVerification: ${method}`, JSON.stringify(error));
        dispatch(ErrorActions.ErrorAction({ error }));
      }
    };

  export const startProcessing =
    (method: VerificationMethod, sessionId: string): ThunkActionAsync =>
    async (dispatch, getState) => {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain }
        }
      } = getState();

      try {
        while (true) {
          const statusResponse =
            await VPCApiClient.getPendingVerificationStatus(
              sessionId,
              vpcRootDomain
            );

          switch (statusResponse.payload.status) {
            case VerificationStatus.Success:
              return dispatch(RouteActions.goto(NavigationRoute.SUCCESS));
            case VerificationStatus.Failure:
              return dispatch(
                ErrorActions.ErrorAction({
                  error: ErrorTypes.VERIFICATION_FAILURE
                })
              );
            case VerificationStatus.Error:
              return dispatch(
                handleVerificationError(statusResponse.payload.errorCode)
              );
            case VerificationStatus.Pending:
            default:
              await stall(10000);
          }
        }
      } catch (error) {
        dispatch(ErrorActions.ErrorAction({ error }));
      }
    };

  export const handleVerificationError =
    (error?: VerificationError): ThunkAction =>
    (dispatch) => {
      dispatch(
        ErrorActions.ErrorAction({
          error: mapVerificationError(error)
        })
      );
    };

  const mapVerificationError = (errorCode?: VerificationError): ErrorTypes => {
    switch (errorCode) {
      case VerificationError.ClientDoesNotSupportCamera:
        return ErrorTypes.BROWSER_DOES_NOT_SUPPORT_CAMERA;
      case VerificationError.ClientError:
        return ErrorTypes.UNEXPECTED_ERROR_OCCURRED_IN_THE_CLIENT;
      case VerificationError.ClientUploadDisabled:
        return ErrorTypes.FILE_UPLOAD_NOT_ENABLED_AND_CAMERA_UNAVAILABLE;
      case VerificationError.ImageProcessingError:
        return ErrorTypes.THE_3D_LIVENESS_FACE_CAPTURE_PROCESS_FAILED_ISSUE_WITH_IPROOV;
      case VerificationError.ImageUploadFailed:
        return ErrorTypes.FILE_UPLOAD_NOT_ENABLED_AND_CAMERA_UNAVAILABLE;
      case VerificationError.InternalServerError:
        return ErrorTypes.ERROR_OCCURRED_ON_OUR_SERVER;
      case VerificationError.NotReadableId:
        return ErrorTypes.VERIFICATION_NOT_READABLE_ID;
      case VerificationError.FacialMatchNotPossible:
        return ErrorTypes.VERIFICATION_FACIAL_MATCH_NOT_POSSIBLE;
      case VerificationError.FacialNotAMatch:
        return ErrorTypes.VERIFICATION_FACIAL_NOT_A_MATCH;
      case VerificationError.UnsupportedIDType:
        return ErrorTypes.VERIFICATION_UNSUPPORTED_ID_TYPE;
      case VerificationError.UnsupportedIDCountry:
        return ErrorTypes.VERIFICATION_UNSUPPORTED_ID_COUNTRY;
      case VerificationError.NoIdUploaded:
        return ErrorTypes.VERIFICATION_NO_ID_UPLOADED;
      case VerificationError.Fraud:
        return ErrorTypes.VERIFICATION_FRAUD;
      case VerificationError.None:
        return ErrorTypes.VERIFICATION_FAILURE;
      case VerificationError.SessionExpired:
        return ErrorTypes.SESSION_EXPIRED_AFTER_THE_USER_JOURNEY_STARTED;
      case VerificationError.TooManyFailedAttempts:
        return ErrorTypes.VERIFICATION_TOO_MANY_FAILED_ATTEMPTS;
      default:
        console.error("Unmapped errorcode: %s", errorCode);
        return ErrorTypes.GENERIC_ERROR;
    }
  };

  export const handleCallback =
    (
      verificationMethod: string,
      sessionId: string,
      status: string,
      errorCode?: VerificationError
    ): ThunkActionAsync =>
    async (dispatch): Promise<void> => {
      if (status === VerificationStatus.Error) {
        return dispatch(handleVerificationError(errorCode));
      }

      const stateString = storage.getItem(`verification.${sessionId}`);

      if (stateString) {
        const verificationState =
          parseCallbackState<VerificationCallbackState>(stateString);
        if (verificationState?.vpcToken) {
          dispatch(VpcActions.updateVpcToken(verificationState.vpcToken));
          storage.removeItem(`verification.${sessionId}`);
        } else {
          console.error("Invalid state");
          dispatch(ErrorActions.ErrorAction({ error: "Invalid_State" }));
          return;
        }
        switch (status) {
          case VerificationStatus.Success:
            dispatch(RouteActions.goto(NavigationRoute.SUCCESS, null, false));
            break;
          case VerificationStatus.Pending:
            dispatch(
              RouteActions.goto(
                NavigationRoute.VERIFY_PROCESSING,
                new URLSearchParams({
                  verificationMethod,
                  sessionId
                }),
                false
              )
            );
            break;
          case VerificationStatus.Error:
            /* System error */
            dispatch(
              ErrorActions.ErrorAction({ error: ErrorTypes.GENERIC_ERROR })
            );
            break;
          case VerificationStatus.Failure:
            /* Not Adult */
            dispatch(
              ErrorActions.ErrorAction({
                error: ErrorTypes.VERIFICATION_FAILURE
              })
            );
            break;

          default:
            console.error("Unhandled state");
            dispatch(ErrorActions.ErrorAction({ error: "Unhandled_State" }));
            break;
        }
      } else {
        console.error("No state found");
        dispatch(ErrorActions.ErrorAction({ error: "No_State_Found" }));
      }
    };
}

export type VerifyActions = typeof VerifyActions;
