import Cookies, { CookieAttributes } from "js-cookie";

const options:CookieAttributes = {
    secure: true,
    sameSite: 'strict'
};

export default class CookieStorage {
    setItem = (key: string, value: string) => Cookies.set(key, value, options);

    getItem = (key: string) => Cookies.get(key);

    removeItem = (key: string) => Cookies.remove(key, options);

    key = (index: number) => {
        let allKeys = Object.keys(Cookies.getJSON());
        return index > -1 && index <= allKeys.length
            ? allKeys[index]
            : "";
    };

    readonly length: number = Object.keys(Cookies.getJSON()).length;

    clear = () => {
        let allKeys = Object.keys(Cookies.getJSON());
        allKeys.forEach(key => {
            Cookies.remove(key, options);
        });
    }
}