import React from "react";
import classnames from "classnames";
const style = require("./Headline.less");

export type HeadlineAlignment = "center" | "left" | "right";
export type HeadlineVariant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
export interface HeadlineProps {
    align?: HeadlineAlignment
    testId?: string
    component?: HeadlineVariant
    variant?: HeadlineVariant
}

const Headline:React.FC<HeadlineProps> = ({children, align = 'center', testId = undefined, component = 'h1', variant = 'h1'})  => {
  const Component = component as HeadlineVariant;

  return (
    <Component 
      className={classnames(variant ? style[variant] : style["h1"], style[`align-${align}`])}
      data-testid={testId}>
        {children}
    </Component>
  );
};

export { Headline };
