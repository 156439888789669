import { useEffect } from "react";
import { connect } from "react-redux";
import { AuthActions, ErrorActions, Reason } from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";
import { getParameterByName, getParameterFromHash } from "scripts/utils";
import { Loader } from "@library";
import { t } from "scripts/utils/i18n";

export namespace CallbackCustom {
  export type Props = {
    authActions: ThunkActionsDispatch<AuthActions>;
    errorActions: ThunkActionsDispatch<ErrorActions>;
  };
}

const CallbackCustom = (props: CallbackCustom.Props): JSX.Element => {
  const { authActions, errorActions } = props;

  useEffect(() => {
    const error = getParameterByName("error");
    if (error) {
      const reason = getParameterFromHash("reason");
      authActions.ReturnToClient(true, reason as Reason);
      return;
    } else {
      const state = getParameterByName("state");
      if (state) {
        (async () => authActions.customCallback(state))();
      } else {
        errorActions.ErrorAction({ error: "No_State_Found" });
        return;
      }
    }
  }, []);

  return <Loader ariaLabel={t("loading_text")} />;
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authActions: bindActionCreators(AuthActions, dispatch),
    errorActions: bindActionCreators(ErrorActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(CallbackCustom);
