import React from "react";
import classNames from "classnames";

const style = require('./Loader.less');

export type LoaderMargin = 'none' | 'small';

export interface LoaderProps {
  margin?: LoaderMargin
  className?: string
  id?: string
  ariaLabel?: string
}

const Loader = ({ id = "loader", className = "", margin = "none", ariaLabel }: LoaderProps) => (
  <div id={id}
    role="status"
    aria-label={ariaLabel}
    className={classNames(style.Loader, className, margin ? style[`margin-${margin}`] : '')}
  >
    <div className={classNames(style.item, style.item_1, className)}></div>
    <div className={classNames(style.item, style.item_2, className)}></div>
    <div className={classNames(style.item, style.item_3, className)}></div>
  </div>
);

export { Loader };