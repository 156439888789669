import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
const style = require("./FormElement.less");

export interface SelectOption {
  value: string
  label: string
}
export interface SelectElementProps {
  /**
   * The selected element.
   */
  value?: string

  /**
   * The options to be selected
   */
  options: SelectOption[]

  /**
   * Called when the selection changes. The value of the selected option.
   */
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void

  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  /**
   * ClassName for each input field in the set
   */
  className?: string

  /**
   * Disabled to be applied to each input field in the set
   */
  disabled?: boolean
  /**
   * autoComplete value from https://www.w3.org/TR/WCAG21/#input-purposes
   */
  autoComplete?:string
}


const SelectElement = (props: SelectElementProps & FormElementProps) => {
  var {label, error, onChange, value, options, ...inputProps} = props;

  const classnames = classNames(
    { [style.Select]: true,
      [style.Input]: true,
      [style.InputContainer]: true,
      [style.InputError]: error && error !== "" && error.length !== 0
    },
    props.className);


  const renderElement = () => {
    
    return (
      <select
          {...inputProps}
          className={classnames}
          onChange={onChange}
          value={value||""}
        >
          {options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
        </select>
    );
  }

  return (
    <FormElement
      label={label}
      error={error}
      {...inputProps}
      element={renderElement()} />
  )

}

export { SelectElement };