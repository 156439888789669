import React from "react";
import classnames from "classnames";
const style = require("./Paragraph.less");

export type ParagraphVariant = "default" | "critical" | "informational" ;

export interface ParagraphProps {
  variant?: ParagraphVariant
}

const Paragraph:React.FC<ParagraphProps> = ({children, variant = 'default'})  => {

  const classes = [style.paragraph];
  if(variant)
    classes.push(style[`variant-${variant}`]);

  return (
    <p className={classnames(classes)}>
        {children}
    </p>
  );
};

export { Paragraph };
