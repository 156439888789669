import React from "react";
import { GivenConsentState } from "scripts/types/consentTypes";

import {
  ActionAccordion,
  ActionAccordionValue
} from "@library/ActionAccordion";
import consentIconHelper from "scripts/utils/consentIconHelper";
import { useTranslation } from "scripts/hooks/useTranslation";
import {
  LinkedProfilePresentationProps,
  getConsentState,
  mapConsentStateToValue
} from ".";

export type ExperiencePresentationProps = {
  consent: BaseConsent<ExperienceConsentData>;
};

export default ({
  consentState,
  profile,
  consent,
  experience,
  onChange
}: ExperiencePresentationProps & LinkedProfilePresentationProps) => {
  const { t } = useTranslation(["ConsentPage", "ExpandableFormCard"]);
  const handleConsentChange = (id: string, value: string) => {
    if (onChange)
      onChange(
        id,
        value === ActionAccordionValue.Yes
          ? GivenConsentState.Granted
          : GivenConsentState.Denied
      );
    return true;
  };

  const getConsentImage = (
    consent: BaseConsent<ExperienceConsentData>
  ): string => {
    if (experience?.data?.logo) return experience.data.logo;
    return consentIconHelper(consent.data.icon);
  };

  return (<ActionAccordion
      key={`profile-${profile.userId}-consent-experience-${consent.consentOptionUri}`}
      id={`profile-${profile.userId}-consent-experience-${consent.consentOptionUri}`}
      imageSrc={getConsentImage(consent)}
      imageAlt={consent.data.title}
      value={mapConsentStateToValue(getConsentState(consentState, consent))}
      onChange={(e) =>
        handleConsentChange(consent.consentOptionUri, e.target.value)
      }
      className="ControlPanelConsents"
      i18n={{
        labelNo: t("ConsentPage:consent_label_no_text"),
        labelYes: t("ConsentPage:consent_label_yes_text"),
        readLess: t("ExpandableFormCard:read_less_text"),
        readMore: t("ExpandableFormCard:read_more_text"),
        title: consent.data.title
      }}
    >
      {consent.data.description}
    </ActionAccordion>
  );
};
