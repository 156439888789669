import { PublicProfileActionTypes } from "scripts/actions";

const initialState: PublicProfileState = {
  nicknames: [],
  nicknameLanguage: "",
  outfits: [],
  isNicknameEditable: true
};

export const PublicProfileReducer = (state = initialState, action: PublicProfileAction): PublicProfileState => {
  switch (action.type) {
    case PublicProfileActionTypes.INITIALIZED:
      return {
        ...state,
        nicknames: action.nicknames,
        nicknameLanguage: action.nicknameLanguage,
        isNicknameEditable: action.isNicknameEditable,
        outfits: action.outfits,
        initialOutfitId: action.initialOutfitId
      };

    default:
      return state;
  }
};
