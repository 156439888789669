export interface CustomCallbackState {
  returnUrl: string;
  vpcToken: string;
}

export interface VerificationCallbackState {
  vpcToken: string;
}

type CallbackState = CustomCallbackState | VerificationCallbackState;

export const compileCallbackState = <T extends CallbackState>(state: T) =>
  JSON.stringify(state);

export const parseCallbackState = <T extends CallbackState>(state: string): T =>
  JSON.parse(state);
