import CookieStorage from "./CookieStorage";
import storageAvailable from "./storageAvailable";

export default class StorageFactory {
  static getStorage = (type: Storage = window.sessionStorage): Storage =>
    storageAvailable(type) ? type : new CookieStorage();

  static storageAsync = {
    setItem(key: string, value: string): Promise<void> {
      StorageFactory.getStorage().setItem(key, value);
      return Promise.resolve();
    },
    getItem(key: string): Promise<string> {
      return Promise.resolve(StorageFactory.getStorage().getItem(key));
    },
    removeItem(key: string): Promise<void> {
      StorageFactory.getStorage().removeItem(key);
      return Promise.resolve();
    }
  };
}
