import React from "react";
import { contentServices } from "scripts/services";
import "./faq.less";

export interface FaqProps {
  children: React.ReactNode;
  id: string;
  testId?: string;
}
export interface QuestionProps {
  id: string;
  text: string;
  testId?: string;
}
export interface AnswerProps {
  text: string;
  testId?: string;
}

const handleClickFaqItem = (e: any) => {
  e.target.setAttribute(
    "aria-expanded",
    !e.target?.nextElementSibling?.checked
  );
  (e?.target as Element).classList.toggle("active");
  if (e.type === "keypress") e.target?.nextElementSibling.click();
};

const List = ({ children, id }: FaqProps) => (
  <div id={id} className="accordion">
    {children}
  </div>
);

const Question = ({ id, text }: QuestionProps) => (
  <>
    <label
      role="heading"
      aria-level={2}
      htmlFor={id}
      onKeyPress={handleClickFaqItem}
      onClick={handleClickFaqItem}
      aria-expanded={false}
      tabIndex={0}
    >
      {text}
    </label>
    <input type="checkbox" id={id} />
  </>
);

const Answer = ({ text }: AnswerProps) => (
  <div
    className="content"
    dangerouslySetInnerHTML={contentServices.createMarkup(text)}
  />
);

export { List, Question, Answer };
