import React, { useEffect } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { VerifyActions, VpcActions } from "scripts/actions";
import {
  usePageTitle,
  BrickLoader,
} from "@library";
import { Legals, PageContainer } from "scripts/components";
import { useTranslation } from "scripts/hooks";
import { getParameterByName } from "scripts/utils";
import { VerificationMethod } from "scripts/types/storeTypes";
import { Card, Headline, Paragraph, HorizonAnchor } from "@lego/lego-account-ui";

type ActionProps = {
  verifyActions: ThunkActionsDispatch<VerifyActions>;
  vpcActions: ThunkActionsDispatch<VpcActions>;
};
type StateProps = {
  context: ContextState;
  verify: VerifyState;
  vpc: VpcState;
};
type Props = ActionProps & StateProps;

const VerifyProcessingPage = (props: Props) => {
  const { t } = useTranslation(["VerifyPage", "VerifyProcessingPage"]);

  const { context, verifyActions, vpcActions } = props;

  usePageTitle(t("VerifyPage:header"));

  useEffect(() => {
    window.scrollTo(0, 0);
    vpcActions.ensureVpcToken();
    const sessionId = getParameterByName("sessionId");
    const verificationMethod = getParameterByName(
      "verificationMethod"
    ) as VerificationMethod;
    verifyActions.startProcessing(verificationMethod, sessionId);
  }, []);

  return (
    <>
      <PageContainer>
        <Headline variant="h1">{t("VerifyProcessingPage:header")}</Headline>
        <HorizonAnchor horizonType="circle">
          <Card>
            <BrickLoader />
            <Headline variant="h2">
              {t("VerifyProcessingPage:fun_facts_header")}
            </Headline>
            <Paragraph>{t("VerifyProcessingPage:fun_facts_text")}</Paragraph>
          </Card>
        </HorizonAnchor>
      </PageContainer>
      <Legals context={context} />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    context: state.context,
    verify: state.verify,
    vpc: state.vpc
  }),
  (dispatch: Dispatch) => ({
    vpcActions: bindActionCreators(VpcActions, dispatch),
    verifyActions: bindActionCreators(VerifyActions, dispatch)
  })
)(VerifyProcessingPage);
