import { requestAsync } from "./restClient";
import { getAccessTokenAsync } from "./userManager";

interface AvatarOutfit {
  readonly Id: string;
  readonly Images: {
    readonly avatarbuilderoutfit: string;
    readonly bigheadoutfit: string;
  };
  readonly OutfitDraft: {
    readonly Name: string;
  };
}

export const createAvatarOutfitEntry = (
  outfit: AvatarOutfit
): AvatarOutfitEntry => ({
  id: outfit.Id,
  imageUrl: outfit.Images.bigheadoutfit,
  minifigureImageUrl: outfit.Images.avatarbuilderoutfit,
  altText: outfit.OutfitDraft.Name
});

export const getPublicOutfitsAsync = async (
  endpoint: string,
  fallbackOutfits: AvatarOutfitEntry[]
): Promise<AvatarOutfitEntry[]> => {
  try {
    const requestOptions: RequestInit = {
      method: "GET",
      headers: {
        Accept: "application/json"
      }
    };

    const uri = new URL("/api/v4/outfits/public", endpoint);
    const response = await fetch(uri.href, requestOptions);
    const outfits: AvatarOutfit[] = (await response.json()).Outfits;

    return outfits.map(createAvatarOutfitEntry);
  } catch (error) {
    console.error(error);
    return fallbackOutfits;
  }
};

export const getFallbackOutfits = (
  imageUrl: string,
  minifigureImageUrl: string
): AvatarOutfitEntry[] => [
  {
    id: "00000000-0000-0000-0000-000000000000",
    imageUrl,
    minifigureImageUrl,
    altText: ""
  }
];

export const getActiveOutfitAsync = async (endpoint: string) => {
  const accessToken = await getAccessTokenAsync();
  return await requestAsync<AvatarOutfit>({
    endpoint,
    method: "GET",
    path: "/api/v4/outfits/active",
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    mode: "cors",
    credentials: "include"
  });
};

export const setActiveOutfitAsync = async (
  endpoint: string,
  outfitId: string
) => {
  const accessToken = await getAccessTokenAsync();
  return await requestAsync<void>({
    endpoint,
    method: "POST",
    path: "/api/v4/outfits/setactive",
    headers: {
      Authorization: `Bearer ${accessToken}`
    },
    data: {
      outfitId
    },
    mode: "cors",
    credentials: "include"
  });
};
