import { AuthActionTypes } from "../actions/authActions";

export function AuthReducer(
  state: AuthState = {
    user: null,
    unauthorized: null,
    completed: false,
    isadult: null,
    email_verified: null,
    country: null
  },
  action: AuthAction
): AuthState {
  switch (action.type) {
    case AuthActionTypes.AUTH_COMPLETED: {
      return {
        ...state,
        completed: true,
        user: action.user,
        unauthorized: false,
        isadult: action.isadult,
        email_verified: action.email_verified,
        country: action.country
      };
    }
    case AuthActionTypes.AUTH_FAILED: {
      return {
        ...state,
        completed: true,
        user: null,
        unauthorized: true,
        isadult: null,
        email_verified: null,
        country: null
      };
    }
    default:
      return state;
  }
}
