import { ErrorTypes } from "../actions/errorActions";

const ErrorTypesMap = {
  VpcTokenMissing: 1001, // The VPCToken is missing from request.
  VpcTokenExpired: 1002, // The VPCToken has expired
  ParentIdMissing: 1003, // The VPCToken does not contain a parent id
  ParentAccountMissing: 1004, // The LegoProfile for the parent could not fetched from identity server
  CannotLinkAccounts: 1005, // The two accounts cannot be linked
  ParentNotVerified: 1006, // The parent has not been verified
  ClientCouldNotBeValidated: 1007, // The client id or return url could not be validated when calling app started flow
  ServerError: 1008, // There was a server error
  NotAnAdult: 1009, // The consenting account is not 18+
  ChildLinkedToOther: 1010, // The child has already been linked to other adult account as parent
  InvalidLocale: 1011, // The locale submitted with the request was either missing or had the incorrect format
  WrongLegoId: 1012, // The LegoId was not found in identity server.
  VPCAccountNotFound: 1013, // The permission user was not found in vpc.
  MissingRelationBetweenUsers: 1014, // The given users don't have a relation.
  NotAChild: 1015, // The account is not below 16 years of age.
  VerificationResultNull: 1016, // The verification Result is NULL.
  NotULLA: 1017, // The account is not an ALLA.

  // Missing ErrorTypes from BE . They are commented because Not implemented in FE
  // UserIsAlreadyVerified: 1018, // User was already verified.
  // MissingCountryHeader: 1019, // Missing Country Header.
  // NotSupportedCountry: 1020, // Contry not supported for Credit Card  .
  AdyenServerError: 1021, // Something is wrong with Adyen and we could not call their API successfully
  BrokenToken: 1023,
  JumioServerError: 1024, // Something is wrong with Jumio and we could not call their API successfully
  ChildAccountMissing: 1025, // The child account used was not found in the backend
  TemporaryLinkTokenExpired: 1026 // Temporary Link Token sent to adult user on email has Expired - token has only 24 hours validity from when was generated
};

export const mapBackendToFrontendError = (response) => {
  if (response.data.code) {
    console.log("Error handler response:", response.data.code, response.data);

    switch (response.data.code) {
      case ErrorTypesMap.VpcTokenMissing:
        return ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED;
      case ErrorTypesMap.ParentIdMissing:
      case ErrorTypesMap.ClientCouldNotBeValidated:
      case ErrorTypesMap.InvalidLocale:
      case ErrorTypesMap.NotULLA:
        return ErrorTypes.BAD_REQUEST;
      case ErrorTypesMap.VpcTokenExpired:
      case ErrorTypesMap.BrokenToken:
        return ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED;
      case ErrorTypesMap.ServerError:
      case ErrorTypesMap.ParentNotVerified:
      case ErrorTypesMap.CannotLinkAccounts:
        return ErrorTypes.SERVER_ERROR;
      case ErrorTypesMap.ParentAccountMissing:
        return ErrorTypes.PARENT_ACCOUNT_NOT_FOUND;
      case ErrorTypesMap.NotAnAdult:
        return ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT;
      case ErrorTypesMap.ChildLinkedToOther:
        return ErrorTypes.VALIDATE_PARENT_NOT_THE_CHILD_PARENT;
      case ErrorTypesMap.AdyenServerError:
        return ErrorTypes.CARD_SERVER_ERROR;
      case ErrorTypesMap.JumioServerError:
        return ErrorTypes.ID_SERVER_ERROR;
      case ErrorTypesMap.TemporaryLinkTokenExpired:
        return ErrorTypes.TEMPORARY_LINK_TOKEN_EXPIRED;
      default:
        return ErrorTypes.GENERIC_ERROR;
    }
  }
};
