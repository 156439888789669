import React, { useState, useEffect } from "react";
import { Loader } from "../";
import classNames from "classnames";
import { TrackingService } from "../TrackingService/TrackingService";
const style = require("./Button.less");

export type ButtonMode = "primary" | "warning" | "link" | "link-inline" | "transparent";
export interface ButtonProps {
  id?: string
  mode?: ButtonMode
  loading?: boolean
  showLoaderOnClick?: boolean
  loadingText?: string
  /**
   * If set will be used as the clickAction string for tracking. If not set the `id` is used instead
   */
  clickTracking?: string
}

const Button = (props: ButtonProps & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>) => {

  const {
    id = "btn",
    onClick,
    className = "",
    disabled = false,
    loading = false,
    mode = "primary",
    showLoaderOnClick = false,
    clickTracking,
    loadingText,
    ...buttonProps} = props;
  const [loadingState, setLoadingState] = useState(false);
  const [loadingDisabled, setLoadingDisabled] = useState(false);

  let isMounted = React.useRef(true);

  const clickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    TrackingService.trackClick(clickTracking || id);

    if (!onClick) return;
    event.preventDefault();

    if (showLoaderOnClick) {
      setLoadingState(true);
    } else {
      setLoadingDisabled(true);
    }

    (props.onClick) && props.onClick(event);

    if (isMounted.current) {
      if (showLoaderOnClick) {
        setLoadingState(false);
      } else {
        setLoadingDisabled(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (loading || loadingState) {
    return <div className={style.ButtonContainer}>
      <Loader className={style.ButtonLoaderContainer} ariaLabel={loadingText}/>
    </div>;
  } else {
    const modeStyle = mode ? { [style[mode || ""]]: true } : {};
    const classnames = classNames(style.Button, modeStyle, className);

    return (
      <div className={classNames(style.ButtonContainer, modeStyle)}>
        <button
          {...buttonProps}
          id={id}
          className={classnames}
          disabled={disabled || loadingDisabled}
          onClick={clickHandler}>
          {props.children}
        </button>
      </div>
    )
  }

}

export { Button };