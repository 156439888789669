import { JourneyActionTypes } from "scripts/actions";

const initialState: JourneyState = {
  isConsentable: false
};

export const JourneyReducer = (state = initialState, action: JourneyAction): JourneyState => {
  switch (action.type) {
    case JourneyActionTypes.USER_CONSENTABLE_STATE_VALIDATED:
      return {
        ...state,
        isConsentable: action.isConsentable
      };

    default:
      return state;
  }
};
