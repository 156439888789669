export function getParameterByName(name: string, url?: string) {
  let urlToGetFrom = url ? url : window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i"),
    results = regex.exec(urlToGetFrom);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function getParameterFromHash(name: string, url?: string): string {
  const urlToGetFrom = new URL(url || window.location.href);
  const hashParams = new URLSearchParams(urlToGetFrom.hash.substring(1)); // substring to remove #
  return hashParams.get(name);
}

export function getQueryParameterByName(name: string, url?: string) {
  const urlToGetFrom = new URL(url ? url : window.location.href);
  const value = urlToGetFrom.searchParams.get(name);
  return value ? decodeURIComponent(value.replace(/\+/g, " ")) : null;
}
export function getQueryString() {
  let index = window.location.href.indexOf("?");
  if (index > 0) return window.location.href.substr(index);
  return "";
}
export const injectGNSScriptTag = (
  domain: string,
  culture: string,
  context: string
) => {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = domain + `/${culture}/go/190/v2/${context}`;
  document.body.appendChild(s);
  document.body.classList.add("LEGO-global-header-footer");
};

export function convertToBool(b: any): boolean {
  if (typeof b === "boolean") return b;
  if (typeof b === "string") return b.toLowerCase() === "true";
  return null;
}
export function getParameterAsBool(parameter: string, returnUrl: string) {
  let bool = convertToBool(getParameterByName(parameter));
  if (bool === null) {
    bool = convertToBool(getParameterByName(parameter, returnUrl));
  }
  if (bool === null) {
    bool = false;
  }
  return bool;
}

export default function delve(obj: any, key: string, def?: any): any {
  var p = 0;
  const keyArray = key.split ? key.split(".") : key;
  while (obj && p < keyArray.length) obj = obj[keyArray[p++]];
  return obj === undefined || p < keyArray.length ? def : obj;
}

export const exists = (obj: any, path: string) => {
  if (delve(obj, path) === null) {
    return false;
  } else return delve(obj, path) !== undefined;
};

export const selfHost = (() => {
  return (
    location.protocol +
    "//" +
    location.hostname +
    (location.port ? ":" + location.port : "")
  );
})();

export const stall = async (stallTime = 3000) => {
  await new Promise((resolve) => setTimeout(resolve, stallTime));
};

export const robotsNoIndex = () => {
  const t = document.createElement("meta");
  t.name = "robots";
  t.content = "noindex";
  document.head.appendChild(t);
};

export const addAttribute = (
  selector: string,
  attribute: string,
  value: string
) => {
  const element = document.querySelector(selector);
  if (element) {
    element.setAttribute(attribute, value);
  }
};
