import React from "react";
import { connect } from "react-redux";
import {
  ConsentActions,
  RouteActions,
  NavigationRoute,
  VpcActions
} from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";

export namespace StartConsent {
  export type Props = {
    consentActions: ThunkActionsDispatch<ConsentActions>;
    vpcActions: ThunkActionsDispatch<VpcActions>;
    routeActions: ThunkActionsDispatch<RouteActions>;
  };
}

class StartConsent extends React.Component<StartConsent.Props> {
  async componentDidMount() {
    const { vpcActions, consentActions, routeActions } = this.props;
    if (
      vpcActions.ensureVpcToken() &&
      (await consentActions.ensureParentIsValidated())
    ) {
      routeActions.goto(NavigationRoute.CONSENT);
    }
  }

  render() {
    return <></>;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch): StartConsent.Props {
  return {
    consentActions: bindActionCreators(ConsentActions, dispatch),
    vpcActions: bindActionCreators(VpcActions, dispatch),
    routeActions: bindActionCreators(RouteActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StartConsent);
