import React from "react";
import { connect } from "react-redux";
import { ConsentActions } from "../../../actions/consentActions";
import { bindActionCreators, Dispatch } from "redux";

export namespace StartConsent {
  export type Props = {
    consentActions: ThunkActionsDispatch<ConsentActions>;
  };
}

class StartConsent extends React.Component<StartConsent.Props> {
  async componentDidMount() {
    const { consentActions } = this.props;
    await consentActions.startConsent();
  }

  render() {
    return <></>;
  }
}

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch): StartConsent.Props {
  return {
    consentActions: bindActionCreators(ConsentActions, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(StartConsent);
