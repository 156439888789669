import { VerifyActionTypes } from "../actions/verifyActions";

const initialState: VerifyState = {
  options: undefined
};

export const VerifyReducer = (
  state = initialState,
  action: VerifyActions
): VerifyState => {
  switch (action.type) {
    case VerifyActionTypes.VERIFY_OPTIONS_LOADED:
      return {
        ...state,
        options: action.options
      };
    default:
      return state;
  }
};
