import { push, replace } from "connected-react-router";
import { AuthActions } from "./authActions";
import { ErrorActions, ErrorTypes } from "./errorActions";
import StorageFactory from "../utils/StorageFactory";

const storage = StorageFactory.getStorage();

const navigateToFlow = (context: ContextState, unauthorized: boolean) => {
  const {
    serviceEndpoints: { identityService },
    vpcClientid,
    culture,
    appContext,
    selfHost
  } = context;

  const queryString = [
    `culture=${culture}`,
    `clientid=${encodeURIComponent(vpcClientid)}`
  ];

  if (appContext === true) {
    queryString.push("appContext=true");
  }

  const upgradeSuccessRedirect = encodeURIComponent(
    `${selfHost}/upgradesuccess`
  );

  if (unauthorized === false) {
    queryString.push(`returnUrl=${upgradeSuccessRedirect}`);
    window.location.href = `${identityService}/upgradevpc?${queryString.join(
      "&"
    )}`;
  } else {
    const middleRedirectUrl = encodeURIComponent(
      `/upgradevpc?${queryString
        .concat([`returnUrl=${upgradeSuccessRedirect}`])
        .join("&")}`
    );
    window.location.href = `${identityService}/registerlife?${queryString
      .concat([`returnUrl=${middleRedirectUrl}`])
      .join("&")}`;
  }
};

export namespace GetYourParentsActions {
  export const continueToFlowInfo = (): ThunkAction => async (
    dispatch,
    getState
  ) => {
    try {
      const { context } = getState();

      if (!context.clientIdWasProvidedByClient) {
        dispatch(
          ErrorActions.ErrorAction({ error: ErrorTypes.CLIENT_ID_NOT_PROVIDED })
        );
        return;
      }
      dispatch(
        push({
          pathname: `/${context.culture}/flowinfo`,
          search: location.search
        })
      );
    } catch (error) {
      console.error("continueToFlowInfo", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  };

  export const continueToConsent = (): ThunkAction => async (
    dispatch,
    getState
  ) => {
    try {
      const {
        auth: { email_verified, unauthorized },
        context
      } = getState();

      storage.setItem("email_verified", email_verified ? "true" : "false");

      if (email_verified === true) {
        dispatch(
          push({
            pathname: `/${context.culture}/startconsent`,
            search: location.search
          })
        );
      } else {
        navigateToFlow(context, unauthorized);
      }
    } catch (error) {
      console.error("continueToConsent", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  };

  export const EnsureLoggedInUser = (
    validateAdult: boolean = true
  ): ThunkFunction<Promise<boolean>> => async (dispatch, getState) => {
    try {
      let oneTimeToken =
        window.location.hash && window.location.hash.length > 0
          ? window.location.hash.substr(1)
          : "";

      let user = null;
      if (oneTimeToken) {
        const { router } = getState();
        const searchParams = new URLSearchParams(router.location.search);
        searchParams.append("isott", "true");
        dispatch(
          replace({
            //replace since we do not want to change the backstack to the url without (?isott=true)
            ...router.location,
            search: "?" + searchParams.toString(),
            hash: ""
          })
        );

        if (
          storage.getItem("lastott") &&
          storage.getItem("lastott") === oneTimeToken
        ) {
          // The last one time token used is the same as this one. So assume we have the user.
          user = await dispatch(AuthActions.GetCurrentUser());
        } else {
          user = await dispatch(AuthActions.DoOTTSilentLogin(oneTimeToken));
        }

        storage.setItem("lastott", oneTimeToken);
      } else {
        const {
          context: { isott }
        } = getState();

        if (isott === true) {
          user = await dispatch(AuthActions.GetCurrentUser());
        } else {
          storage.removeItem("lastott");
          user = await dispatch(AuthActions.DoSilentLogin());
        }
      }

      if (validateAdult && user && user.profile && user.profile.isadult) {
        dispatch(AuthActions.ReturnToClient());
        return false;
      }
      return true;
    } catch (error) {
      console.error("EnsureLoggedInUser", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      return false;
    }
  };

  export const CleanSessionState = () => () => {};

  export const ValidateCurrentUserIsConsentable = (): ThunkFunction<
    Promise<boolean>
  > => async (dispatch) => {
    const currentUser = await dispatch(AuthActions.GetCurrentUser());

    if (!currentUser) {
      dispatch(
        ErrorActions.ErrorAction({ error: ErrorTypes.ACCOUNT_REQUIRED })
      );
      return false;
    }

    if (currentUser?.profile?.isadult === true) {
      dispatch(
        ErrorActions.ErrorAction({
          error: ErrorTypes.ACCOUNT_IS_ADULT_IN_CHILD_CONTEXT
        })
      );
      return false;
    }

    return true;
  };
}

export type GetYourParentsActions = typeof GetYourParentsActions;
