import React from "react";
import classNames from "classnames";
import FormElement, { FormElementProps } from "./FormElement";
import { SelectOption } from "./SelectElement";

const style = require("./FormElement.less");

export interface FavoriteSelectOption {
  favorite: SelectOption[]
  all: SelectOption[]
}
export interface FavoriteSelectElementProps {
  /**
   * The selected element.
   */
  value?: string

  /**
   * The options to be selected
   */
  options: FavoriteSelectOption

  /**
   * Called when the selection changes. The value of the selected option.
   */
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void

  onBlur?: (event: React.FocusEvent<HTMLElement>) => void
  /**
   * ClassName for each input field in the set
   */
  className?: string

  /**
   * Disabled to be applied to each input field in the set
   */
  disabled?: boolean
  /**
   * autoComplete value from https://www.w3.org/TR/WCAG21/#input-purposes
   */
  autoComplete?: string,
  favoriteGroupLabel?: string,
  allGroupLabel?: string,
}

const FavoriteSelectElement = (props: FavoriteSelectElementProps & FormElementProps) => {
  let { label, error, onChange, value, options, favoriteGroupLabel, allGroupLabel, ...inputProps } = props;

  const classnames = classNames(
    {
      [style.Select]: true,
      [style.Input]: true,
      [style.InputContainer]: true,
      [style.InputError]: error && error !== "" && error.length !== 0
    },
    props.className);

  const renderOptGroup = (options: SelectOption[], label?: string) => {
    const optionElements = renderOptions(options)
    return label ?
      <optgroup label={label}>
        {optionElements}
      </optgroup>
      : optionElements
  }

  const renderOptions = (options: SelectOption[]) => options.map(o => <option key={o.value} value={o.value}>{o.label}</option>)

  const renderElement = () => {

    return (
      <select
        {...inputProps}
        className={classnames}
        onChange={onChange}
        value={value || ""}
      >
        {renderOptGroup(options.favorite, favoriteGroupLabel)}
        {renderOptGroup(options.all, allGroupLabel)}
      </select>
    );
  }

  return (
    <FormElement
      label={label}
      error={error}
      {...inputProps}
      element={renderElement()} />
  )

}

export { FavoriteSelectElement };