import { push } from "connected-react-router";
import { JourneyRoute, resolveCulturedPath } from "scripts/routes";
import { ErrorActions, ErrorTypes } from "scripts/actions";
import {
  createAvatarOutfitEntry,
  getActiveOutfitAsync,
  getPublicOutfitsAsync,
  setActiveOutfitAsync
} from "scripts/services/AvatarService";
import {
  getNicknamesAsync,
  getCurrentNicknameAsync,
  setCurrentNicknameAsync
} from "scripts/services/DisplaynameService";
import { JourneyActions } from "./journeyActions";
import { GetYourParentsActions } from "./getYourParentsActions";
import { userManager } from "scripts/services";

export enum PublicProfileActionTypes {
  INITIALIZED = "[PUBLIC PROFILE] INITIALIZED"
}

export namespace PublicProfileActions {
  export const initializeAsync = (
    fallbackAvatars: AvatarOutfitEntry[]
  ): ThunkActionAsync => async (dispatch, getState) => {
    const { context } = getState();

    try {
      await dispatch(GetYourParentsActions.EnsureLoggedInUser(false));
      await dispatch(JourneyActions.validateCurrentUserIsConsentable());

      const currentDisplayname = await getCurrentNicknameAsync(
        context.serviceEndpoints.displaynameService
      );
      const isGuest = /^Guest_[a-f0-9]{32}$/.test(currentDisplayname.nickname);

      // If user is NOT a guest, skip naminator
      if (!isGuest) {
        dispatch(push(JourneyRoute.GETYOURPARENTS));
        return;
      }

      const [outfits, currentOutfit, nicknamesResult] = await Promise.all([
        getPublicOutfitsAsync(
          context.serviceEndpoints.avatarService,
          fallbackAvatars
        ),
        getActiveOutfitAsync(context.serviceEndpoints.avatarService),
        getNicknamesAsync(
          context.serviceEndpoints.displaynameService,
          context.culture
        )
      ]);

      const isNicknameEditable =
        currentDisplayname.nicknameChangeGracePeriodDays <= 0;

      const availableDisplaynames =
        currentDisplayname.nickname.length > 0 && !isGuest
          ? [currentDisplayname.nickname, ...nicknamesResult.nicknames]
          : [...nicknamesResult.nicknames];

      // If current avatar is not in public avatars, add it to the list
      if (
        currentOutfit.ok &&
        outfits.findIndex((a) => a.id === currentOutfit.payload.Id) === -1
      ) {
        outfits.unshift(createAvatarOutfitEntry(currentOutfit.payload));
      }

      dispatch<PublicProfileStateLoaded>({
        type: PublicProfileActionTypes.INITIALIZED,
        outfits: outfits,
        nicknames: isNicknameEditable
          ? availableDisplaynames
          : [currentDisplayname.nickname],
        nicknameLanguage: nicknamesResult.language,
        isNicknameEditable: isNicknameEditable,
        initialOutfitId: currentOutfit.ok ? currentOutfit.payload.Id : null
      });
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      return;
    }
  };

  export const persistOutfitAndDisplayname = (
    outfitId: string,
    displayname: string,
    redirectTo?: JourneyRoute
  ): ThunkActionAsync => async (dispatch, getState) => {
    const { context, publicProfile } = getState();
    const user = await userManager.instance().getUser();

    if (!user) {
      dispatch(
        ErrorActions.ErrorAction({ error: ErrorTypes.ACCOUNT_REQUIRED })
      );
      return;
    }

    try {
      if (
        publicProfile.isNicknameEditable &&
        displayname !== user.profile.nickname
      ) {
        const displaynameResponse = await setCurrentNicknameAsync(
          context.serviceEndpoints.displaynameService,
          publicProfile.nicknameLanguage,
          displayname
        );
        if (!displaynameResponse.ok) {
          switch (displaynameResponse.status) {
            case 409:
              dispatch(
                ErrorActions.ErrorAction({ error: ErrorTypes.NICKNAME_IN_USE })
              );
              break;
            case 404:
              dispatch(
                ErrorActions.ErrorAction({
                  error: ErrorTypes.NICKNAME_USER_NOT_FOUND
                })
              );
              break;
            default:
              dispatch(ErrorActions.ErrorResponse(displaynameResponse));
              break;
          }
          return;
        }
      }

      if (outfitId !== publicProfile.initialOutfitId) {
        await setActiveOutfitAsync(
          context.serviceEndpoints.avatarService,
          outfitId
        );
      }

      if (redirectTo)
        dispatch(push(resolveCulturedPath(redirectTo, context.culture)));
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      return;
    }
  };
}

export type PublicProfileActions = typeof PublicProfileActions;
