import React from "react";
const style = require("./Checklist.less");

export interface ChecklistItemProps {
    title: string
    description?: string,
    uniqueKey?: string,
    checked?: boolean
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({ title, description = null, checked = false }): JSX.Element => (
    <>
        <div className={style.ChecklistItem}>
            <div className={style.ChecklistItemHeader}>
                {title} {checked && <span className={style.ChecklistItemCheckmark} />}
            </div>
            {description && <div className={style.ChecklistItemText}>
                {description}
            </div>}
        </div>
    </>
)

export { ChecklistItem };