import i18n, { t } from "scripts/utils/i18n";

const UserAgentParser = require("ua-parser-js");

export interface SupportedBrowsers {
  Chrome: number;
  Edge: number;
  Safari: number;
  "Mobile Safari": number;
  Opera: number;
  Firefox: number;
  Vivaldi?: number;
  Facebook?: number;
  [key: string]: number;
}

export interface OutdatedBrowserOptions {
  browserSupport: SupportedBrowsers;
  requireChromeOnAndroid: boolean;
  isUnknownBrowserOK: boolean;
  backgroundColor?: string;
  textColor?: string;
  fullscreen?: boolean;
}

interface MappedEdgeVersions {
  [key: number]: number;
}

interface BrowserTranslatedMessages {
  web: string;
  googlePlay: string;
  appStore: string;
  [key: string]: string;
}

interface TranslatedMessages {
  outOfDate: string;
  update: BrowserTranslatedMessages;
  url: string;
  callToAction: string;
  close: string;
}

const DEFAULTS: SupportedBrowsers = {
  Chrome: 90, // Includes Chrome for mobile devices
  Edge: 39,
  Safari: 10,
  "Mobile Safari": 10,
  Opera: 78,
  Firefox: 78,
  Vivaldi: 1,
  Facebook: 150
};

const EDGEHTML_VS_EDGE_VERSIONS: MappedEdgeVersions = {
  12: 0.1,
  13: 21,
  14: 31,
  15: 39,
  16: 41,
  17: 42
};

const COLORS = {
  salmon: "#f25648",
  white: "white"
};

const updateDefaults = (
  defaults: SupportedBrowsers,
  updatedValues: SupportedBrowsers
) => ({ ...defaults, ...updatedValues });

const outdatedBrowser = (options: OutdatedBrowserOptions) => {
  // Despite the docs, UA needs to be provided to constructor explicitly:
  // https://github.com/faisalman/ua-parser-js/issues/90
  const parsedUserAgent = new UserAgentParser(
    window.navigator.userAgent
  ).getResult();

  // Variable definition (before ajax)
  let outdatedUI: HTMLElement = document.getElementById("outdated");
  if (outdatedUI === null) {
    const mainDiv = document.querySelector("div.main");
    if (mainDiv) {
      outdatedUI = <HTMLElement>document.createElement("div");
      outdatedUI.id = "outdated";
      mainDiv.prepend(outdatedUI);
    }
  }

  // Set default options
  const browserSupport = options.browserSupport
    ? updateDefaults(DEFAULTS, options.browserSupport)
    : DEFAULTS;
  // CSS property to check for. You may also like 'borderSpacing', 'boxShadow', 'transform', 'borderImage';
  const {
    backgroundColor = COLORS.salmon,
    textColor = COLORS.white,
    fullscreen = false
  } = options;

  let updateSource = "web"; // Other possible values are 'googlePlay' or 'appStore'. Determines where we tell users to go for upgrades.

  // Chrome mobile is still Chrome (unlike Safari which is 'Mobile Safari')
  const isAndroid = parsedUserAgent.os.name === "Android";
  if (isAndroid) {
    updateSource = "googlePlay";
  }

  let isAndroidButNotChrome;
  if (options.requireChromeOnAndroid) {
    isAndroidButNotChrome =
      isAndroid && parsedUserAgent.browser.name !== "Chrome";
  }

  if (parsedUserAgent.os.name === "iOS") {
    updateSource = "appStore";
  }

  let done = true;

  const changeOpacity = (opacityValue: number) => {
    if (outdatedUI) {
      outdatedUI.style.opacity = (opacityValue / 100).toString();
      outdatedUI.style.filter = `alpha(opacity=${opacityValue})`;
    }
  };

  const fadeIn = (opacityValue: number) => {
    if (outdatedUI) {
      changeOpacity(opacityValue);
      if (opacityValue === 1) {
        outdatedUI.style.display = "table";
      }
      if (opacityValue === 100) {
        done = true;
      }
    }
  };

  const isBrowserOutOfDate = () => {
    const browserName = parsedUserAgent.browser.name;
    let browserMajorVersion: number = +parsedUserAgent.browser.major;
    if (browserName === "Edge") {
      browserMajorVersion =
        EDGEHTML_VS_EDGE_VERSIONS[browserMajorVersion] ?? browserMajorVersion;
    }
    let isOutOfDate = false;
    if (!browserSupport[browserName]) {
      if (!options.isUnknownBrowserOK) {
        isOutOfDate = true;
      }
    } else if (browserMajorVersion < browserSupport[browserName]) {
      isOutOfDate = true;
    }
    return isOutOfDate;
  };

  const makeFadeInFunction = (opacityValue: number) => fadeIn(opacityValue);

  // Style element explicitly - TODO: investigate and delete if not needed
  const startStylesAndEvents = () => {
    // check settings attributes
    if (outdatedUI) {
      outdatedUI.style.backgroundColor = backgroundColor;
      // way too hard to put !important on IE6
      outdatedUI.style.color = textColor;
      (<HTMLElement>outdatedUI.children[0].children[0]).style.color = textColor;
      (<HTMLElement>outdatedUI.children[0].children[1]).style.color = textColor;
    }
    // Update button is desktop only
    const buttonUpdate: HTMLElement = document.getElementById(
      "buttonUpdateBrowser"
    );
    if (buttonUpdate) {
      buttonUpdate.style.color = textColor;
      if (buttonUpdate.style.borderColor) {
        buttonUpdate.style.borderColor = textColor;
      }

      // Override the update button color to match the background color
      buttonUpdate.onmouseover = function (this: GlobalEventHandlers) {
        (this as HTMLElement).style.color = backgroundColor;
        (this as HTMLElement).style.backgroundColor = textColor;
      };

      buttonUpdate.onmouseout = function (this: GlobalEventHandlers) {
        (this as HTMLElement).style.color = textColor;
        (this as HTMLElement).style.backgroundColor = backgroundColor;
      };
    }

    const buttonClose: HTMLElement = document.getElementById(
      "buttonCloseUpdateBrowser"
    );
    if (buttonClose) {
      buttonClose.style.color = textColor;
      buttonClose.onmousedown = (ev) => {
        ev.preventDefault();
        if (outdatedUI) outdatedUI.style.display = "none";
        return false;
      };
    }
  };

  const getmessage = (messages: TranslatedMessages) => {
    const updateMessages: BrowserTranslatedMessages = {
      web: `<p>${messages.update.web}
      <a id="buttonUpdateBrowser" rel="nofollow" href="${messages.url}">${messages.callToAction}</a>
      </p>`,
      googlePlay: `<p>${messages.update.googlePlay}
      <a id="buttonUpdateBrowser" rel="nofollow" href="https://play.google.com/store/apps/details?id=com.android.chrome">
      ${messages.callToAction}
      </a>
      </p>`,
      appStore: `<p>${messages.update[updateSource]}</p>`
    };

    const updateMessage = updateMessages[updateSource];

    return `<div class="vertical-center">
    <h6>${messages.outOfDate}</h6>
    ${updateMessage}
    <p class="last">
      <a href="#" id="buttonCloseUpdateBrowser" title="${messages.close}">&times;</a>
    </p>
    </div>`;
  };

  // Check if browser is supported
  if (isBrowserOutOfDate() || isAndroidButNotChrome) {
    i18n.loadNamespaces(["OutdatedBrowser"]).then(() => {
      const messages = {
        outOfDate: t("OutdatedBrowser:out_of_date"),
        update: {
          web: t("OutdatedBrowser:web"),
          googlePlay: t("OutdatedBrowser:google_play"),
          appStore: t("OutdatedBrowser:app_store")
        },
        url: "",
        callToAction: "",
        close: t("OutdatedBrowser:close")
      };

      // This is an outdated browser
      if (done && outdatedUI?.style?.opacity !== "1") {
        done = false;

        for (let opacity = 1; opacity <= 100; opacity++) {
          setTimeout(() => makeFadeInFunction(opacity), opacity * 8);
        }
      }
      if (!outdatedUI) return;

      if (fullscreen) {
        outdatedUI.classList.add("fullscreen");
      }
      outdatedUI.innerHTML = getmessage(messages);
      startStylesAndEvents();
    });
  }
};

export default outdatedBrowser;
