import React, { useState } from "react";
const showAnimation = require("./../Transitions/ShowAccordion.less");
const style = require("./ActionAccordion.less");
import { CSSTransition } from "react-transition-group";

import { RadioElement, ComponentStyle, ComponentType } from "..";
import classNames from "classnames";
export interface ActionAccordionProps {
    className?: string,
    id: string;
    imageSrc?: string;
    imageAlt?: string;
    errorStatus?: boolean;
    i18n: ActionAccordionI18N;
    value?: ActionAccordionValue;
    testId?: string;
    onChange?: (e:React.ChangeEvent<HTMLInputElement>) => void
}
export interface ActionAccordionI18N{
  labelYes: string;
  labelNo: string;
  readMore: string;
  readLess: string;
  title: string;
}

export enum ActionAccordionValue {
  "Yes" = "yes",
  "No" = "no",
  "NotSet" = "notSet",
}

const ActionAccordion: React.FunctionComponent<ActionAccordionProps> = (props: React.PropsWithChildren<ActionAccordionProps>)  => {
  const { id, i18n, imageSrc, imageAlt = "", children, value = undefined, testId } = props;

    const [expanded, setExpanded] = useState<boolean>(false);

  const isImage = imageSrc && imageSrc !== "";

  const handleSetExpander = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent<HTMLAnchorElement>) => {
    if (event.type === "keypress") {
      event.preventDefault();
    }
    setExpanded(expand => !expand);
  }

  return (
    <li className={classNames(style.ActionAccordion, props.className)} id={id} data-component={"ActionAccordion"} data-testid={testId}>
      <div className={style.ContentContainer}>
        {isImage && (
          <div className={style.ActionAccordionPicture}>
            <img src={imageSrc} alt={imageAlt} />
          </div>
        )}
        <div className={style.InfoBox}>
          <div
            id={`consent-${id}`}
            className={classNames(style.ActionAccordionTitle, { [style.ActionAccordionTitleThick]: !isImage && !children })}
            data-testid="ActionAccordion-title"
          >
            {i18n.title}
          </div>

          <a
            id={`accordion-${id}`}
            role="link"
            aria-expanded={expanded}
            aria-controls={`section-${id}`}
            className={style.ReadMoreRef}
            onClick={handleSetExpander}
            onKeyPress={handleSetExpander}
            tabIndex={0}
            aria-labelledby={`consent-${id}`}
          >
              {(children != null) && <>{!expanded ? i18n.readMore : i18n.readLess}</> }
          </a>
        </div>
        {(props.onChange != null) &&

        <div className={style.RadioElementsContainer}
            role="radiogroup"
            aria-activedescendant={`consentYes-${id}`}
            tabIndex={-1}
            aria-required="true"
            aria-label={i18n.title}>
                <RadioElement
                  id={`consentYes-${id}`}
                  name={`group-${id}`}
                  label={i18n.labelYes}
                  componentstyle={ComponentStyle.consent}
                  componenttype={ComponentType.checkmark}
                  value={ActionAccordionValue.Yes}
                  checked={value === ActionAccordionValue.Yes}
                  onChange={props.onChange ? props.onChange : undefined}
                  aria-labelledby={`consent-${id} consentYes-${id}`}
                />
                <RadioElement
                  id={`consentNo-${id}`}
                  name={`group-${id}`}
                  label={i18n.labelNo}
                  componentstyle={ComponentStyle.consent}
                  componenttype={ComponentType.close}
                  value={ActionAccordionValue.No}
                  checked={value === ActionAccordionValue.No}
                  onChange={props.onChange ? props.onChange : undefined}
                  aria-labelledby={`consent-${id} consentNo-${id}`}
                />
          </div>

        }
      </div>
      <CSSTransition
        in={expanded}
        timeout={200}
        classNames={showAnimation}
        unmountOnExit
      >
        <div
          id={`section-${id}`}
          role="region"
          aria-labelledby={`accordion-${id}`}
          className={style.ActionAccordionBody}
        >
          {children}
        </div>
      </CSSTransition>
    </li>
  );
};

export { ActionAccordion };
