import React, { forwardRef } from "react";
import {
  GivenConsentState,
  ConsentSchemaType
} from "scripts/types/consentTypes";
import GlobalConsentsPresentation from "./GlobalConsentsPresentation";
import ExperienceConsentsPresentation from "./ExperienceConsentsPresentation";
import BlanketConsentsPresentation from "./BlanketConsentsPresentation";
import { BareUnsortedList } from "@lego/lego-account-ui";

export type PresentationProps = {
  consentsData: ConsentConfiguration;
  onConsentChange: (uri: string, state: GivenConsentState) => void;
};

export default forwardRef((props: PresentationProps, ref: React.Ref<HTMLUListElement>) => {
  const { consentsData, onConsentChange } = props;
  const globalConsents: BaseConsent<GlobalConsentData>[] = [];
  const experienceConsents: BaseConsent<ExperienceConsentData>[] = [];
  const blanketConsents: BaseConsent<BlanketConsentData>[] = [];

  consentsData.consents.forEach((consent) => {
    switch (consent.data.schema) {
      case ConsentSchemaType.Global:
        globalConsents.push(consent as BaseConsent<GlobalConsentData>);
        break;
      case ConsentSchemaType.Experience:
        experienceConsents.push(consent as BaseConsent<ExperienceConsentData>);
        break;
      case ConsentSchemaType.Blanket:
        blanketConsents.push(consent as BaseConsent<BlanketConsentData>);
        break;
      default:
        console.log(
          "Skipping unsupported consent type for consent: %s",
          consent.consentOptionUri
        );
        break;
    }
  });

  return (
    <BareUnsortedList ref={ref}>
      <ExperienceConsentsPresentation
        consents={experienceConsents}
        consentsData={consentsData}
        onConsentChange={onConsentChange}
      />
      <GlobalConsentsPresentation
        consents={globalConsents}
        consentsData={consentsData}
        onConsentChange={onConsentChange}
      />
      <BlanketConsentsPresentation
        consents={blanketConsents}
        consentsData={consentsData}
        onConsentChange={onConsentChange}
      />
    </BareUnsortedList>
  );
});
