export type RestRequestMethod = "HEAD" | "GET" | "POST" | "PUT" | "DELETE";

type RestRequest = {
  method: RestRequestMethod;
  endpoint: string;
  path: string;
  contentType?: string;
  query?: Record<string, string>;
  headers?: Record<string, string>;
  data?: string | object;
  /**
   * If undefined it will default to same-origin
   */
  mode?: RequestMode;
  /**
   * Credentials are cookies, authorization headers or TLS client certificates.
   */
  credentials?: RequestCredentials;
};

export interface ModelStateError {
  [index: string]: string[];
}

export type RestResponse<T> = {
  statusText: string;
  internalError: boolean;
  badRequest: boolean;
  notFound: boolean;
  forbidden: boolean;
  unauthorized: boolean;
  ok: boolean;
  noContent: boolean;
  status: number;
  payload: T;
  error: ModelStateError;
  errors?: string[];
  exception: Error | string;
  requestID?: string;
};

const getBody = (request: RestRequest): string => {
  if (request.data && typeof request.data === "object") {
    return JSON.stringify(request.data);
  }
  return request.data as string;
};

const getContentTypeHeader = (request: RestRequest): string => {
  if (request.data && typeof request.data === "object") {
    return "application/json";
  }
  return request.contentType || "application/x-www-form-urlencoded";
};

export const requestAsync = async <T>(
  request: RestRequest
): Promise<RestResponse<T>> => {
  const requestHeaders = new Headers();
  if (request.method !== "HEAD" && request.method !== "GET")
    requestHeaders.set("Content-Type", getContentTypeHeader(request));
  requestHeaders.set("Accept", "application/json");

  const uri = new URL(request.path, request.endpoint);

  if (request.query) {
    for (const key in request.query) {
      if (request.query.hasOwnProperty(key)) {
        uri.searchParams.append(key, request.query[key]);
      }
    }
  }

  if (request.headers) {
    for (const key in request.headers) {
      if (request.headers.hasOwnProperty(key)) {
        requestHeaders.append(key, request.headers[key]);
      }
    }
  }

  const options: RequestInit = {
    method: request.method,
    headers: requestHeaders,
    body: getBody(request),
    mode: request.mode ?? "same-origin",
    credentials: request.credentials,
    referrerPolicy: "no-referrer-when-downgrade"
  };

  try {
    const response = await fetch(uri.href, options);
    const contentType = response.headers.get("content-type");
    const requestID = response.headers.get("x-amz-cf-id");
    const result: RestResponse<T> = {
      statusText: response.statusText,
      internalError: response.status >= 500,
      badRequest: response.status === 400,
      notFound: response.status === 404,
      forbidden: response.status === 403,
      unauthorized: response.status === 401,
      status: response.status,
      ok: response.ok,
      noContent: response.status === 204,
      payload: {} as T,
      error: {},
      errors: [],
      exception: null,
      requestID: requestID
    };

    if (response.ok) {
      if (
        !result.noContent &&
        contentType &&
        contentType.includes("application/json")
      ) {
        result.payload = await response.json();
      }
    } else {
      if (
        contentType &&
        (contentType.includes("application/json") ||
          contentType.includes("application/problem+json"))
      ) {
        result.error = await response.json();
        // Map errors to a simple array for easier validation in some scenarios
        // The where doesn't matter - just the why/what
        const { errors } = result.error;
        if (errors) {
          result.errors = Object.keys(errors)
            .map((key: string) => errors[key as any])
            .flat();
        }
      } else {
        console.error("No json payload present!");
      }
    }
    return result;
  } catch (error) {
    return {
      statusText:
        error && error.message ? error.message : JSON.stringify(error),
      internalError: false,
      badRequest: false,
      notFound: false,
      forbidden: false,
      unauthorized: false,
      noContent: false,
      status: 0,
      ok: false,
      payload: {} as T,
      error: {},
      errors: [],
      exception: error
    };
  }
};
