import { LocationDescriptorObject } from "history";
import { FlowType } from "scripts/types/storeTypes";
import constants from "scripts/constants";

export const createRoute = (route: string) =>
  `${constants.cultureRoutePrefix}${route}`;

export const resolvePath = (
  route: string,
  culture: string,
  search?: string,
  hash?: string
): LocationDescriptorObject<any> => ({
  pathname: resolveCulturedPath(route, culture),
  search,
  hash
});

export const resolveCulturedPath = (route: string, culture: string): string =>
  `/${culture}${route}`;

export const isJourneyFlow = (flowType: FlowType) =>
  flowType === FlowType.Email;
