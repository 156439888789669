import React from "react";
import classNames from "classnames";
import { TrackingService } from "../TrackingService/TrackingService";
const style = require("./FormElement.less");
const baseStyle = require("../Styles/Base.less");

export interface FormElementProps {
  /**
   * ID for the element. Must be unique.
   */
  id: string
  /**
   * Label for the form element
   */
  label: string
  /**
   * Name for the form. Used for tracking.
   */
  formname?: string
  /**
   * Used for validation errors or other alert type errors
   */
  error?: string | string[]
  success?: string | string[]
  help?: string | string[]

  /**
   * Custom Style 
   */
  
  customClass?: string
}

export interface FormElementParentProps {
  element: React.ReactElement,
  hideLabel?: boolean
}

const FormElement = (props: FormElementProps & FormElementParentProps & React.HTMLAttributes<HTMLElement>) => {
  const { formname = "form" } = props
  const error: string[] = new Array<string>().concat(props.error || []);
  const success: string[] = new Array<string>().concat(props.success || []);
  const help: string[] = new Array<string>().concat(props.help || []);

  const blurHandler = (event: React.FocusEvent<HTMLElement>) => {
    TrackingService.trackLeavingField(formname, event.target.id, error.length > 0 ? error[0] : undefined)
    if (props.onBlur) {
      props.onBlur(event);
    }
  };

  return (
    <div className={classNames(style.Container, props.customClass)}>
      {props.hideLabel !== true && <div className={style.LabelContainer}>
        <label htmlFor={props.id} className={style.Label}>{props.label}</label>
      </div>}
      <div className={style.FieldContainer}>
        {React.cloneElement(props.element, {
          onBlur: blurHandler,
          "aria-describedby": (error && error.length) ? `${props.id}Error` : undefined,
          error: undefined
        })}
      </div>
      <div className={style.Underneath}>
        {(error && error.length > 0) && <span role="alert" id={`${props.id}Error`} className={classNames(style.ErrorText, style.Information)}>{error.map(e => <div key={e}>{e}</div>)}</span>}
        {(success && success.length > 0) && <span id={`${props.id}Success`} className={classNames(style.SuccessText, style.Information)}>{success.map(e => <div key={e}>{e}</div>)}</span>}
        {(help && help.length > 0) && <span id={`${props.id}Help`} className={classNames(style.HelpText, style.Information)}>{help.map(e => <div key={e}>{e}</div>)}</span>}
      </div>
     
    </div>
  )

}

export default FormElement;