import { replace } from "connected-react-router";
import { RestResponse, VPCApiClient } from "scripts/services";
import {
  AuthActions,
  VpcActions,
  ErrorActions,
  ErrorTypes,
  RouteActions,
  NavigationRoute
} from "scripts/actions";
import { GivenConsentState } from "scripts/types/consentTypes";
import isOAuthClient from "scripts/utils/clientIdHelpers";

export enum ConsentActionTypes {
  CONSENT_CONSENTS_LOADED = "[CONSENT] CONSENTS_LOADED"
}

export namespace ConsentActions {
  export const startConsent = (
    login: boolean = false
  ): ThunkFunction<Promise<void>> => async (dispatch, getState) => {
    try {
      const {
        context,
        auth: { user }
      } = getState();

      let userToUse = user;
      if (!userToUse || !userToUse.profile) {
        userToUse = await dispatch(AuthActions.DoSilentLogin());
        if (!userToUse || !userToUse.profile) {
          dispatch(
            ErrorActions.ErrorAction({
              error: ErrorTypes.LOGIN_REQUIRED
            })
          );
          return;
        }
      }
      if (userToUse.profile.isadult) {
        dispatch(
          ErrorActions.ErrorAction({
            error: ErrorTypes.USER_IS_ADULT
          })
        );
        return;
      } else {
        try {
          const [data, parentsData] = await Promise.all([
            VPCApiClient.createSessionFromChild(
              context.serviceEndpoints.vpcRootDomain,
              context.clientid,
              context.culture
            ),
            VPCApiClient.getCurrentParents(
              context.serviceEndpoints.vpcRootDomain,
              userToUse.access_token
            )
          ]);
          if (!data.ok) {
            dispatch(ErrorActions.ErrorResponse(data));
            return;
          }
          dispatch(VpcActions.updateVpcToken(data.payload.token));

          var search = new URLSearchParams(location.search);
          if (userToUse.profile.sub && userToUse.profile.nickname) {
            search.set("uid", userToUse.profile.sub);
            search.set("n", userToUse.profile.nickname);
          }
          const linkConsentUri = `/linkconsent?${search}`;
          if (parentsData?.parents.length > 0 || login) {
            dispatch(
              AuthActions.DoRedirectLogin(linkConsentUri, undefined, "login")
            );
          } else {
            dispatch(
              AuthActions.DoRedirectLogin(linkConsentUri, "flow:register")
            );
          }
        } catch (error) {
          dispatch(
            ErrorActions.ErrorAction({
              error: "issueToken_error"
            })
          );
          return;
        }
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      return;
    }
  };

  export const ensureParentIsValidated = (): ThunkFunction<
    Promise<boolean>
  > => async (dispatch, getState) => {
    try {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain }
        },
        vpc: { VPCToken, VPCParentValidated }
      } = getState();

      // If the parent is validated we're good
      if (VPCParentValidated) {
        return true;
      }

      let user = await dispatch(AuthActions.GetCurrentUser());
      if (!user) {
        user = await dispatch(AuthActions.DoSilentLogin());
      }
      if (!user?.profile) {
        dispatch(
          ErrorActions.ErrorAction({
            error: ErrorTypes.LOGIN_REQUIRED
          })
        );
        return false;
      }
      if (user.profile.isadult === false) {
        dispatch(
          ErrorActions.ErrorAction({
            error: ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT
          })
        );
        return false;
      }

      const res = await VPCApiClient.SetCurrentAdultUser(
        vpcRootDomain,
        VPCToken
      );
      if (res.ok) {
        dispatch(VpcActions.parentValidated(true));
        return true;
      } else if (res.badRequest) {
        if (res.errors.includes("adult_user_required")) {
          dispatch(
            ErrorActions.ErrorAction({
              error: ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT
            })
          );
        } else {
          dispatch(
            ErrorActions.ErrorAction({
              error: res.errors[0]
            })
          );
        }
        return false;
      } else if (res.unauthorized) {
        dispatch(
          ErrorActions.ErrorAction({ error: ErrorTypes.LOGIN_REQUIRED })
        );
        return false;
      } else {
        dispatch(ErrorActions.ErrorResponse(res));
        return false;
      }
    } catch (error) {
      dispatch(ErrorActions.ErrorAction({ error }));
      return false;
    }
  };

  export const redirectToNextStep = (
    persist: boolean = true
  ): ThunkFunction<Promise<void>> => async (dispatch, getState) => {
    try {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain },
          clientid
        }
      } = getState();

      let user = await dispatch(AuthActions.GetCurrentUser());
      if (!user) {
        user = await dispatch(AuthActions.DoSilentLogin());
      }
      const status = await VPCApiClient.getVerificationStatus(
        vpcRootDomain,
        user.access_token
      );
      if (!status.ok) {
        dispatch(ErrorActions.ErrorResponse(status));
        return;
      }

      const extraSearch = new URLSearchParams();
      if (!persist) extraSearch.append("persist", "false");

      if(status.payload.verified) {
        dispatch(
          RouteActions.goto(
            NavigationRoute.SUCCESS,
            extraSearch
          )
        );
      } else {
        dispatch(
          RouteActions.goto(
            NavigationRoute.VERIFY_OPTIONS,
            extraSearch
          )
        );
      }

    } catch (error) {
      console.error("redirectToNextStep", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
    }
  };

  export const saveConsents = (
    clientConsents: VpcConsentEntryState[]
  ): ThunkFunction<Promise<boolean>> => async (dispatch, getState) => {
    try {
      const {
        context: {
          serviceEndpoints: { vpcRootDomain }
        },
        vpc: { VPCToken }
      } = getState();

      if (clientConsents.length === 0) return true; // No consents to save

      const resultPostConsents = await VPCApiClient.postConsents(
        vpcRootDomain,
        VPCToken,
        clientConsents
      );
      return resultPostConsents?.status === "success";
    } catch (error) {
      console.error("saveConsents", { ...error });
      dispatch(ErrorActions.ErrorAction({ error }));
      return false;
    }
  };

  export const loadConsentConfiguration = (): ThunkFunction<
    Promise<ConsentConfiguration>
  > => async (dispatch, getState) => {
    const {
      vpc: { VPCToken },
      context: {
        serviceEndpoints: { vpcRootDomain }
      }
    } = getState();

    const [experienceResponse, activeConsents, consentsContent] = await Promise.all([
      VPCApiClient.getExperienceConfiguration(
        vpcRootDomain,
        VPCToken
      ),
      VPCApiClient.getChildConsents(
        vpcRootDomain,
        VPCToken
      ),
      VPCApiClient.getConsentsContent(
        vpcRootDomain,
        VPCToken
      )
    ]);

    if (!consentsContent.ok) {
      dispatch(ErrorActions.ErrorResponse(consentsContent));
      throw "Failed to get consents";
    }

    if (!activeConsents.ok) {
      dispatch(ErrorActions.ErrorResponse(activeConsents));
      throw "Failed to get experience";
    }
    let consentRecords: Record<string, Consent> = {};
    for (const consent of activeConsents.payload.consents) {
      consentRecords[consent.optionId] = consent;
    }


    return {
      experience: {
        experienceAuthority: experienceResponse.payload.id,
        data: {
          title: experienceResponse.payload.title,
          logo: experienceResponse.payload.logo
        }
      },
      consents: convertToBaseConsents(consentsContent, consentRecords)
    };
  };

  export const consentPersistData = (): ThunkFunction<Promise<void>> => async (
    dispatch,
    getState
  ) => {
    try {
      const {
        vpc: { VPCToken },
        context: {
          serviceEndpoints: { vpcRootDomain }
        }
      } = getState();

      const statusResponse = await VPCApiClient.accountLinksStatus(
        vpcRootDomain,
        VPCToken
      );

      if (statusResponse.ok && statusResponse.payload.child.isLinked === true)
        dispatch(addIsReconsentToUrl(true));

      await VPCApiClient.putRelationsCurrent(vpcRootDomain, VPCToken);
      return await VPCApiClient.persistConsents(vpcRootDomain, VPCToken);
    } catch (error) {
      console.error("consentPersistData", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  };

  export const finalizeFlowAsync = (): ThunkFunction<Promise<'default'|'redirect'>> => async (
    dispatch,
    getState
  ) => {
    try {
      const {
        vpc: { VPCToken },
        context: {
          clientid,
          serviceEndpoints: { vpcRootDomain }
        }
      } = getState();
      const user = await dispatch(AuthActions.GetCurrentUser());

      const response = await VPCApiClient.finalize(
        vpcRootDomain,
        user.access_token,
        VPCToken
      );

      await dispatch(AuthActions.DoOTTSilentLogin(response.token));

      if (isOAuthClient(clientid)) {
        dispatch(AuthActions.ReturnToClient())
        return 'redirect'
      }
      return 'default';
    } catch (error) {
      console.error("finalizeFlow", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  };

  export const finalizeOffDeviceFlowAsync = (): ThunkFunction<Promise<void>> => async (
    dispatch,
    getState
  ) => {
    try {
      const {
        vpc: { VPCToken },
        context: {
          clientid,
          serviceEndpoints: { vpcRootDomain }
        }
      } = getState();
      const user = await dispatch(AuthActions.GetCurrentUser());

      await VPCApiClient.finalize(
        vpcRootDomain,
        user.access_token,
        VPCToken
      );
    } catch (error) {
      console.error("finalizeOffDeviceFlow", JSON.stringify(error));
      dispatch(ErrorActions.ErrorAction({ error }));
      throw error;
    }
  }

  export const addPersistFalseToUrl = (): ThunkAction => (dispatch) => {
    var search = new URLSearchParams(window.location.search);
    search.set("persist", "false");
    dispatch(replace({ search: `?${search.toString()}` }));
  };

  export const addIsReconsentToUrl = (isReconsent: boolean): ThunkAction => (
    dispatch
  ) => {
    let search = new URLSearchParams(window.location.search);
    search.set("isReconsent", isReconsent ? "true" : "false");
    dispatch(replace({ search: `?${search.toString()}` }));
  };

  function convertToBaseConsents(
    consentsContent: RestResponse<GetExperienceConsentsContentResponse>,
    consentRecords: Record<string, Consent>
  ): BaseConsent<BaseConsentData>[] {
    return consentsContent.payload.consents.map(
      (consent: GetConsentContentResponse) => {
        let c: BaseConsent<BaseConsentData>;
        if (consent.id === null) {
          throw "Missing id on consent";
        }
        if (consent.blanket)
          c = <BaseConsent<BlanketConsentData>>{
            consentOptionUri: consent.id,
            data: {
              title: consent.blanket.title,
              schema: "https://vpc.lego.com/schemas/consents/blanket.json",
              terms: consent.blanket.terms.map(
                (term) =>
                  <BlanketConsentTerm>{
                    title: term.title,
                    icon: term.icon,
                    description: term.description
                  }
              )
            },
            state: convertState(consentRecords[consent.id]?.state)
          };
        else if (consent.experience) {
          c = <BaseConsent<ExperienceConsentData>>{
            consentOptionUri: consent.id,
            data: {
              title: consent.experience.title,
              description: consent.experience.description,
              icon: consent.experience.icon,
              schema: "https://vpc.lego.com/schemas/consents/experience.json"
            },
            state: convertState(consentRecords[consent.id]?.state)
          };
        } else if (consent.global) {
          c = <BaseConsent<GlobalConsentData>>{
            consentOptionUri: consent.id,
            data: {
              title: consent.global.title,
              description: consent.global.description,
              icon: consent.global.icon,
              schema: "https://vpc.lego.com/schemas/consents/global.json"
            },
            state: convertState(consentRecords[consent.id]?.state)
          };
        } else {
          throw `Missing content on consent ${consent.id}`;
        }
        return c;
      }
    );
  }
  const convertState = (state?: ConsentStateValue): GivenConsentState => {
    switch (state) {
      case "DENIED": {
        return GivenConsentState.Denied;
      }
      case "GRANTED": {
        return GivenConsentState.Granted;
      }
      case "UNDECIDED": {
        return GivenConsentState.Unknown;
      }
      default: {
        return GivenConsentState.Unknown;
      }
    }
  };
}

export type ConsentActions = typeof ConsentActions;
