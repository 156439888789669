import React from "react";
import { ApplicationFooter, ApplicationHeader, Box, PageBody } from "@lego/lego-account-ui";
import { useModal } from "scripts/hooks";
import { ModalTexts } from "scripts/utils/ModalTextsGenerator";

export type CloseButtonParameters = {
  display?: boolean
  showWarning?: boolean
  modalContent?: ModalTexts
  onClick: Function
  closeButtonLabel: string
}

export type BackButtonParameters = {
  display?: boolean;
  onClick: Function;
  backButtonText: string
  showWarning?: boolean
  modalContent?: ModalTexts
}

type PageContainerProps = {
  children?: React.ReactNode;
  closeButtonParameters?: CloseButtonParameters;
  backButtonParameters?: BackButtonParameters;
  legalComponent?: React.ReactNode;
}

export const PageContainer = React.memo(({
  children,
  closeButtonParameters,
  backButtonParameters,
  legalComponent
}: PageContainerProps) => {
  const { triggerModal, dismissModal } = useModal();
  const showCloseButton =
    closeButtonParameters && closeButtonParameters.display !== false;
  const showBackButton =
    backButtonParameters && backButtonParameters.display !== false;

  const handleBackClick = () => {
    if (backButtonParameters.showWarning && backButtonParameters.modalContent) {
      triggerModal({
        title: backButtonParameters?.modalContent?.title,
        testIdentifier: backButtonParameters?.modalContent?.testIdentifier || "BackModal",
        body: backButtonParameters?.modalContent?.text,
        closeText: backButtonParameters?.modalContent?.cancelButtonText,
        confirmText: backButtonParameters?.modalContent?.confirmButtonText,
        onClose: dismissModal,
        onConfirm: async () => {
          backButtonParameters?.onClick();
        }
      });
    } else {
      backButtonParameters?.onClick();
    }
  };


  const handleOnConfirm = () => closeButtonParameters?.onClick();

  const handleCloseClick = () => {
    if (
      closeButtonParameters.showWarning &&
      closeButtonParameters.modalContent
    ) {
      triggerModal({
        title: closeButtonParameters?.modalContent?.title,
        testIdentifier: closeButtonParameters?.modalContent?.testIdentifier || "CloseModal",
        body: closeButtonParameters?.modalContent?.text,
        closeText: closeButtonParameters?.modalContent?.cancelButtonText,
        confirmText: closeButtonParameters?.modalContent?.confirmButtonText,
        hideCloseButton: false,
        onClose: dismissModal,
        onConfirm: handleOnConfirm
      });
    } else {
      closeButtonParameters?.onClick();
    }
  };

  return (
    <>
      <ApplicationHeader
        showBackButton={showBackButton}
        onBack={handleBackClick}
        showCloseButton={showCloseButton}
        onClose={handleCloseClick}
      />
      <PageBody>
        {children}
      </PageBody>
      <ApplicationFooter>
        {legalComponent}
      </ApplicationFooter>
    </>
  );
});