import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions, GetYourParentsActions } from "scripts/actions";
import {
  Header,
  usePageTitle,
  useTrackPage,
  Checklist,
  Img,
  ChecklistItemProps
} from "@library";
import { Legals, PageContainer } from "scripts/components";
import { push } from "connected-react-router";
import { useTranslation } from "scripts/hooks";
import { Button, HorizonAnchor } from "@lego/lego-account-ui";
const legoIDFullAccountAssets = require.context(
  "../../../../assets/LEGOIDFullAccount",
  false
);

export namespace FlowInfo {
  export type StateProps = {
    context: ContextState;
  };
  export type ActionProps = {
    getYourParentsActions: ThunkActionsDispatch<GetYourParentsActions>;
    authactions: ThunkActionsDispatch<AuthActions>;
    navigate: (path: string) => any;
  };
  export type Props = StateProps & ActionProps;
}

const FlowInfoPage = ({
  context,
  getYourParentsActions,
  authactions,
  navigate
}: FlowInfo.Props): JSX.Element => {
  const { t } = useTranslation(["FlowExplainer", "FlowChecklist"]);
  const { hideCloseButton } = context;

  usePageTitle(t("FlowExplainer:title"));
  useTrackPage(PAGE_TYPE.FLOW_INFO);

  const nextClick = async () => {
    if(!context.isott)
      await authactions.DoSilentLogin();
    getYourParentsActions.continueToConsent();
  };

  const mapChecklistItems = (checklistItems: ChecklistItemProps[]) =>
    checklistItems?.map((item: any) => ({
      title: item?.title ?? "",
      description: item?.description ?? "",
      uniqueKey: item?.unique_key ?? "",
      checked: item?.checked ?? false
    }));

  const renderContent = () => (
    <>
      <Header
        title={t("FlowExplainer:title")}
        introText={t("FlowExplainer:body")}
      />

      <Img
        srcImg={[
          legoIDFullAccountAssets("./adult-success-page@1x.png"),
          legoIDFullAccountAssets("./adult-success-page@2x.png"),
          legoIDFullAccountAssets("./adult-success-page@3x.png"),
          legoIDFullAccountAssets("./adult-success-page@4x.png")
        ]}
        alt="Captain Safety flying"
      />
      <HorizonAnchor horizonType="uphill">
        <Checklist
          header={t("FlowExplainer:checklist_header")}
          list={mapChecklistItems(t("FlowChecklist:entries"))}
        />
      </HorizonAnchor>
      <br />
      <Button
        data-testid="flowInfo"
        onPress={nextClick}>
        {t("FlowExplainer:next_button")}
      </Button>
    </>
  );

  const handleCloseButtonOnClick = () =>
    authactions.ReturnToClientWithReasonCancel(true);

  const closeButtonParameters = {
    onClick: handleCloseButtonOnClick,
    closeButtonLabel: t("close_button")
  };
  const backButtonParameters = {
    showWarning: false,
    onClick: () => navigate("/getyourparents"),
    backButtonText: t("go_back_button"),
    display: true
  };

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        closeButtonParameters={{
          ...closeButtonParameters,
          display: hideCloseButton !== true
        }}
        legalComponent={<Legals context={context} />}
      >
        {renderContent()}
      </PageContainer>
    </>
  );
};

function mapStateToProps(state: RootState): FlowInfo.StateProps {
  return {
    context: state.context
  };
}

const mapDispatchToProps = (dispatch: Dispatch): FlowInfo.ActionProps => {
  return {
    getYourParentsActions: bindActionCreators(GetYourParentsActions, dispatch),
    authactions: bindActionCreators(AuthActions, dispatch),
    navigate: (path: string) =>
      dispatch(
        push({
          pathname: path
        })
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlowInfoPage);
