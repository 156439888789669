import React, { useState, useEffect } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { AuthActions, ConsentActions, VpcActions } from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { Success } from "scripts/components/presentation/Success";
import { getParameterByName, convertToBool } from "scripts/utils";
import { useTranslation } from "scripts/hooks/useTranslation";
const assets = require.context("../../../assets/", false);

export namespace FinishedContainer {
  export type StateProps = {
    context: ContextState;
    vpc: VpcState;
  };
  export type ActionProps = {
    consentActions: ThunkActionsDispatch<ConsentActions>;
    authActions: ThunkActionsDispatch<AuthActions>;
    vpcActions: ThunkActionsDispatch<VpcActions>;
  };
  export type Props = StateProps & ActionProps;
}

const isReconsent = (): boolean => {
  const param = getParameterByName("isReconsent");
  return param && convertToBool(param);
};

const FinishedContainer = (props: FinishedContainer.Props) => {
  const { t } = useTranslation("JourneyFinished");

  const { context, authActions, consentActions } = props;
  const { hideCloseButton } = context;

  const [loading, setLoading] = useState<boolean>(true);

  // To prevent persisting on reload we set persist=false in the querystring after load
  const shouldPersist = (): boolean => {
    const persist = getParameterByName("persist");
    // If persist is not set, then we should persist
    return !persist || convertToBool(persist);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    (async () => {
      if (props.vpc.IsLoaded === true && shouldPersist() === true) {
        await consentActions.consentPersistData();
        consentActions.addPersistFalseToUrl();
        await consentActions.finalizeOffDeviceFlowAsync();
        setLoading(false);
      } else if (shouldPersist() === true) {
        props.vpcActions.ensureVpcToken();
      } else {
        setLoading(false);
      }
    })();
  }, [props.vpc.IsLoaded]);

  usePageTitle(t("JourneyFinished:title"));
  useTrackPage(PAGE_TYPE.VERIFY_SUCCESS);

  const closeButtonParameters = {
    onClick: authActions.ReturnToEdit,
    closeButtonLabel: t("close_button")
  };

  return (
    <Success
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      isLoading={loading}
      legalComponent={<Legals context={context} />}
      i18n={{
        header: t("JourneyFinished:header"),
        changeDelayNotice: t("JourneyFinished:change_delay_notice"),
        text: isReconsent
          ? t("JourneyFinished:consents_updated_text")
          : t("JourneyFinished:text")
      }}
      minifigure={{
        srcImg: [
          assets("./unicorn-guy@1x.png"),
          assets("./unicorn-guy@2x.png"),
          assets("./unicorn-guy@3x.png")
        ],
        alt: "unicorn guy"
      }}
    />
  );
};

function mapStateToProps(state: RootState): FinishedContainer.StateProps {
  return {
    context: state.context,
    vpc: state.vpc
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch
): FinishedContainer.ActionProps => {
  return {
    consentActions: bindActionCreators(ConsentActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch),
    vpcActions: bindActionCreators(VpcActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishedContainer);
