import React, { forwardRef, useState } from "react";
import { ProfileActions } from "scripts/actions";
import {
  Loader,
  ControlPanelAccordion,
  ActionAccordionList,
  AccordionItem,
  Headline,
  Paragraph
} from "@library";
import { GivenConsentState } from "scripts/types/consentTypes";

import { PresentationFactory, getConsentState } from "./Presentation";
import { consentSorting } from "scripts/utils/consentHelper";
import { useTranslation } from "scripts/hooks/useTranslation";

namespace ProfileMember {
  export type Props = {
    member: LinkedChild;
    serviceEndpoints: ContextServiceEndpoints;
    actions: ThunkActionsDispatch<ProfileActions>;
    consentState: VpcConsentEntryState[];
    onUnlinkIntent?: (id: string) => void;
    onChange?: (
      member: LinkedChild,
      consentState: VpcConsentEntryState[]
    ) => void;
  };
}

export default forwardRef((props: ProfileMember.Props, ref: React.Ref<HTMLUListElement>) => {
  const {
    member,
    serviceEndpoints,
    actions,
    consentState,
    onUnlinkIntent = () => { },
    onChange = () => { }
  } = props;
  const { t } = useTranslation(["DashboardPage"]);
  const [isLoading, setLoading] = useState(false);
  const [consentData, setConsentData] = useState<UserConsentsResponse>(null);

  const handleUnlink = () => onUnlinkIntent(member.userId);
  const handletogglePanel = (expanded: boolean) => {
    if (isLoading && !expanded) return;

    if (!consentData) {
      (async () => {
        setLoading(true);
        setConsentData(await actions.GetLinkedConsents(member.userId));
        setLoading(false);
      })();
    }
  };

  const handleConsentChange = (
    consentOptionUri: string,
    state: GivenConsentState
  ) => {
    const newConsentState = [
      ...consentState.filter(
        (item: VpcConsentEntryState) =>
          item.consentOptionUri !== consentOptionUri
      ),
      { consentOptionUri, state }
    ];
    onChange(member, newConsentState);
  };

  const renderConsents = (
    consents: BaseConsent<BaseConsentData>[],
    experience?: ConsentConfigurationExperience
  ) =>
    consents
      .sort(consentSorting)
      .map((consent) => (
        (getConsentState(consentState, consent) !== GivenConsentState.Unknown) ? <PresentationFactory
          key={`linked-profile-${member.userId}-consent-${consent.consentOptionUri}`}
          consentState={consentState}
          consent={consent}
          profile={member}
          experience={experience}
          onChange={handleConsentChange}
        /> : null
      )).filter(item => item !== null);
      
  const experienceSection = (experienceConfig: ConsentConfiguration): JSX.Element[] =>
  {
    var consents = renderConsents(experienceConfig.consents, experienceConfig.experience);
    if(consents && consents.length > 0)
    { 
      return [
        <AccordionItem
          key={`AccordionItem-${member.userId}-${experienceConfig.experience.data.title}`}
          className="ControlPanelConsents"
        >
          <Headline
            component="h2"
            variant="h3"
            testId="blanketConsentsHeader"
          >
            {t("ConsentPage:experience_consents_header", {
              EXPERIENCE_NAME: experienceConfig.experience.data.title
            })}
          </Headline>
          <Paragraph>
            {t("ConsentPage:experience_consents_subtitle", {
              EXPERIENCE_NAME: experienceConfig.experience.data.title
            })}
          </Paragraph>
        </AccordionItem>
      ].concat(consents);
    }
    else {
        return [];
    }
  }

  const renderConsentList = (consentDataResponse: UserConsentsResponse) => (
    <ActionAccordionList ref={ref}>
      {renderConsents(consentDataResponse.globalConsents)}

      {consentDataResponse.experiences.map(experienceSection)}
    </ActionAccordionList>
  );

  return (
    <div>
      <ControlPanelAccordion
        avatarurl={`${serviceEndpoints.avatarService}/api/v4/avatar/${member.userId}/Head`}
        nickname={member.nickName}
        username={member.userName}
        nicknameLabel={t("DashboardPage:nickname_label")}
        usernameLabel={t("DashboardPage:username_label")}
        unlinkLabel={t("DashboardPage:unlink")}
        unlinkOnclick={handleUnlink}
        onToggle={handletogglePanel}
      >
        {isLoading ? (
          <Loader id={`loader-${member.userId}`} margin="small" />
        ) : consentData ? (
          renderConsentList(consentData)
        ) : null}
      </ControlPanelAccordion>
    </div>
  );
});
