export interface PageType {
    page: string[]
    event?: TrackingEvent
    channel?: string
}
export enum TrackingEvent {
    SIGNUP_ADULT_INITIATED,
    SIGNUP_ADULT_COMPLETE,
    SIGNUP_CHILD_INITIATED,
    SIGNUP_CHILD_COMPLETE,
    FORM_FIELD_ERROR,

    PARENT_ACTIVATION_COMPLETE,
    PARENT_ACTIVATION_INITIATED,
    VPC_COMPLETE
}
export type BasicTracking = {
    setContentCulture: (culture: string) => void
    setSiteCulture: (culture: string) => void
    clickAction: (clickAction: string) => void
    trackError: (originator: string, errorMessage: string) => void
}
export type AccountTracking = {
    clientID: (clientId: string) => void
    leavingField: (formname: string, field: string, error?: string) => void
    withEvents: (event: TrackingEvent) => void
    trackPage: () => void
    channel: (channel: string) => AccountTracking
    trackLinkHit: (link: string, aTag: any) => void
    page: (...page: string[]) => AccountTracking
}
type Tracking = {
    BasicTracking: BasicTracking
    AccountTracking: AccountTracking
    MediaTracking: any
};

type TrackingData = {
    user: { age: string|null, loggedIn: boolean, memberCountry?: string|null }
    propertyUpdated?: Function
}
declare global {
    interface Window { trackingData: TrackingData; }
}

export type TrackingFunction = ((f: Tracking) => void);

class TrackingServiceClass {
    scripts?: TrackingFunction[];
    tracking?: Tracking
    currentPage?: PageType;

    init(basicTracking: any, accountTracking: any, mediaTracking: any, disableTracking: boolean = true): void {
        if (disableTracking === true) {
            return;
        }
        this.tracking = {
            BasicTracking: basicTracking,
            AccountTracking: accountTracking,
            MediaTracking: mediaTracking
        };
        if (this.scripts) {
            const fs: TrackingFunction[] = this.scripts;
            this.scripts = [];
            try {
                fs.forEach(f => f(this.tracking as Tracking));
            } catch (e) {
                console.error(e);
            }
        }
    }

    onUserLoaded = (age: any, country: any): void => {
        const user = {
            age: age ? age : null,
            loggedIn: true,
            memberCountry: country ? country : null
        }
        if (!window.trackingData) {
            window.trackingData = {user};
        } else {
            window.trackingData.user = user;
        }

        if (typeof window.trackingData.propertyUpdated === "function") {
            window.trackingData.propertyUpdated("user");
        }
    }

    onUserUnloaded = (): void => {
        const user: TrackingData["user"] = {
            age: null,
            loggedIn: false,
            memberCountry: null
        };
        if (!window.trackingData) {
            window.trackingData = {user}
        } else {
            window.trackingData.user = user;
        }

        if (typeof window.trackingData.propertyUpdated === "function") {
            window.trackingData.propertyUpdated("user");
        }
    }

    setCulture(culture: string): void
    {
        return;
        TrackingService.onTrackingReady(tracking => {
            tracking.BasicTracking.setContentCulture(culture);
            tracking.BasicTracking.setSiteCulture(culture);
        });
    }
    setClientID(clientId: string): void
    {
        return;
        TrackingService.onTrackingReady(tracking => tracking.AccountTracking.clientID(clientId));
    }

    private onTrackingReady(f: TrackingFunction): void {
        return;

        /* if (f) {
            if (this.tracking) {
                try {
                    f(this.tracking);
                } catch (e) {
                    console.error(e);
                }
            } else {
                if (!this.scripts) {
                    this.scripts = [];
                }
                this.scripts.push(f);
            }
        } */
    }

    trackLeavingField(formname: string, field: string, error?: string): void {
        return;
        if (!this.currentPage) {
            console.info("trackLeavingField called without a previous trackPage");
            return;
        }
        this.onTrackingReady(tracking => {
            console.log(`[Track Leaving Field] Form name: ${formname}, field: ${field}, error: ${error}`);
            tracking.AccountTracking.leavingField(formname, field, error);
        });
    }

    trackClick(clickAction: string) {
        return;
        console.log("track click");
        this.onTrackingReady(tracking => {
            console.log(`[Track Click] Action: ${clickAction}`);
            tracking.BasicTracking.clickAction(clickAction);
        });
    }

    withEvent(event: TrackingEvent): TrackingServiceClass {
        this.onTrackingReady(tracking => {
            tracking.AccountTracking.withEvents(event);
        });
        return this;
    }

    trackLinkHit(linkHit: string, aTag?: any): void {
        return;
        if (!this.currentPage) {
            console.info("trackLinkHit called without a previous trackPage");
            return;
        }

        this.onTrackingReady(tracking => {
            if (!this.currentPage) {
                console.info("trackLinkHit executed without a previous trackPage");
                return;
            }

            const pageName: string[] = this.currentPage.page;

            console.log(`[Track Link Hit] Link Hit: ${linkHit}, PageName: ${JSON.stringify(pageName)}`);
            if (this.currentPage.channel) {
                tracking.AccountTracking = tracking.AccountTracking.channel(this.currentPage.channel);
            }

            tracking.AccountTracking = tracking.AccountTracking.page.apply(undefined, pageName);

            if (this.currentPage.event) {
                tracking.AccountTracking.withEvents(this.currentPage.event);
            }

            tracking.AccountTracking.trackLinkHit(linkHit, aTag);
        });
    }

    trackPage(pageType: PageType, pageNamePostFix?: string): void {
        return;
        if (!pageType) {
            console.error("trackPage called without a pageType");
            return;
        }

        this.currentPage = pageType;

        this.onTrackingReady(tracking => {
            let pageName: string[] = pageType.page;
            if (pageNamePostFix) {
                pageName = pageName.concat([pageNamePostFix]);
            }
            console.log(`[Track Page] PageName: ${JSON.stringify(pageName)}`);
            if (pageType.channel) {
                tracking.AccountTracking = tracking.AccountTracking.channel(pageType.channel);
            }

            tracking.AccountTracking = tracking.AccountTracking.page.apply(undefined, pageName);

            if (pageType.event) {
                tracking.AccountTracking.withEvents(pageType.event);
            }

            tracking.AccountTracking.trackPage();
        });
    }

    trackError(errorType: string): void {
        return;
        if (!this.currentPage) {
            console.info("trackError executed without a previous trackPage");
            return;
        }
        if (!errorType) {
            console.error("trackError called without an errorType");
        }

        this.onTrackingReady(tracking => {
            if (!this.currentPage) {
                console.info("trackError executed without a previous trackPage");
                return;
            }
            console.log(`[Track Set Error] ErrorType: ${errorType}`);
            tracking.BasicTracking.trackError(this.currentPage.page[0], errorType);
            this.trackPage(this.currentPage);
        });
    }
}

export const TrackingService: TrackingServiceClass = new TrackingServiceClass();