import React, { useEffect } from "react";
import { PAGE_TYPE } from "scripts/services";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import {
  AuthActions,
  GetYourParentsActions,
  JourneyActions
} from "scripts/actions";
import { usePageTitle, useTrackPage } from "@library";
import { Legals } from "scripts/components";
import { useTranslation } from "scripts/hooks/useTranslation";
import {
  ParentEmailFormValues,
  GetYourParentsWithEmail
} from "scripts/components/presentation";
import { useModal } from "scripts/hooks";

type StateProps = {
  context: ContextState;
  journey: JourneyState;
};
type ActionProps = {
  getYourParentsActions: ThunkActionsDispatch<GetYourParentsActions>;
  authActions: ThunkActionsDispatch<AuthActions>;
  journeyActions: ThunkActionsDispatch<JourneyActions>;
};
type Props = StateProps & ActionProps;

const GetYourParentsContainer = (props: Props) => {
  const { t } = useTranslation(["JourneyGetYourParents"]);

  const {
    context,
    journey,
    getYourParentsActions,
    authActions,
    journeyActions
  } = props;
  const { hideCloseButton } = context;

  const { triggerModal, dismissModal } = useModal();
  usePageTitle(t("JourneyGetYourParents:title"));
  useTrackPage(PAGE_TYPE.GET_YOUR_PARENTS);

  useEffect(() => {
    window.scrollTo(0, 0);
    Promise.all([
      authActions.ValidateClient(),
      getYourParentsActions.EnsureLoggedInUser(false)
    ]).then(async ([validClient, loggedInUser]) => {
      if (validClient && loggedInUser) {
        await journeyActions.validateCurrentUserIsConsentable();
      }
    });
  }, []);

  const nextClick = async (values: ParentEmailFormValues) => {
    getYourParentsActions.CleanSessionState();
    await journeyActions.triggerParentFlowByEmailAsync(values.email);
  };

  const handleCloseButtonOnClick = () =>
    authActions.ReturnToClientWithReasonCancel(true);

  const closeButtonParameters = {
    onClick: handleCloseButtonOnClick,
    closeButtonLabel: t("close_button")
  };

  const openHelpModal = () =>
    triggerModal({
      testIdentifier: "GetYourParentsWithEmail",
      title: t("JourneyGetYourParents:modal_header"),
      body: t("JourneyGetYourParents:modal_text"),
      closeText: t("JourneyGetYourParents:modal_close_button_label"),
      richBody: true,
      onClose: dismissModal
    });

  return (
    <GetYourParentsWithEmail
      hideCloseButton={hideCloseButton}
      closeButtonParameters={closeButtonParameters}
      isLoading={!journey.isConsentable}
      legalComponent={<Legals context={context} />}
      onContinue={nextClick}
      onOpenModal={openHelpModal}
      i18n={{
        header: t("JourneyGetYourParents:header"),
        text: t("JourneyGetYourParents:text"),
        loadingText: t("loading_text"),
        modalButtonLabel: t("JourneyGetYourParents:secondary_button"),
        emailLabelText: t("JourneyGetYourParents:form_email_label"),
        emailPlaceholderText: t("JourneyGetYourParents:form_email_placeholder"),
        submitLabel: t("JourneyGetYourParents:form_submit_label"),
        emailErrorMessages: {
          patternMismatch: t(
            "JourneyGetYourParents:form_email_validation_pattern_mismatch"
          ),
          valueMissing: t(
            "JourneyGetYourParents:form_email_validation_value_missing"
          )
        }
      }}
    />
  );
};

function mapStateToProps(state: RootState): StateProps {
  return {
    context: state.context,
    journey: state.journey
  };
}

const mapDispatchToProps = (dispatch: Dispatch): ActionProps => {
  return {
    getYourParentsActions: bindActionCreators(GetYourParentsActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch),
    journeyActions: bindActionCreators(JourneyActions, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GetYourParentsContainer);
