import { useEffect, useState } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { PAGE_TYPE } from "scripts/services";
import { AuthActions, ProfileActions } from "scripts/actions";
import {
  Section,
  Loader,
  Separator,
  ParentInfo,
  usePageTitle,
  useTrackPage
} from "@library";
import { Headline, Paragraph } from "@library/Typography";
import { Legals, PageContainer } from "scripts/components";
import { LinkedProfile, SaveSection } from "./components";
import { HorizonAnchor } from "@lego/lego-account-ui";

import "./Profile.less";
import { useModal, useTranslation } from "scripts/hooks";

namespace Profile {
  export type StateProps = {
    context: ContextState;
    auth: AuthState;
  };
  export type ActionProps = {
    profileactions: ThunkActionsDispatch<ProfileActions>;
    authactions: ThunkActionsDispatch<AuthActions>;
  };
  export type Props = StateProps & ActionProps;

  export interface State {
    loading: boolean;
    verified: boolean;
    consentState: Record<string, VpcConsentEntryState[]>;
    isSaving: boolean;
    showSavedMessage?: boolean;
    children: LinkedChild[];
  }
}

const Profile = (props: Profile.Props): JSX.Element => {
  const {
    authactions: { DoSilentLogin, DoRedirectLogin, ReturnToClient, ReturnToEdit },
    profileactions,
    auth: { user },
    context
  } = props;
  const {
    serviceEndpoints: { avatarService }
  } = context

  const [loading, setLoading] = useState(true);
  const [verified, setVerified] = useState(false);
  const [consentState, setConsentState] = useState<Record<string, VpcConsentEntryState[]>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [showSavedMessage, setShowSavedMessage] = useState(null);
  const [children, setChildren] = useState([]);

  const { triggerModal, dismissModal } = useModal();

  const { t } = useTranslation(["DashboardPage", "ConsentPage", "ExpandableFormCard"]);
  usePageTitle(t("DashboardPage:header"));
  useTrackPage(PAGE_TYPE.DASHBOARD);

  useEffect(() => {
    (async () => {
      const user = await DoSilentLogin();

      if (!user) {
        DoRedirectLogin("/dashboard", undefined, "login");
        return;
      }
      if (!user.profile) {
        DoRedirectLogin("/dashboard", undefined, "login");
        return;
      }

      if (user.profile.isadult === false) {
        // We have a user but user is not an adult - go to profile instead or return url
        if (context.returnUrlWasProvidedByClient) {
          ReturnToClient();
          return;
        } else {
          DoRedirectLogin("/dashboard", undefined, "login");
          return;
        }
      }

      const [verifiedData, childrenData] = await Promise.all([
        profileactions.GetIsVerified(),
        profileactions.GetChildren()
      ]);

      setVerified(verifiedData)
      setChildren(childrenData);
      setLoading(false);
    })();
  }, []);

  const unlink = async (id: string) => {
    setLoading(true);
    await profileactions.Unlink(id);
    setChildren(await profileactions.GetChildren());
    setLoading(false);
  };

  const getMemberById = (children: LinkedChild[], id: string) =>
    children.find((child) => child.userId === id);

  const renderVerifiedAccount = () => (
    <>
      <Headline variant="h3" testId="VerifiedAccountHeadline">
        {t("DashboardPage:verified_account")}
      </Headline>
      <Separator />
      <ParentInfo
        emailLabel={t("DashboardPage:email_label")}
        nicknameLabel={t("DashboardPage:nickname_label")}
        avatarurl={`${avatarService}/api/v4/avatar/${user.profile.sub}/Head`}
        nickname={user.profile.nickname}
        email={user.profile.email}
        verified={verified}
        verifiedLabel={t("DashboardPage:verified_label")}
      />
      <Separator />
    </>
  );

  const unlinkMember = (id: string, content: string) => {
    const { userName, nickName } = getMemberById(children, id);
    return (
      <>
        {content}
        {userName && nickName && (
          <div className={"profile--avatar-wrapper"}>
            <div className={"profile--avatar-label"}>
              {t("DashboardPage:nickname_label")}
            </div>
            <div className={"profile--avatar-text"}>{userName}</div>
            <div className={"profile--avatar-label"}>
              {t("DashboardPage:username_label")}
            </div>
            <div className={"profile--avatar-text"}>{nickName}</div>
          </div>
        )}
      </>
    );
  };

  const renderNoLinkedMembers = (message: string) => <Section>{message}</Section>;

  const getChildConsentState = (child: LinkedChild): VpcConsentEntryState[] => {
    if (consentState[child.userId])
      return consentState[child.userId];
    return [];
  };

  const renderLinkedMembers = (children: LinkedChild[]) => {
    return (
      <>
        <HorizonAnchor horizonType="uphill">
          {children.map((member) => (
            <LinkedProfile
              key={member.userId}
              member={member}
              serviceEndpoints={context.serviceEndpoints}
              actions={profileactions}
              consentState={getChildConsentState(member)}
              onUnlinkIntent={loadUnlinkModal}
              onChange={handleMemberChange}
            />
          ))}
        </HorizonAnchor>
      </>
    );
  };

  const handleMemberChange = (
    child: LinkedChild,
    consentState: VpcConsentEntryState[]
  ) => {
    const vpcConsentState: Record<string, VpcConsentEntryState[]> = {
      [child.userId]: consentState
    }
    setConsentState(vpcConsentState);
    setShowSavedMessage(false);
  };

  const renderMembers = () => (
    <>
      <Headline
        component="h2"
        variant="h3"
        testId="MembersWithConsentHeadline"
      >
        {t("DashboardPage:members_with_consent")}
      </Headline>
      <Paragraph>{t("ConsentPage:alert_box")}</Paragraph>
      {children.length > 0
        ? renderLinkedMembers(children)
        : renderNoLinkedMembers(
          t("DashboardPage:members_with_consent_error")
        )}
    </>
  );

  const saveLinkedProfiles = async () => {
    const payloads = Object.keys(consentState).map(
      (sub: string): ChildConsentsPayload => ({
        sub,
        consents: consentState[sub]
      })
    );

    try {
      await profileactions.SaveLinkedConsents(payloads);
      setIsSaving(true);
      setShowSavedMessage(true);
      setIsSaving(false);
    }
    finally {
      setIsSaving(false);

    }
  };

  const handleOnSave = () => saveLinkedProfiles();

  const getDisplayState = (
    isSaving: boolean,
    showSavedMessage: boolean
  ): SaveSection.DisplayState => {
    if (isSaving) return "loading";
    if (showSavedMessage === true) return "saved";
    if (showSavedMessage === null) return "disabled";
    return "default";
  };

  const renderSaveSection = () => {
    if (children.length === 0) {
      return null;
    }
    return (
      <SaveSection
        label={t("DashboardPage:save_button")}
        displayState={getDisplayState(isSaving, showSavedMessage)}
        onSave={handleOnSave}
      />
    );
  };

  const loadUnlinkModal = (id: string) => {
    const handlePreConfirm = async () => {
      dismissModal();
      await unlink(id);
    };

    triggerModal({
      title: t("DashboardPage:unlink_modal_header"),
      testIdentifier: "unlink",
      body: unlinkMember(id, t("DashboardPage:unlink_modal_text")),
      confirmText: t("DashboardPage:unlink"),
      onClose: dismissModal,
      onConfirm: handlePreConfirm
    });
  };

  const backButtonParameters = {
    showWarning: false,
    onClick: () => {
      if (context.returnUrlWasProvidedByClient)
        ReturnToClient();
      else
        ReturnToEdit();
    },
    backButtonText: t("go_back_button"),
    display: true
  };

  return (
    <>
      <PageContainer
        backButtonParameters={backButtonParameters}
        legalComponent={<Legals context={context} />}
      >
        {loading ? (
          <Loader ariaLabel={t("loading_text")} />
        ) : (
          <>
            {user.profile ? renderVerifiedAccount() : null}
            {renderMembers()}
            {renderSaveSection()}
          </>
        )}
      </PageContainer>
    </>
  );
}

function mapStateToProps(state: RootState): Profile.StateProps {
  return {
    context: state.context,
    auth: state.auth
  };
}

function mapDispatchToProps(dispatch: Dispatch): Profile.ActionProps {
  return {
    authactions: bindActionCreators(AuthActions, dispatch),
    profileactions: bindActionCreators(ProfileActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
