import React from "react";
const assets = require.context("./assets", false);
const style = require("./MessageBox.less");

export interface MessageBoxProps {
  text: string
  iconAlt?: string
}

const MessageBox = (props: MessageBoxProps) => {
  const { text, iconAlt = "icon" } = props;
  return (
    <div className={style.wrapper}>
      <div className={style.icon}>
        <img src={assets("./warning.svg")} alt={iconAlt} />
      </div>
      <div className={style.content}>
        <div className={style.text}>
          {text}
        </div>
      </div>
    </div>
  )
}

export { MessageBox };
