import React from 'react';
import Lottie, { Options } from 'react-lottie';
const style = require("./BrickLoader.less");

import animationData from './assets/verifycheck.json';

type BrickLoaderProps = {
  width?: number,
  height?: number
}

export const BrickLoader = ({ width , height }: BrickLoaderProps) => {

  const animationOptions: Options = {
    loop: true,
    animationData: animationData
  }

  return (
    <div className={style.BrickLoader} role="alertdialog" aria-hidden="true">
      <Lottie options={animationOptions}
        isClickToPauseDisabled={true}
        ariaRole="alertdialog"
        ariaLabel="animation"
        width={width}
        height={height} />
    </div>
  )

}
