import { ConsentActionTypes } from "../actions/consentActions";

const initialState: ConsentState = {
  consents: [], //TODO: Can probably be removed
  clientConsents: []
};

export const ConsentReducer = (state = initialState, action: ConsentAction): ConsentState => {
  switch (action.type) {
    case ConsentActionTypes.CONSENT_CONSENTS_LOADED:
      return {
        ...state,
        consents: action.consents, //TODO: Can probably be removed
        clientConsents: action.clientConsents
      };

    default:
      return state;
  }
};
