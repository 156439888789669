import React from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { contentServices, PAGE_TYPE } from "scripts/services";
import {
  VpcActions,
  VerifyActions,
  NavigationRoute,
  RouteActions,
  ModalActions
} from "scripts/actions";
import {
  Header,
  Section,
  Loader,
  TrackingService,
  Img,
} from "@library";
import { VerificationMethod } from "scripts/types/storeTypes";
import { VerifyOption, Faq } from "../components";
import { Legals, PageContainer } from "scripts/components";
import NoExternalLinksTransform from "scripts/utils/NoExternalLinksTransform";
import "../../../../../styles/vpc.less";
const assets = require.context("./assets", false);
import i18n, { t } from "scripts/utils/i18n";
import { Button } from "@lego/lego-account-ui";

namespace VerifyOptionsPage {
  export type ActionProps = {
    vpcActions: ThunkActionsDispatch<VpcActions>;
    verifyActions: ThunkActionsDispatch<VerifyActions>;
    routeActions: ThunkActionsDispatch<RouteActions>;
    modalActions: ThunkActionsDispatch<ModalActions>;
  };
  export type StateProps = {
    context: ContextState;
    verify: VerifyState;
    vpc: VpcState;
  };
  export type Props = ActionProps & StateProps;

  export type State = {
    loading: boolean;
    isAwaiting: boolean;
  };
}

class VerifyOptionsPage extends React.Component<
  VerifyOptionsPage.Props,
  VerifyOptionsPage.State
> {
  _isMounted = false;
  constructor (props: VerifyOptionsPage.Props) {
    super(props);
    this.state = {
      loading: true,
      isAwaiting: false
    };
  }

  initialize = async () => {
    await i18n.loadNamespaces(["VerifyPage", "CreditCardInfoPage"]);
    document.title = t("VerifyPage:header");
    await this.props.verifyActions.getVerificationMethodAllowed();
    if (this._isMounted) {
      TrackingService.trackPage(PAGE_TYPE.VERIFY_OPTIONS);
      this.setState({
        loading: this.props.verify.options.includes(
          VerificationMethod.IdCardCreditCardFaceDetection
        )
      });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.scrollTo(0, 0);

    if (this.props.vpc.IsLoaded === true) {
      this.initialize();
    } else {
      this.props.vpcActions.ensureVpcToken();
    }
  }

  async componentDidUpdate(prevProps: VerifyOptionsPage.Props) {
    if (prevProps.vpc.IsLoaded === false && this.props.vpc.IsLoaded === true) {
      this.initialize();
    }
  }

  handleMethodSelect = async (method: VerificationMethod) => {
    this.setState({ isAwaiting: true }, async () => {
      this.props.verifyActions.redirectToNextStep(method);
    });
  };

  renderOptions = () => {
    const {
      verify: { options }
    } = this.props;
    const { isAwaiting: isDisabled } = this.state;

    return (
      <div data-testid="verifyOptions">
        {options.includes(VerificationMethod.FaceDetection) && (
          <VerifyOption
            title={t("VerifyPage:selfie_verification_title")}
            text={t("VerifyPage:selfie_verification_text")}
            isDisabled={isDisabled}
            value={VerificationMethod.FaceDetection}
            onClick={this.handleMethodSelect}
            note={t("VerifyPage:selfie_verification_note")}
          />
        )}
        {options.includes(VerificationMethod.CreditCard) && (
          <VerifyOption
            title={t("VerifyPage:credit_card_verification_title")}
            text={t("VerifyPage:credit_card_verification_text")}
            isDisabled={isDisabled}
            value={VerificationMethod.CreditCard}
            onClick={this.handleMethodSelect}
            note={t("VerifyPage:credit_card_verification_note")}
          />
        )}
        {options.includes(VerificationMethod.IdCard) && (
          <VerifyOption
            title={t("VerifyPage:i_dverification_title")}
            text={t("VerifyPage:i_dverification_text")}
            isDisabled={isDisabled}
            value={VerificationMethod.IdCard}
            onClick={this.handleMethodSelect}
            note={t("VerifyPage:id_verification_note")}
          />
        )}
      </div>
    );
  };

  renderFaq = () => (
    <div className="wrapperAccordion">
      <div
        dangerouslySetInnerHTML={contentServices.createMarkup(
          t("VerifyPage:help_modal_text")
        )}
      />
      {this.renderFaqItem()}
    </div>
  );

  renderFaqItem = () => {
    const {
      context: { appContext, serviceEndpoints },
      verify: { options }
    } = this.props;
    const transformer = new NoExternalLinksTransform(
      appContext,
      serviceEndpoints.vpcRootDomain
    );
    return (
      <Faq.List id="faq">
        {options.includes(VerificationMethod.FaceDetection) && (
          <>
            <Faq.Question
              id="accSelfieVerification"
              text={t("VerifyPage:selfie_verification_title")}
            />
            <Faq.Answer
              text={transformer.Apply(
                t("VerifyPage:selfie_verification_help_modal_text")
              )}
            />
          </>
        )}
        {options.includes(VerificationMethod.CreditCard) && (
          <>
            <Faq.Question
              id="accCreditCardVerification"
              text={t("VerifyPage:credit_card_verification_title")}
            />
            <Faq.Answer
              text={transformer.Apply(
                t("VerifyPage:help_modal_credit_card_verification_text")
              )}
            />
          </>
        )}
        {options.includes(VerificationMethod.IdCard) && (
          <>
            <Faq.Question
              id="accIdVerification"
              text={t("VerifyPage:i_dverification_title")}
            />
            <Faq.Answer
              text={transformer.Apply(
                t("VerifyPage:help_modal_id_verification_text")
              )}
            />
          </>
        )}
      </Faq.List>
    );
  };

  handleDismissModal = async () => this.props.modalActions.dismiss();
  loadInfoModal = () =>
    this.props.modalActions.trigger({
      title: t("VerifyPage:help_modal_header"),
      testIdentifier: "verifyMethodInfo",
      body: this.renderFaq(),
      closeText: t("CreditCardInfoPage:help_modal_ok_button"),
      onClose: this.handleDismissModal
    });

  renderFooterProvider = () => {
    const {
      verify: { options }
    } = this.props;

    return (
      <>
        <div className={"footer-provider"}>{t("VerifyPage:powered_by")}</div>
        <div className={"footer-providerLogos"}>
          {options.includes(VerificationMethod.IdCard) && (
            <Img srcImg={[assets("./jumio.png")]} alt="Jumio" />
          )}
          {options.includes(VerificationMethod.CreditCard) && (
            <Img srcImg={[assets("./adyen.png")]} alt="Adyen" />
          )}
          {options.includes(VerificationMethod.FaceDetection) && (
            <Img srcImg={[assets("./yoti.png")]} alt="YOTI" />
          )}
        </div>
      </>
    );
  };

  renderContent = () => (
    <>
      <Header
        title={t("VerifyPage:header")}
        introText={t("VerifyPage:text")}
      />
      <Section>
        <div
          className={"information__text-box"}
          dangerouslySetInnerHTML={contentServices.createMarkup(
            t("VerifyPage:note")
          )}
        />

        {this.renderOptions()}
        <Button
          variant="link"
          data-testid="verifyInfoBtn"
          aria-haspopup="dialog"
          onPress={this.loadInfoModal}
        >
          {t("VerifyPage:help_button")}
        </Button>
        {this.renderFooterProvider()}
      </Section>
    </>
  );

  handleBackButtonOnClick = () =>
    this.props.routeActions.goto(NavigationRoute.CONSENT);


  isLoading = () => {
    const { verify } = this.props
    if (verify.options?.length > 0) {
      return verify.options.includes(
        VerificationMethod.IdCardCreditCardFaceDetection
      )
    }
    return true;
  }

  render() {
    const { context } = this.props;

  const backButtonParameters = {
    showWarning: false,
    onClick: this.handleBackButtonOnClick,
    backButtonText: t("go_back_button"),
    display: true
  };

    return (
      <>
        <PageContainer
          backButtonParameters={backButtonParameters}
          legalComponent={<Legals context={context} />}
        >
          {this.isLoading() ? (
            <Loader ariaLabel={t("loading_text")} />
          ) : (
            this.renderContent()
          )}
        </PageContainer>
      </>
    );
  }
}

function mapStateToProps(state: RootState): VerifyOptionsPage.StateProps {
  return {
    context: state.context,
    verify: state.verify,
    vpc: state.vpc
  };
}

function mapDispatchToProps(dispatch: Dispatch): VerifyOptionsPage.ActionProps {
  return {
    vpcActions: bindActionCreators(VpcActions, dispatch),
    verifyActions: bindActionCreators(VerifyActions, dispatch),
    routeActions: bindActionCreators(RouteActions, dispatch),
    modalActions: bindActionCreators(ModalActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VerifyOptionsPage);
