import classNames from "classnames";
import React from "react";
import { VerificationMethod } from "scripts/types/storeTypes";
import "./VerifyOption.less";

type VerifyOptionProps = {
  title: string;
  text: string;
  note?: string;
  value: VerificationMethod;
  isDisabled?: boolean;
  imageAlt?: string;
  onClick?: (method: VerificationMethod) => void;
};

const VerifyOption = (props: VerifyOptionProps) => {
  const {
    title,
    text,
    note,
    value,
    isDisabled = false,
    onClick = () => {}
  } = props;

  const handleClick = () => {
    if (!isDisabled) onClick(value);
  };

  return (
    <div
      onTouchStart={() => false}
      id={`verifyOption-${value}`}
      key={`verifyOption-${value}`}
      className={classNames("optionWrapper", `variant-${value}`)}
      role="button"
      tabIndex={isDisabled ? null : 0}
      onKeyDown={(e) => {
        if (e.key === " " || e.key === "Enter" || e.key === "Spacebar")
          handleClick();
      }}
      onClick={handleClick}
      aria-disabled={isDisabled}
      aria-label={title}
    >
      <div className="optionContent">
        <div id={`verifyOption-title-${value}`} className="optionTitle">
          {title}
        </div>
        <div id={`verifyOption-text-${value}`} className="optionText">
          {text}
        </div>
        {note && <div className="optionNote">{note}</div>}
      </div>
    </div>
  );
};

export { VerifyOption };
