import React from "react";
const style = require("./ActionAccordion.less");
import classNames from "classnames";

export interface AccordionItemProps {
    children: React.ReactNode
    className?: string
    testId?: string
}

const AccordionItem = ({ children, className, testId }: AccordionItemProps) => (
    <li className={classNames(style.ActionAccordion, className)} data-component="AccordionItem" data-testid={testId}>
        {children}
    </li>
);

export { AccordionItem };
