import React, {forwardRef} from "react";

export interface ImgProps {
    srcImg: string[]
}

const Img = forwardRef<HTMLImageElement | null, ImgProps & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>>((
    props,
    ref
) => {
    const { alt, src, srcImg, width="70%", ...remainProps } = props

    const imgSrc = src ?? srcImg[0];
    const srcSet = [
        srcImg[0] && `${srcImg[0]} 300w`,
        srcImg[1] && `${srcImg[1]} 768w`,
        srcImg[2] && `${srcImg[2]} 960w`,
        srcImg[3] && `${srcImg[3]} 1440w`
    ].filter(elem => Boolean(elem)).join(' , ');
    return (
        <img
            ref={ref}
            alt={alt}
            src={imgSrc}
            width={width}
            srcSet={srcSet}
            {...remainProps}
        />
    )
})

export { Img };
