import {
  ErrorActions,
  ErrorTypes,
  ContextActions,
  VerifyActions
} from "scripts/actions";
import StorageFactory from "../utils/StorageFactory";
import { push } from "connected-react-router";
import {
  AccountContext,
  IdentityApiClient,
  VPCApiClient
} from "scripts/services";
import {
  compileCallbackState,
  CustomCallbackState
} from "scripts/utils/callbackHelper";

const storage = StorageFactory.getStorage();

export enum VpcActionType {
  VPC_LOAD_VPC_TOKEN = "[VPC] VPC_LOAD_VPC_TOKEN",
  VPC_RELOAD_VPC_TOKEN = "[VPC] VPC_RELOAD_VPC_TOKEN",
  VPC_PARENT_VALIDATED = "[VPC] VPC_PARENT_VALIDATED",
  VPC_MARK_IS_LOADED = "[VPC] VPC_MARK_IS_LOADED",
  VPC_PERMISSION_CHANGED = "[VPC] VPC_PERMISSION_CHANGED"
}

export namespace VpcActions {
  export const updateVpcToken = (vpcToken: string): ThunkAction => (
    dispatch
  ) => {
    dispatch({
      type: VpcActionType.VPC_LOAD_VPC_TOKEN,
      VPCToken: vpcToken
    });
  };

  export const parentValidated = (isParentValidated: boolean): ThunkAction => (
    dispatch
  ) => {
    dispatch({
      type: VpcActionType.VPC_PARENT_VALIDATED,
      VPCParentValidated: isParentValidated
    });
  };

  export const ensureVpcTokenJourney = (vpcToken: string): ThunkAction => (
    dispatch
  ) => {
    if (vpcToken) {
      dispatch(VpcActions.updateVpcToken(vpcToken));
      dispatch({ type: VpcActionType.VPC_MARK_IS_LOADED });
    } else {
      console.log("VPC Token is not found");
      dispatch(
        ErrorActions.ErrorAction({
          error: ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED
        })
      );
      return;
    }
  };

  export const ensureVpcToken = (): ThunkFunction<boolean> => (
    dispatch,
    getState
  ) => {
    const {
      vpc: { VPCToken: vpcToken }
    } = getState();

    if (!vpcToken) {
      console.log("VPC Token is not found in redux");
      dispatch(
        ErrorActions.ErrorAction({
          error: ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED
        })
      );
      return false;
    } else {
      console.log(
        "VPC Token already loaded from storage. From Redux: " + vpcToken
      );
      dispatch({ type: VpcActionType.VPC_MARK_IS_LOADED });
      return true;
    }
  };

  export const startVpcJourney = (
    journeyContext: JourneyContext,
    urlWithSearchParams: string
  ): ThunkActionAsync => async (dispatch, getState) => {
    const {
      context,
      vpc: { VPCToken: vpcToken }
    } = getState();
    const { serviceEndpoints, culture } = context;
    if (await dispatch(VerifyActions.isParentCountrySupported())) {
      if (vpcToken) {
        dispatch(ContextActions.JourneyContextChanged(journeyContext));
        const responseSetCurrentUser = await VPCApiClient.SetCurrentAdultUser(
          serviceEndpoints.vpcRootDomain,
          vpcToken
        );
        if (responseSetCurrentUser.ok) {
          const response = await VPCApiClient.accountLinksStatus(
            serviceEndpoints.vpcRootDomain,
            vpcToken
          );
          if (response.ok) {
            const redirectUri = new URL(
              urlWithSearchParams,
              `${window.location.protocol}//${window.location.host}`
            );
            const { payload } = response;
            redirectUri.searchParams.append("uid", payload?.child?.userId);
            redirectUri.searchParams.append("n", payload?.child?.nickname);

            if (payload?.child?.requiresUpgrade) {
              const upgradeToken = payload?.upgradeToken;
              if (!upgradeToken) {
                dispatch(ErrorActions.ErrorResponse(response));
                return;
              }

              // Apply VET if defined
              if (
                journeyContext.verifiedEmailToken &&
                journeyContext.securityId
              ) {
                const emailTokenResponse = await IdentityApiClient.emailTokenVerification(
                  serviceEndpoints.identityService,
                  journeyContext.verifiedEmailToken,
                  journeyContext.securityId
                );
                if (!emailTokenResponse.ok) {
                  const error =
                    emailTokenResponse.badRequest &&
                    emailTokenResponse.errors[0]
                      ? emailTokenResponse.errors[0]
                      : ErrorTypes.GENERIC_ERROR;
                  dispatch(ErrorActions.ErrorAction({ error }));
                  return;
                }
              }

              await IdentityApiClient.upgradeUser(
                culture,
                compileCallbackState<CustomCallbackState>({
                  returnUrl: redirectUri.href,
                  vpcToken
                }),
                context.appContext,
                payload.upgradeToken,
                AccountContext.Child,
                AccountContext.Adult
              );
            } else {
              dispatch(
                push({
                  pathname: redirectUri.pathname,
                  search: redirectUri.searchParams.toString(),
                  hash: redirectUri.hash
                })
              );
              return;
            }
          } else {
            dispatch(ErrorActions.ErrorResponse(response));
            return;
          }
        } else {
          if (responseSetCurrentUser.errors.includes("adult_user_required")) {
            dispatch(
              ErrorActions.ErrorAction({
                error: ErrorTypes.VALIDATE_PARENT_NOT_AN_ADULT
              })
            );
          } else if (
            responseSetCurrentUser.errors.includes(
              "user_linked_to_different_account"
            )
          ) {
            dispatch(
              ErrorActions.ErrorAction({
                error: ErrorTypes.USER_LINKED_TO_DIFFERENT_ACCOUNT,
                behavior: ErrorActions.createReauthBehavior(
                  urlWithSearchParams,
                  "flow:login"
                )
              })
            );
          } else {
            dispatch(ErrorActions.ErrorResponse(responseSetCurrentUser));
          }
          return;
        }
      } else {
        dispatch(
          ErrorActions.ErrorAction({
            error: ErrorTypes.VPC_TOKEN_INVALID_OR_EXPIRED
          })
        );
        return;
      }
    }
  };
}

export type VpcActions = typeof VpcActions;
