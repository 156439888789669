import React, { useEffect } from "react";

import { connect } from "react-redux";
import { AuthActions, ProfileActions } from "scripts/actions";
import { bindActionCreators, Dispatch } from "redux";
import { Loader } from "@library";
import { t } from "scripts/utils/i18n";
import { getParameterByName } from "scripts/utils";
import { VPCApiClient } from "scripts/services";

type StateProps = {
  context: ContextState;
};
export namespace Unlink {
  export type Props = {
    authactions: ThunkActionsDispatch<AuthActions>;
    profileActions: ThunkActionsDispatch<ProfileActions>;
  } & StateProps;
}

const Unlink = (props: Unlink.Props): JSX.Element => {
  const {
    context,
    authactions: { DoSilentLogin },
    profileActions
  } = props;

  const handleUnlink = async (userId: string, redirectUrl: string) => {
    await profileActions.Unlink(userId);
    const decodedRedirectUrl = new URL(decodeURIComponent(redirectUrl));
    const redirectUrlWithoutParams =
      decodedRedirectUrl.origin + decodedRedirectUrl.pathname;
    const isRedirectUrlValid = await VPCApiClient.validateReturnUrl(
      context.serviceEndpoints.vpcRootDomain,
      context.clientid,
      redirectUrlWithoutParams,
      context.culture
    );
    isRedirectUrlValid
      ? (window.location.href = decodedRedirectUrl.toString())
      : window.history.go(-1);
  };

  useEffect(() => {
    (async () => {
      const userId = getParameterByName("userId");
      const redirectUrl = getParameterByName("redirectUrl");
      const user = await DoSilentLogin();
      if (user && userId) handleUnlink(userId, redirectUrl);
    })();
  }, []);

  return <Loader ariaLabel={t("loading_text")} />;
};

function mapStateToProps(state: RootState): StateProps {
  return {
    context: state.context
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    authactions: bindActionCreators(AuthActions, dispatch),
    profileActions: bindActionCreators(ProfileActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Unlink);
