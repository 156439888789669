const constants = {
  prefixTitlePage: "LEGO.com ",
  experienceId: "LEGO ID Naminator",
  endpoints: {
    url: "https://www.lego.com/gizmo/api/v1/content/en/"
  },
  cultureRoutePrefix: "/:culture([a-zA-Z]{2}-[a-zA-Z]{2})",
  defaultCulture: "en-US",
  emailPattern: /^([a-zA-Z0-9.!#$%&'*+-\/=?^_`{|}~;]*)[a-zA-Z0-9]@(?!([0-9]|\.|-)*$)(?!([0-9a-zA-Z])*$)([\u00A0-\uD7FFa-zA-Z0-9]+(?:(\.|-)[\u00A0-\uD7FFa-zA-Z0-9]+)*((\.)[\u00A0-\uD7FFa-zA-Z]+){1})$/,
  maxImagePixelLength: 8000,
  maxImageFileSize: 5 * 1024 * 1024,
  validImageFileType: ["image/jpeg", "image/png"],
  defaultTheme: "legacy"
};

export default constants;
