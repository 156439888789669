import React from "react";
const style = require("./Avatar.less");
import classNames from "classnames";
export interface AvatarProps {
    url: string,
    avatarId: string,
    nickname?: string,
    color?: string,
    className?: string,
    showNickname?: boolean,
    variant?: 'default' | 'large'
}

const Avatar: React.FC<AvatarProps> = ({ 
    url, 
    className, 
    avatarId, 
    nickname = "Avatar", 
    color = "#fff", 
    showNickname = true,
    variant = 'default'
}): JSX.Element => {
     
    const imgStyle =
    {
        backgroundImage: `url(${url})`,
        backgroundColor: color
    }
    let avatarClass = "";
    if(className != null) {
        avatarClass = className;
    }

    return (
        <div className={classNames(style.avatar, style[`variant-${variant}`], {[avatarClass]: className != null})} role="img" aria-label={nickname} tabIndex={-1}>
            <div className={style.avatarWrapper} >
                <div className={style.avatarInner}>
                    <div data-testid="avatarimage" data-avatarid={avatarId} className={style.avatarImage} style={imgStyle}></div>
                </div>
            </div>
            {showNickname && nickname && <span data-testid="avatarnickname" className={style.avatarText} >{nickname}</span>}
        </div>
    );
};
export { Avatar };